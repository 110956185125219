export default class EpicDto {
    constructor() {
        this.epic_id = '';
        this.name = '';
        this.description = '';
        this.project_id = '';
        this.completed = false;
        this.color_id = '';
    }

    static fromJSON(json) {
        const epic = new EpicDto();
        epic.epic_id = json.epic_id;
        epic.name = json.name;
        epic.description = json.description;
        epic.project_id = json.project_id;
        epic.completed = json.completed;
        epic.color_id = json.color_id;
        return epic;
    }
}