import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import ProjectDocumentList from './ProjectDocumentList';
import ProjectDocumentView from './ProjectDocumentView';
import DocumentEdit from './DocumentEdit';
import ProjectAlerts from '../ProjectAlerts';
import Loading from '../../Loading';
import ProjectDocumentDowngrade from './ProjectDocumentDowngrade';
import ProjectContextDto from '../../../dtos/ProjectContextDto';
import DataService from '../../../classes/DataService';

export default function ProjectDocuments({ 
    project, 
    Auth0_user, 
    stripeSubscription,
    projectContext,
    setProjectContext 
}) {
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [projectDocuments, setProjectDocuments] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userPlan, setUserPlan] = useState(null);

    useEffect(() => {
        setLoading(true);
        const fetchDocuments = async () => { 
          try {
            if (project && projectContext) {
                
                await getDocuments();
                
                setProjectDocuments(projectContext.DOCUMENTS)
                
                // Set selectedDocument to homeDocument immediately after fetching
                const homeDocument = projectContext.DOCUMENTS.find(document => document.is_home === true);
                
                setSelectedDocument(homeDocument); 
              }
          } catch (error) {
            console.error('Failed to fetch project context:', error);
          }
        };
        fetchDocuments().finally(() => setLoading(false));
      }, [project]);
    
    // useEffect(() => {
    //     console.log('Second useEffect triggered');
    //     if ((selectedDocument === null || selectedDocument === undefined) && projectDocuments.length > 0) {
    //         console.log("In the IF statement");
    //         const homeDocument = projectDocuments.find(document => document.is_home === true);
    //         if (homeDocument && homeDocument !== selectedDocument) {
    //             setSelectedDocument(homeDocument);
    //         }
    //     }
    // }, [projectDocuments, selectedDocument]);
    
    useEffect(() => {
        if (stripeSubscription && stripeSubscription.message && stripeSubscription.message === 'No Stripe subscription') {
            setUserPlan("Professional");
        } else if (stripeSubscription.productName) {
            setUserPlan("Professional");
        }
    }, [stripeSubscription]);
    
    const getDocuments = async () => {
        //console.log('Fetching documents...');
        if (project == null) return;
        if (projectContext.DOCUMENTS.length > 0) return;
        
        let documents = await DataService.getDocumentsByProjectId(project.project_id);
        let context = ProjectContextDto.setDocuments(projectContext, documents);

        setProjectContext(prevContext => ({
            ...prevContext,
            DOCUMENTS: context.DOCUMENTS
        }));
    };

    const removeAlert = () => {
        setAlert(null);
    };

    if (userPlan === 'Free' && projectDocuments.length > 2) {
        return <ProjectDocumentDowngrade 
            project={project}
            projectContext={projectContext}
            setProjectContext={setProjectContext}
            projectDocuments={projectDocuments}
            setProjectDocuments={setProjectDocuments}
            />;
    }

    return loading ? (
        <Box display="flex" flexDirection="row" height="100%" sx={{ marginTop: '10px' }}>
            <Skeleton variant="rounded" animation="wave" width="20%" height="500px" />
            <Skeleton variant="rounded" animation="wave" width="80%" height="500px" sx={{ marginLeft: '10px' }} />
        </Box>
    ) : (
        <Box sx={{ 
                display: 'flex', 
                height: '80%', 
                width: '100%', 
                flex: 1, 
                borderTop: '1px solid gray',
            }}
        >
            {isEditing ? (
                <Box sx={{ marginBottom: '10px', width: '100%' }}>
                    <DocumentEdit
                        project={project}
                        projectDocuments={projectDocuments}
                        setProjectDocuments={setProjectDocuments}
                        selectedDocument={selectedDocument}
                        setSelectedDocument={setSelectedDocument}
                        setEditing={setIsEditing}
                        projectContext={projectContext}
                        setProjectContext={setProjectContext}
                    />
                </Box>
            ) : (
                <>
                    <Box sx={{ borderRight: '1px solid lightgray' }}>
                        <ProjectDocumentList 
                            projectDocuments={projectDocuments} 
                            setProjectDocuments={setProjectDocuments} 
                            selectedDocument={selectedDocument} 
                            setSelectedDocument={setSelectedDocument} 
                            project={project} 
                            Auth0_user={Auth0_user}
                            alert={alert}
                            setAlert={setAlert}
                            userPlan={userPlan}
                            projectContext={projectContext}
                            setProjectContext={setProjectContext}
                        />
                    </Box>
                    <Box sx={{ 
                        maxHeight: '100%', 
                        width: '100%', 
                        maxWidth: '80%', 
                        overflowY: 'auto',
                        paddingBottom: '30px'
                        }}
                    >
                        <ProjectDocumentView 
                            setProjectDocuments={setProjectDocuments} 
                            selectedDocument={selectedDocument} 
                            setSelectedDocument={setSelectedDocument}
                            setIsEditing={setIsEditing}
                            userPlan={userPlan}
                            projectContext={projectContext}
                            setProjectContext={setProjectContext}
                            projectDocuments={projectDocuments}
                        />
                    </Box>
                </>
            )}
            <ProjectAlerts alert={alert} removeAlert={removeAlert}/>
        </Box>
    );
}