import React, { useState, useEffect } from 'react';
import { Drawer, TextField, Box, MenuItem, Avatar, Tooltip, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DataService from '../../../classes/DataService.js';
import StoryComments from './StoryComments.js';
import ViewComments from './ViewComments.js';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

const ViewStory = ({ 
    open, 
    handleClose, 
    selectedStoryForEdit, 
    projectMembers,
    projectContext
}) => {
    //const [epics, setEpics] = useState([]);
    const Auth0_user = useAuth0().user;
    const { id } = useParams();

    // useEffect(() => {
    //     if (selectedStoryForEdit && selectedStoryForEdit.project_id) {
    //         DataService.getEpicsByProjectId(selectedStoryForEdit.project_id)
    //             .then(response => {
    //                 setEpics(response);
    //             })
    //             .catch(e => {
    //                 console.error("There was a problem fetching the epics: ", e);
    //             });
    //     }
    // }, [selectedStoryForEdit]);

    return (
        <Drawer anchor='right' open={open} onClose={handleClose}>
            <div style={{ padding: '20px', width: '750px' }}>
                <h2>{selectedStoryForEdit ? selectedStoryForEdit.title : ''}</h2>
                <Box>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        value={selectedStoryForEdit ? selectedStoryForEdit.title : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        multiline
                        maxRows={10}
                        value={selectedStoryForEdit ? selectedStoryForEdit.description : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        label="Epic"
                        value={(selectedStoryForEdit && projectContext?.EPICS) ? projectContext?.EPICS?.find(epic => epic.epic_id === selectedStoryForEdit.epic_id)?.name : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                        margin="normal"
                        fullWidth
                    />
                </Box>
                <Box>
                    <TextField
                        margin="normal"
                        fullWidth
                        multiline
                        maxRows={10}
                        label="Acceptance Criteria"
                        value={selectedStoryForEdit ? selectedStoryForEdit.acceptance_criteria : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        select
                        label="Status"
                        margin="normal"
                        fullWidth
                        value={selectedStoryForEdit ? selectedStoryForEdit.status : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    >
                        <MenuItem value="todo">TODO</MenuItem>
                        <MenuItem value="in-progress">IN PROGRESS</MenuItem>
                        <MenuItem value="done">DONE</MenuItem>
                        <MenuItem value="inactive">DONE</MenuItem>
                    </TextField>
                </Box>
                <Box>
                    <TextField
                        label="Story Points"
                        margin="normal"
                        fullWidth
                        value={selectedStoryForEdit ? selectedStoryForEdit.story_points : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Box>
                <Box>
    <TextField
        label="Assigned User"
        value={selectedStoryForEdit ? projectMembers.find(member => member.user_id === selectedStoryForEdit.assigned_user)?.name : ''}
        InputProps={{
            readOnly: true,
            startAdornment: (
                <InputAdornment position="start">
                    <Avatar src={selectedStoryForEdit ? projectMembers.find(member => member.user_id === selectedStoryForEdit.assigned_user)?.picture : ''} style={{ width: '30px', height: '30px' }} />
                </InputAdornment>
            ),
        }}
        margin="normal"
        fullWidth
    />
</Box>
                <ViewComments selectedStoryForEdit={selectedStoryForEdit} projectMembers={projectMembers} />
            </div>
        </Drawer>
    );
}

export default ViewStory;