import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider, Box } from '@mui/material';
import ChatInput from './ChatInput.js';
import MessageList from './MessageList.js';
import ChatBotCommunicator from '../classes/ChatBotCommunicator.js';

// Create an instance of the ChatBotCommunicator class
const chatBotCommunicator = new ChatBotCommunicator('AIzaSyBpFVWg1k_4g0uK4ULzOvifgsHG-URkQG0');

function TalkToTess() {
  const storedMessages = JSON.parse(localStorage.getItem('messages'));
  const [messages, setMessages] = useState(storedMessages || []);
  const [lastUpdated, setLastUpdated] = useState(Date.now());

  // Define messagesEndRef
  const messagesEndRef = useRef(null);

  // Define clearMessages
  const clearMessages = () => {
    setMessages([]);
    localStorage.removeItem('messages');
  };

  useEffect(() => {
    localStorage.setItem('messages', JSON.stringify(messages));
    // console.log("Messages: ", messages);
  }, [messages]);

  const [isProcessing, setIsProcessing] = useState(false);

  const sendMessage = async (inputObject, callback) => {
    const text = inputObject.text.trim();
    if (text) {
      const sender = inputObject.sender === 'Tess' ? 'model' : inputObject.sender;
      setIsProcessing(true);
      let updatedMessages = [
        ...messages,
        { text: text, sender: sender },
      ];
      setMessages(updatedMessages); // Set messages immediately after user's message is added
      let isFirstResponse = true;
      try {
        if (messages.length == 0) {
          const storedMessages = JSON.parse(localStorage.getItem('messages'));
          // console.log("Starting chat with messages:", storedMessages);
          if (storedMessages && storedMessages.length > 0 && storedMessages[0].sender === 'user') {
            const formattedStoredMessages = storedMessages.map(message => ({
              role: message.sender === 'Tess' ? 'model' : message.sender,
              parts: [{ text: message.text }],
            }));
            await chatBotCommunicator.startChat(formattedStoredMessages);
          }
        } // This closing brace was missing
        // console.log("Input Text: ", text);
        const responseStream = chatBotCommunicator.generateContentStream(text);
        for await (const response of responseStream) {
          if (response && typeof response === 'object' && response.text) {
            if (isFirstResponse) {
              // Create a new message for the first response
              updatedMessages = [
                ...updatedMessages,
                { text: response.text, sender: 'Tess' },
              ];
              isFirstResponse = false;
            } else {
              // Append subsequent responses to the last message
              updatedMessages[updatedMessages.length - 1].text += response.text;
              setLastUpdated(Date.now()); // Trigger a re-render
            }
            setMessages(updatedMessages); // Set messages immediately after each AI's response is added
            localStorage.setItem('messages', JSON.stringify(updatedMessages)); // Save messages to local storage immediately after each AI's response is added
          } else {
            console.error("Error: Invalid response from chatBotCommunicator.generateContentStream");
          }
        }
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the message list whenever messages update
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Box>
      <Box flex={1} display="flex" flexDirection="column" margin="0px" >
        <Box flex={1} p={0}>
          <MessageList messages={messages} clearMessages={clearMessages} isProcessing={isProcessing} lastUpdated={lastUpdated} />
          <div ref={messagesEndRef} /> 
        </Box>
        <Box p={1} m={0} sx={{ position: "fixed", bottom: '35px', width: '85vw' }}>
          <ChatInput sendMessage={(inputObject) => sendMessage(inputObject, () => chatBotCommunicator.generateContentStream(inputObject.text))} clearMessages={clearMessages} isProcessing={isProcessing} />
        </Box>
      </Box>
    </Box>
  );
}

export default TalkToTess;
