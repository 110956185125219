import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Paper, Typography, Dialog, DialogTitle, DialogContentText, DialogActions, Divider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import DataService from '../../../classes/DataService';
import ProjectAlerts from '../ProjectAlerts';
import MemberManagement from './MemberManagement';
import { useAuth0 } from '@auth0/auth0-react';

export default function ProjectSettings({ project, setProject, projectMembers, setProjectMembers, userPlan }) {
    const [name, setName] = useState(project.name);
    const [description, setDescription] = useState(project.description);
    const [isModified, setIsModified] = useState(false);
    const [open, setOpen] = useState(false);
    const [openExit, setOpenExit] = useState(false);
    const [alert, setAlert] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);    

    useEffect(() => {
        setIsModified(name !== project.name || description !== project.description);
    }, [name, description, project.name, project.description]);

    const handleExitConfirm = () => {
        // Handle exit confirmation here
        setOpenExit(false);
    };

    const handleSave = async () => {
        if (isModified) {
            const updatedProject = await DataService.updateProjectDetails(project.project_id, { name, description });
            setProject(updatedProject);
            setIsModified(false);
            setAlert({ message: 'Project Details Saved', severity: 'success', autoHideDuration: 6000 });
        }
    };

    const handleDelete = async () => {
        setOpenDelete(false);
        const updatedProject = await DataService.updateProjectDetails(project.project_id, { is_active: false });
        setProject(updatedProject);
        navigate('/projects');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const removeAlert = () => {
        setAlert(null);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
                paddingBottom: '50px'
            }}
                >
            <Typography variant="h6">Project Details</Typography>
            {loading ? (
                <Box display="flex" flexDirection="column" height="100%" sx={{ marginTop: '10px' }}>
                    <Skeleton variant="rounded" animation="wave" width="50%" height="55px" />
                    <Skeleton variant="rounded" animation="wave" width="50%" height="200px" sx={{ marginTop: '10px' }} />
                </Box>
            ) : (
                <>
                    <Box>
                        <TextField
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ width: '50%' }}
                        />
                    </Box>
                    <Box>
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            // minRows={3}
                            // maxRows={10}
                            rows={5}
                            sx={{ width: '50%' }}
                        />
                    </Box>
                    <Box>
                        <Button
                            variant="text"
                            color="primary"
                            disabled={!isModified}
                            onClick={handleSave}
                        >
                            Save Details
                        </Button>
                    </Box>
                </>
            )}
            <Divider />
            <Box>
                {loading ? (
                    <>
                        <Typography variant='h6'>Project Members</Typography>
                        <Skeleton variant="rounded" animation="wave" width="100%" height="100px" />
                    </>
                ) : (
                    userPlan !== 'Free' && ( 
                        <MemberManagement project={project} projectMembers={projectMembers} setProjectMembers={setProjectMembers} />
                    )
                )}
            </Box>
            {userPlan !== 'Free' && ( <Divider /> )}
            {user.sub === project.owner_id && (
                <Box>
                    <Button variant="text" color="error" onClick={() => setOpenDelete(true)}>Delete Project</Button>
                </Box>
            )}
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
            >
                    <DialogTitle>Delete {project.name}?</DialogTitle>
                <DialogContentText>
                    <Paper variant='outlined' sx={{ backgroundColor: '#ffeded', padding: '10px', margin: '0 20px' }}>
                        <b>This action is irreversible and cause you to lose all project data.</b><br/>Are you sure you want to delete this project?
                    </Paper>
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleDelete} color="error">
                        Yes, Delete the project
                    </Button>
                    <Button onClick={() => setOpenDelete(false)} color="primary">
                        No, Don't delete
                    </Button>
                </DialogActions>
            </Dialog>
            <ProjectAlerts alert={alert} removeAlert={removeAlert} />
        </Box>
    );
}