import React, { useState, useEffect } from 'react';
import DataService from '../../../classes/DataService.js';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Divider } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';

const StoryComments = ({ selectedStoryForEdit, projectMembers }) => {
    const [storyComments, setStoryComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const Auth0_user = useAuth0().user;
    const [editingComment, setEditingComment] = useState(null);
    const [editedComment, setEditedComment] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);

    const handleConfirmDelete = () => {
        DataService.deleteComment(commentToDelete)
        .then(response => {
            // Remove the comment from the local state
            setStoryComments(storyComments.filter(comment => comment.comment_id !== commentToDelete));
        })
        .catch(e => {
            console.error("There was a problem deleting the comment: ", e);
        });
        setOpenDialog(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCommentClick = (comment) => {
        if (Auth0_user.sub === comment.created_by) {
            setEditingComment(comment.comment_id);
            setEditedComment(comment.comment);
        }
    };

    const handleEditedCommentChange = (event) => {
        setEditedComment(event.target.value);
    };
    
    const handleEditedCommentSubmit = (event) => {
        event.preventDefault();
        // Call the function to update the comment in the database
        DataService.updateComment(editingComment, { comment: editedComment })
        .then(response => {
            // Update the comment in the local state
            setStoryComments(storyComments.map(comment => 
                comment.comment_id === editingComment ? { ...comment, comment: editedComment } : comment
            ));
            setEditingComment(null);
            setEditedComment('');
        })
        .catch(e => {
            console.error("There was a problem updating the comment: ", e);
        });
    };

    const createNewComment = (newComment) => {
        const commentData = {
            "user_story_id": selectedStoryForEdit.user_story_id,
            "created_by": Auth0_user.sub,
            "created_on": new Date().toISOString(),
            "comment": newComment
        };
        // console.log("Comment Data: ", commentData);

        DataService.createComment(commentData)
        .then(response => {
            // console.log('Comment created successfully:', response);
        })
        .catch(e => {
            console.error("There was a problem creating the comment: ", e);
        });
    };

    const handleNewCommentChange = (event) => {
        setNewComment(event.target.value);
    };
    
    const handleNewCommentSubmit = (event) => {
        event.preventDefault();
        createNewComment(newComment);
        setNewComment('');
    };

    const handleDeleteComment = (comment_id) => {
        // Open the dialog and set the comment to delete
        setOpenDialog(true);
        setCommentToDelete(comment_id);
    };

    useEffect(() => {
        if (selectedStoryForEdit && selectedStoryForEdit.user_story_id) {
            DataService.getStoryComments(selectedStoryForEdit.user_story_id)
            .then(response => {
                const commentsWithEmail = response.map(comment => {
                    const projectMember = projectMembers.find(member => member.user_id === comment.created_by);
                    return { 
                        ...comment,
                        email: projectMember ? projectMember.email : ''
                    };
                });

                // Sort comments by date
                commentsWithEmail.sort((a, b) => {
                    const dateA = new Date(a.created_on);
                    const dateB = new Date(b.created_on);
                    return dateB - dateA; // for descending order
                });

                setStoryComments(commentsWithEmail);
                // console.log("Story Comments: ", commentsWithEmail);
            })
            .catch(e => {
                console.error("There was a problem fetching the story comments: ", e);
            });
        }
    }, [selectedStoryForEdit, projectMembers, storyComments]);

    return (
        <div>
            <h3>Comments</h3>
                <form onSubmit={handleNewCommentSubmit}>
                    <TextField
                        value={newComment}
                        onChange={handleNewCommentChange}
                        placeholder="Write a comment..."
                        fullWidth
                        multiline
                        maxRows={5}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleNewCommentSubmit(e);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            {storyComments.length > 0 ? (
                <List>
                    {storyComments.map((comment, index) => {
                        const newestDate = comment.edited_on > comment.created_on ? comment.edited_on : comment.created_on;
                        return (
                            <>
                                <ListItem key={index} sx={{ display: 'flex', alignItems: 'start' }}>
                                    <ListItemAvatar>
                                        <Avatar 
                                            src={projectMembers.find(member => member.user_id === comment.created_by)?.user_metadata?.picture || projectMembers.find(member => member.user_id === comment.created_by)?.picture} 
                                            sx={{ width: 40, height: 40 }}
                                        />
                                    </ListItemAvatar>
                                    {editingComment === comment.comment_id ? (
                                        <form onSubmit={handleEditedCommentSubmit} style={{ width: '100%' }}>
                                            <TextField
                                                autoFocus
                                                value={editedComment}
                                                onChange={handleEditedCommentChange}
                                                fullWidth
                                                multiline
                                                maxRows={5}
                                                onBlur={() => setTimeout(() => setEditingComment(null), 200)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                        e.preventDefault();
                                                        handleEditedCommentSubmit(e);
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton type="submit">
                                                                <SendIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </form>
                                    ) : (
                                        <ListItemText
                                            primary={comment.comment}
                                            secondary={
                                                <>
                                                    <Typography component="span" variant="caption" color="textSecondary" display="block" sx={{ marginTop: '10px' }}>
                                                        {projectMembers.find(member => member.user_id === comment.created_by)?.name} - {comment.email}
                                                    </Typography>
                                                    <Typography component="span" variant="caption" color="textSecondary" display="block">
                                                        {new Date(newestDate).toLocaleString(undefined, {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            hour12: true
                                                        })}
                                                    </Typography>
                                                </>
                                            }
                                            sx={{ cursor: Auth0_user.sub === comment.created_by ? 'pointer' : 'auto' }}
                                            onClick={() => handleCommentClick(comment)}
                                        />
                                    )}
                                    {Auth0_user.sub === comment.created_by && (
                                        <Tooltip title="Delete comment">
                                            <IconButton onClick={() => handleDeleteComment(comment.comment_id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </ListItem>
                                {index !== storyComments.length - 1 && <Divider />}
                            </>
                        );
                    })}
                </List>
            ) : (
                <p>No comments yet</p>
            )}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this comment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StoryComments;