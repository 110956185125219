import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './styles/App.css';
import AppMenu from './Components/AppMenu.js';
import AppRouter from './Components/AppRouter.js';
import Header from './Components/Header.js';
import EmailVerification from './Components/EmailVerification.js';
import PlanSelection from './Components/Registration/PlanSelection.js'; // Import PlanSelection component
import { Box, createTheme, ThemeProvider, CssBaseline, Typography } from '@mui/material';
import './styles/ScrollbarStyles.css';
import AiMessenger from './Components/AiMessenger/AiMessenger.js';
import DataService from './classes/DataService';
import Loading from './Components/Loading.js';

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(100,0,0,.1)',
          outline: '1px solid slategrey'
        }
      }
    }
  }
});

function App() {
  const { user, isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims, logout } = useAuth0();
  const [userProfileImage, setUserProfileImage] = useState(null);
  const [userName, setUserName] = useState(null);
  const [planLoading, setPlanLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const isRootRoute = location.pathname === '/talk-to-tess';
  const routerContainerClass = isRootRoute ? 'TTTAppRouterContainer custom-scrollbar' : 'DefaultAppRouterContainer custom-scrollbar';
  const [stripeSubscription, setStripeSubscription] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const [fullUser, setFullUser] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [planSelection, setPlanSelection] = useState(false);
  const [daysLeftOnTrial, setDaysLeftOnTrial] = useState(null);

  useEffect(() => {
    sessionStorage.setItem('returnTo', location.pathname);
    getIdTokenClaims().then((claims) => {});
  }, [location.pathname]);

  useEffect(() => {
    fetchUser();
  }, [user]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  const fetchUser = async () => {
    if (user) {
      try {
        const fullUser = await DataService.getAuth0UserByID(user.sub);
        if (user.email_verified) {
          setEmailVerified(true);
        }
        setFullUser(fullUser);
        setUserName(fullUser.name);
        if (user.sub.startsWith('auth0')) {
          setUserProfileImage(fullUser.picture);
        } else if (fullUser && fullUser.user_metadata && fullUser.user_metadata.picture) {
          setUserProfileImage(fullUser.user_metadata.picture);
        } else {
          setUserProfileImage(fullUser.picture);
        }
        getStripeSubscription(fullUser);
        calculateDaysLeftOnTrial(fullUser);
      } catch (error) {
        console.error('Error fetching user:', error);
        logout({ returnTo: window.location.origin });
      }
    }
  };

  const getStripeSubscription = (fullUser) => {
    DataService.getStripeSubscriptionInfo(fullUser.email)
      .then((subscription) => {
        if (subscription === 'No Stripe subscription found') {
          setStripeSubscription({ 'message': 'No Stripe subscription', 'productName': 'Professional' });
          if (fullUser.user_metadata === undefined || fullUser.user_metadata.meteor_plan_id === undefined) {
            setStripeSubscription(subscription);
          }
          if (!fullUser.user_metadata || !fullUser.user_metadata.free_trial_expiration_date) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 7);
            fullUser.user_metadata = {
              ...fullUser.user_metadata,
              free_trial_expiration_date: expirationDate.toISOString()
            };
            DataService.updateUserMetaData(user.sub, fullUser.user_metadata)
              .then(() => {
                console.log('Free trial expiration date set to:', expirationDate.toISOString());
              })
              .catch((error) => {
                console.error('Error updating user metadata:', error);
              });
          } else {
            const expirationDate = new Date(fullUser.user_metadata.free_trial_expiration_date);
            const currentDate = new Date();
            if (currentDate > expirationDate) {
              setPlanSelection(true);
            }
          }
        } else {
          subscription.productName = "Professional";
          setPlanSelection(false);
          setStripeSubscription(subscription);
        }
      })
      .catch((error) => {
        console.error('Error fetching Stripe subscription:', error);
      });
    setPlanLoading(false);
  }

  const calculateDaysLeftOnTrial = (fullUser) => {
    if (fullUser && fullUser.user_metadata && fullUser.user_metadata.free_trial_expiration_date) {
      const expirationDate = new Date(fullUser.user_metadata.free_trial_expiration_date);
      const currentDate = new Date();
      const timeDiff = expirationDate - currentDate;
      const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      setDaysLeftOnTrial(daysLeft);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isLoading || planLoading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'auto' }}>
          <Loading />
        </Box>
      ) : !emailVerified ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'auto' }}>
          <EmailVerification fullUser={fullUser} />
        </Box>
      ) : planSelection ? (
        <PlanSelection />
      ) : isAuthenticated ? (
        <>
          <Header userProfileImage={userProfileImage} stripeSubscription={stripeSubscription} fullUser={fullUser} daysLeftOnTrial={daysLeftOnTrial} sx={{ width: '100%', overflow: 'auto' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto', height: 'calc(100vh - 40px)', marginBottom: '20px' }}>
            <Box className='custom-scrollbar' sx={{ flexGrow: 1 }}>
              <div className="AppContainer">
                <div className="AppContent">
                  <div className="AppMenu">
                    <AppMenu />
                  </div>
                  <div className={`${routerContainerClass}`} style={{ overflow: 'auto' }}>
                    <AppRouter className='custom-scrollbar' userProfileImage={userProfileImage} setUserProfileImage={setUserProfileImage} userName={userName} setUserName={setUserName} stripeSubscription={stripeSubscription} fullUser={fullUser} daysLeftOnTrial={daysLeftOnTrial} />
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </>
      ) : (
        <Loading />
      )}
      <Box>
        <AiMessenger />
      </Box>
      <Box display='flex' alignItems='flex-start' justifyContent='space-between' p={1} m={0} sx={{ backgroundColor: '#707387', position: 'fixed', width: '100%', bottom: 0 }}>
        <Typography variant="body2" color="white" align="left" sx={{ fontSize: '0.7rem' }}>
          Meteor v1 © {new Date().getFullYear()} IMRS LLC. All rights reserved.
        </Typography>
        <Typography variant="body2" color="white" align="left" sx={{ fontSize: '0.7rem' }}>
          *AI can sometimes display unexpected or inaccurate responses, so double-check any important information.
        </Typography>
      </Box>
    </ThemeProvider>
  )
};

export default App;