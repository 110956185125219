import React from 'react';
import { Snackbar, ClickAwayListener } from '@mui/material';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

export default function ProjectAlerts({ alert, removeAlert }) {
  return (
    alert ? (
      <ClickAwayListener onClickAway={() => {}}>
        <Snackbar 
          open={true}
          autoHideDuration={alert.autoHideDuration}
          onClose={(event, reason) => {
            if (reason === 'timeout') {
              removeAlert();
            }
          }}
        >
          <Alert onClose={removeAlert} severity={alert.severity}>
            {alert.message}
            {alert.severity === 'info' && <LinearProgress />}
          </Alert>
        </Snackbar>
      </ClickAwayListener>
    ) : null
  );
}