import React, { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Parser, HtmlRenderer } from 'commonmark';
import DataService from '../../../classes/DataService';
import '../../../styles/RichTextEditorStyles.css';

const DocumentEdit = ({ 
  project, 
  projectDocuments, 
  setProjectDocuments, 
  selectedDocument, 
  setSelectedDocument, 
  setEditing,
  projectContext,
  setProjectContext }) => {
  const [hasEnteredContent, setHasEnteredContent] = useState(false);
  const [value, setValue] = useState('');
  const [wasScrolledToBottom, setWasScrolledToBottom] = useState(false);
  const editorContainerRef = useRef(null);
  const editorEndRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
  const [shouldExitAfterSave, setShouldExitAfterSave] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (selectedDocument && selectedDocument.body) {
      let documentBody = selectedDocument.body;
      setValue(documentBody);
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (isSaveSuccessful && shouldExitAfterSave) {
      exit();
    }
  }, [isSaveSuccessful, shouldExitAfterSave]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handlePublishClick = () => {
    setShowPublishConfirmation(true);
  };
  
  const handleConfirmPublish = async () => {
    setShowPublishConfirmation(false);
    await publishDocument();
  };
  
  const handleCancelPublish = () => {
    setShowPublishConfirmation(false);
  };

  const handleExitClick = () => {
    if (hasEnteredContent) {
      setShowExitConfirmation(true);
    } else {
      exit();
    }
  };
  
  const handleConfirmExit = () => {
    setShowExitConfirmation(false);
    exit();
  };
  
  const handleCancelExit = () => {
    setShowExitConfirmation(false);
  };

  const saveAndExitDocument = async () => {
    setShouldExitAfterSave(true);
    try {
      const updatedDocument = await DataService.updateDocument(selectedDocument.document_id, { body: value });
      if (updatedDocument) {
        setSelectedDocument(updatedDocument);

        const documents = projectDocuments.map(document => 
          document.document_id === updatedDocument.document_id ? updatedDocument : document
        );

        // Update the projectDocuments state directly
        setProjectDocuments(documents);
        setProjectContext(prevContext => ({
          ...prevContext,
          DOCUMENTS: documents
        }));

        setIsSaveSuccessful(true);
      } else {
        console.error('Failed to update document: No data returned');
      }
    } catch (error) {
      console.error('Failed to update document:', error);
    } finally {
    }
  };

  const saveWithoutExit = async () => {
    setShouldExitAfterSave(false);
    try {
      const updatedDocument = await DataService.updateDocument(selectedDocument.document_id, { body: value });
      if (updatedDocument) {
        setSelectedDocument(updatedDocument);
        const documents = projectDocuments.map(document =>
          document.document_id === updatedDocument.document_id ? updatedDocument : document
        );
        // Update the projectDocuments state directly
        setProjectDocuments(documents);
        setProjectContext(prevContext => ({
          ...prevContext,
          DOCUMENTS: documents
        }));
        setIsSaveSuccessful(true);
        setHasEnteredContent(false);
      } else {
        console.error('Failed to update document: No data returned');
      }
    } catch (error) {
      console.error('Failed to update document:', error);
    }
  };

  const publishDocument = async () => {
    try {
      // Change the status to 'published'
      const updatedDocument = await DataService.updateDocument(selectedDocument.document_id, { status: 'published' });
      if (updatedDocument) {
        setSelectedDocument(updatedDocument);
        
        const documents = projectDocuments.map(document => 
          document.document_id === updatedDocument.document_id ? updatedDocument : document
        );
        
        // Update the projectDocuments state directly
        setProjectDocuments(documents);
        setProjectContext(prevContext => ({
          ...prevContext,
          DOCUMENTS: documents
        }));
        exit();
      } else {
        console.error('Failed to publish document: No data returned');
      }
    } catch (error) {
      console.error('Failed to publish document:', error);
    }
  };

  const handleClose = () => {
    if (isSaveSuccessful) {
      setIsSaveSuccessful(false);
    }
  };

  const exit = () => {
    setHasEnteredContent(false);
    setEditing(false);
  };

  const onChange = (newValue) => {
    const isNearBottom = editorContainerRef.current.scrollHeight - editorContainerRef.current.scrollTop - editorContainerRef.current.clientHeight <= 100;
    if (hasMounted && newValue !== value) {
      setHasEnteredContent(true);
    }
    setWasScrolledToBottom(isNearBottom);
    setValue(newValue);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link']                         // link
    ]
  };

  return (
    <Box className='edit-view'>
      <Box>
        <Button 
          onClick={handleExitClick}
          sx={{ margin: '10px 0' }}
        >
          Exit
        </Button>
        <Button 
          onClick={saveAndExitDocument}
          sx={{ margin: '10px 0' }}
          disabled={!hasEnteredContent}
        >
          Save and Exit
        </Button>
        <Button 
          onClick={saveWithoutExit}
          sx={{ margin: '10px 0' }}
          disabled={!hasEnteredContent}
        >
          Save
        </Button>
        {selectedDocument.status === 'draft' && (
          <Button 
            onClick={handlePublishClick}
            sx={{ margin: '10px 0' }}
          >
            Publish
          </Button>
        )}
      </Box>
      <Box sx={{ height: '100%', width: '100%' }} ref={editorContainerRef}>
        <ReactQuill
          value={value}
          onChange={onChange}
          theme="snow"
          modules={{
            toolbar: modules.toolbar,
            clipboard: {
              matchVisual: false
            }
          }}
          style={{ height: '100%', width: '100%' }}
        />
        <div ref={editorEndRef} />
      </Box>
      <Dialog
        open={isSaving || isSaveSuccessful}
        onClose={handleClose}
      >
        <DialogTitle>{"Document Save Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isSaving ? "Saving document..." : isSaveSuccessful ? "Document saved successfully!" : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showExitConfirmation}
        onClose={handleCancelExit}
      >
        <DialogTitle>{"Exit Without Saving"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"You have unsaved changes. Are you sure you want to exit without saving?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleCancelExit}>
            Cancel
          </Button>
          <Button onClick={handleConfirmExit}>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showPublishConfirmation}
        onClose={handleCancelPublish}
      >
        <DialogTitle>{"Publish Document"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Publishing the document will make it visible to all project collaborators. Are you sure you want to do that?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleCancelPublish}>
            Cancel
          </Button>
          <Button onClick={handleConfirmPublish}>
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DocumentEdit;