import React from 'react';
import { Box } from '@mui/material';
import AiChatButton from './AiChatButton/AiChatButton.js';
import AiChatWindow from './AiChatWindow/AiChatWindow.js';
import AiMessengerController from './AiMessenger.controller.js';

export default function AiMessenger() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const controller = new AiMessengerController(setAnchorEl, () => anchorEl);

    return (
        <Box>
            <AiChatWindow
                anchorEl={anchorEl}
                handleClick={controller.handleClick}
                handleClose={controller.handleClose}
            />
            <AiChatButton 
                anchorEl={anchorEl}
                handleClick={controller.handleClick}
                handleClose={controller.handleClose}
            />
        </Box>
    )
}