import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Tooltip, Box, Badge } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Free from '@mui/icons-material/Sell.js';
import Basic from '@mui/icons-material/Verified.js';
import Standard from '@mui/icons-material/Speed.js';
import Professional from '@mui/icons-material/VerifiedUser.js';
import CheckCircle from '@mui/icons-material/CheckCircle.js';
import { useAuth0 } from '@auth0/auth0-react';
import DataService from '../../classes/DataService';
import '../../styles/PlanSelection.css';
import { ConnectingAirportsOutlined } from '@mui/icons-material';

const PlanSelection = () => {
    const { user, isLoading } = useAuth0();
    const [Auth0User, setAuth0User] = useState(null);
    const freeUserPlanId = 'Free';
    const navigate = useNavigate();
    // Stripe test plan links
    // const stripeMontlyPlanLink = "https://buy.stripe.com/test_28o3dJaYg6FcefSdQU";
    // const stripeAnnualPlanLink = "https://buy.stripe.com/test_6oE5lR2rK8Nk7Ru005";
    //Stripe live plan links
    const stripeMontlyPlanLink = "https://buy.stripe.com/4gw4j307C8Dj9Q4001";
    const stripeAnnualPlanLink = "https://buy.stripe.com/5kA2aV8E86vb3rGaEE";

    
    useEffect(() => {
        const fetchAuth0User = async () => {
            try {
                if (user && user.email) {
                    const auth0User = await DataService.getAuth0UserByEmail(user.email);
                    setAuth0User(auth0User);
                }
            } catch (error) {
                console.error('Error fetching Auth0 user:', error);
            }
        };

        if (!isLoading) {
            fetchAuth0User();
        }
        // console.log('isLoading Plan Selection:', isLoading);
    }, [user, isLoading]);

    const SetFreeUserPlan = async () => {
        try {
            if (Auth0User && Auth0User[0] && Auth0User[0].user_id) {
                const userId = Auth0User[0].user_id;
                // console.log('User ID:', userId);
                const userMetadata = {
                    meteor_plan_id: freeUserPlanId
                };
                // console.log('User Metadata:', userMetadata);
                const updateResponse = await DataService.updateUserMetaData(userId, userMetadata);
                navigate('/');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error updating user metadata:', error);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }
    
    return (
        <Box sx={{ backgroundColor: 'white', paddingBottom: '30px' }}>
            <Typography variant="h3" align='center' sx={{ fontWeight: 'bold', padding: '20px 0' }}>Thanks for trying Meteor!</Typography>
            <Typography variant="body1" align='center' sx={{ margin: '20px 0' }}>Now that your free trial is over, you can subscribe on a monthly or annual basis to continue using Meteor.</Typography>
            <Box style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <Card elevation={3} sx={{ minWidth: 250, maxWidth: 345 }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Professional sx={{ color: '#E62824' }} />
                                <Typography variant='h6' sx={{ color: '#E62824', fontWeight: 'bold', marginLeft: '8px' }}>
                                    Monthly
                                </Typography>
                            </div>
                        
                        <Typography variant='h5' sx={{ fontWeight: 'bold', marginTop: '16px' }}>
                            $24.99
                        </Typography>
                        
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <a href={`${stripeMontlyPlanLink}?prefilled_email=${encodeURIComponent(user.email)}`}>
                            <Button variant='contained' sx={{ backgroundColor: '#E62824', color: 'white' }}>Subscribe</Button>
                        </a>
                    </CardActions>
                </Card>
                <Card elevation={3} sx={{ minWidth: 250, maxWidth: 345 }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Professional sx={{ color: '#E62824' }} />
                                <Typography variant='h6' sx={{ color: '#E62824', fontWeight: 'bold', marginLeft: '8px' }}>
                                    Annually
                                </Typography>
                            </div>
                        <Typography variant='h5' sx={{ fontWeight: 'bold', marginTop: '16px' }}>
                            <Badge color='success' variant='string' badgeContent='20% Off!'>
                                $239.99
                            </Badge>
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <a href={`${stripeAnnualPlanLink}?prefilled_email=${encodeURIComponent(user.email)}`}>
                            <Button variant='contained' sx={{ backgroundColor: '#E62824', color: 'white' }}>Subscribe</Button>
                        </a>
                    </CardActions>
                </Card>
            </Box>
        </Box>
    );
};

export default PlanSelection;