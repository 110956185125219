import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const LoadingIndicator = ({ size = 20 }) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" paddingRight="15px">
            <CircularProgress color="primary" size={size} />
        </Box>
    );
};

export default LoadingIndicator;
