import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemText, Typography, Box, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import { createTheme } from '@mui/material/styles/index.js';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome.js';
import ChatIcon from '@mui/icons-material/Chat.js';
import AssessmentIcon from '@mui/icons-material/Assessment.js';
import Avatar from '@mui/material/Avatar/index.js';
// import SettingsIcon from '@mui/icons-material/Settings.js';
import AccountCircleIcon from '@mui/icons-material/AccountCircle.js';
import LogoutIcon from '@mui/icons-material/Logout.js'; // Import the Logout icon
import { useAuth0 } from '@auth0/auth0-react'; // Import the useAuth0 hook
import { SubscriptionContext } from '../contexts/SubscriptionContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5F8575',
    },
  },
});

const AppMenu = () => {
  const [, setSpaces] = useState([]); // State to hold the spaces
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logout } = useAuth0(); // Get the user and logout function from the useAuth0 hook
  const { subscription } = useContext(SubscriptionContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <Box
        sx={{
          width: 200,
          flexShrink: 0,
          backgroundColor: '#f5f8fc',
          borderRight: '1px solid #F4F4F4',
          height: 'calc(100vh - 50px)'
        }}
        anchor="left"
      >
        <List>
          <ListItem button component={Link} to="/" selected={location.pathname === "/"}>
            <HomeIcon fontSize='small' sx={{ marginRight: '.5rem'}} />
            <ListItemText primary="Home" sx={{ userSelect: 'none' }} />
          </ListItem>
            {/* {subscription === 'professional' && (
              <Tooltip
                title="Upgrade to the Professional plan to unlock this feature"
                placement="right"
                disableHoverListener={subscription === 'professional'}
                disableInteractive
                arrow
              >
                <ListItem 
                  button={subscription === 'professional'} // Only render as a button if the subscription is 'professional'
                  component={subscription === 'professional' ? Link : 'div'} // Only render as a Link if the subscription is 'professional'
                  to="/talk-to-tess" 
                  selected={location.pathname === "/talk-to-tess"}
                  cursor={subscription === 'professional' ? 'pointer' : 'not-allowed'} // Change the cursor based on the subscription 
                >
                  <ChatIcon 
                    fontSize='small' 
                    sx={{ 
                      marginRight: '.5rem',
                      color: subscription !== 'professional' ? 'grey' : 'inherit' // Apply grey color if the subscription is not 'professional'
                    }}
                  />
                  <ListItemText 
                    primary="Talk to Tess" 
                    sx={{ 
                      userSelect: 'none',
                      color: subscription !== 'professional' ? 'grey' : 'inherit' // Apply grey color if the subscription is not 'professional'
                    }}
                  />
                  {subscription !== 'professional' && ( // Conditionally render the Chip
                    <Chip icon={<AutoAwesomeIcon />} label="Pro" color='success' size="small" />
                  )}
                </ListItem>
              </Tooltip>
            )} */}
          <ListItem button component={Link} to="/projects" selected={location.pathname === "/projects"}>
            <AssessmentIcon fontSize='small' sx={{ marginRight: '.5rem'}} />
            <ListItemText primary="Projects" sx={{ userSelect: 'none' }} />
          </ListItem>
        </List>
        {/* <Box 
          display="flex" 
          alignItems="center" 
          sx={{ 
            position: 'absolute', 
            bottom: 0, 
            width: '100%', 
            padding: '1rem',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
              cursor: 'pointer',
            },
            transition: 'background-color 0.3s',
          }}
          onClick={handleClick}
        >
          <Avatar src={user.picture} sx={{ marginRight: '1rem', width: 35, height: 35 }}>U</Avatar>
          <Typography variant="body1">{user.name}</Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem onClick={handleClose} sx={{ width: 200 }}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
          </Link>
          <Divider />
          <MenuItem onClick={() => { handleClose(); logout(); }} sx={{ width: 200 }}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu> */}
      </Box>
  );
};

export default AppMenu;