import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Pagination, Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Skeleton from '@mui/material/Skeleton';
import DataService from '../../../classes/DataService';
import ViewStory from './ViewStory';
import '../../../styles/AllStories.css';

export default function AllStories({ 
    //projectStories, 
    //projectEpics, 
    projectMembers,
    projectContext, 
    setProjectContext
}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [filterCriteria, setFilterCriteria] = useState({});
    const [sprints, setSprints] = useState({});
    const [displayedStories, setDisplayedStories] = useState([...projectContext?.STORIES || []]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('title');
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedStoryForEdit, setSelectedStoryForEdit] = useState(null);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    const statusDisplayNames = {
        'todo': 'TODO',
        'in-progress': 'IN PROGRESS',
        'done': 'DONE',
        'inactive': 'DONE'
    };

    useEffect(() => {
        if (projectContext?.STORIES) {
            setLoading(true);
            setDisplayedStories([...projectContext.STORIES]);
            setLoading(false);
        }
    }, [projectContext?.STORIES]);

    // todo: remove, redundant since we have project context :)
    // useEffect(() => {
    //     setLoading(true);
    //     const fetchSprints = async () => {
    //         const newSprints = {};
    //         for (const story of projectStories) {
    //             if (story.sprint_id) {
    //                 const sprint = await DataService.getSprintById(story.sprint_id);
    //                 newSprints[story.sprint_id] = sprint;
    //             } else {
    //                 newSprints[story.sprint_id] = { name: '', start_date: '', end_date: '' };
    //             }
    //         }
    //         setSprints(newSprints);
    //     };
    //     // setTimeout(() => setLoading(false), 2000);
    //     setLoading(false);

    //     fetchSprints();
    // }, [projectStories]);
  
    // useEffect(() => {
    //   setDisplayedStories([...projectStories]);
    // }, [projectStories]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleSort = (column) => {
        const isAsc = orderBy === column && order === 'asc';
        const sortedStories = [...displayedStories].sort((a, b) => {
            let aValue = a[column];
            let bValue = b[column];
            if (column === 'epic') {
                aValue = projectContext?.EPICS?.find(epic => epic.epic_id === a.epic_id)?.name || '';
                bValue = projectContext?.EPICS?.find(epic => epic.epic_id === b.epic_id)?.name || '';
            } else if (column === 'sprint') {
                aValue = projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === a.sprint_id)?.name || '';
                bValue = projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === b.sprint_id)?.name || '';
            } else if (column === 'start_date') {
                aValue = projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === a.sprint_id)?.start_date || '';
                bValue = projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === b.sprint_id)?.start_date || '';
            } else if (column === 'end_date') {
                aValue = projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === a.sprint_id)?.end_date || '';
                bValue = projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === b.sprint_id)?.end_date || '';
            }
            if (aValue < bValue) {
                return isAsc ? -1 : 1;
            }
            if (aValue > bValue) {
                return isAsc ? 1 : -1;
            }
            return 0;
        });
        setDisplayedStories(sortedStories);
    };

    const handleClose = () => {
        setSelectedStoryForEdit(null);
        setSelectedRow(null);
        setOpen(false);
      };
  
      const handleSearch = (term) => {
        const filteredStories = displayedStories?.filter(story => {
            const sprintName = story.sprint_id && projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === story.sprint_id)?.name;
            const epicName = story.epic_id && projectContext?.EPICS?.find(epic => epic.epic_id === story.epic_id)?.name;
    
            return (
                story.title.toLowerCase().includes(term.toLowerCase()) ||
                (sprintName && sprintName.toLowerCase().includes(term.toLowerCase())) ||
                (epicName && epicName.toLowerCase().includes(term.toLowerCase())) ||
                statusDisplayNames[story.status].toLowerCase().includes(term.toLowerCase())
            );
        });
    
        setDisplayedStories(filteredStories);
    };
    
      
    const filteredStories = displayedStories?.filter(story => {
        //const sprint = story.sprint_id && sprints[story.sprint_id];
        //const epic = story.epic_id && projectEpics.find(e => e.epic_id === story.epic_id);

        const sprint = story.sprint_id && projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === story.sprint_id);
        const epic = story.epic_id && projectContext?.EPICS?.find(e => e.epic_id === story.epic_id);

        return story?.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (sprint && (
                sprint.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                new Date(sprint.start_date).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                new Date(sprint.end_date).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase())
            )) ||
            (epic && epic.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            statusDisplayNames[story.status].toLowerCase().includes(searchTerm.toLowerCase());
    });

    const handleRequestSort = (event, property) => {
        // console.log('handleRequestSort called with', property);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        handleSort(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const paginatedStories = filteredStories.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const start = (page - 1) * rowsPerPage + 1;
    const end = Math.min(page * rowsPerPage, filteredStories.length);
    const total = filteredStories.length;
    
  return loading ? (
    <Box>
        <Skeleton variant="rounded" animation="wave" width="100%" height="55px" />
        <Skeleton variant="rounded" animation="wave" width="100%" height="400px" sx={{ marginTop: '20px' }} />
    </Box>
  ) : (
    <div style={{ marginBottom: '20px' }}>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchTerm('')}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          ),
        }}
      />
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell sortDirection={orderBy === 'title' ? order : false}>
                        <TableSortLabel
                            active={orderBy === 'title'}
                            direction={orderBy === 'title' ? order : 'asc'}
                            onClick={createSortHandler('title')}
                            style={{ 
                                color: 'white',
                                fontWeight: 'bold'
                             }}
                        >
                            Title
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={orderBy === 'epic' ? order : false}>
                        <TableSortLabel
                            active={orderBy === 'epic'}
                            direction={orderBy === 'epic' ? order : 'asc'}
                            onClick={createSortHandler('epic')}
                            style={{ 
                                color: 'white',
                                fontWeight: 'bold'
                             }}
                        >
                            Epic
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={orderBy === 'sprint' ? order : false}>
                        <TableSortLabel
                            active={orderBy === 'sprint'}
                            direction={orderBy === 'sprint' ? order : 'asc'}
                            onClick={createSortHandler('sprint')}
                            style={{ 
                                color: 'white',
                                fontWeight: 'bold'
                             }}
                        >
                            Sprint
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'start_date'}
                            direction={orderBy === 'start_date' ? order : 'asc'}
                            onClick={createSortHandler('start_date')}
                            style={{ color: 'white', fontWeight: 'bold' }}
                        >
                            Sprint Start Date
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'end_date'}
                            direction={orderBy === 'end_date' ? order : 'asc'}
                            onClick={createSortHandler('end_date')}
                            style={{ color: 'white', fontWeight: 'bold' }}
                        >
                            Sprint End Date
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={orderBy === 'status' ? order : false}>
                        <TableSortLabel
                            active={orderBy === 'status'}
                            direction={orderBy === 'status' ? order : 'asc'}
                            onClick={createSortHandler('status')}
                            style={{ 
                                color: 'white',
                                fontWeight: 'bold'
                             }}
                        >
                            Status
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {paginatedStories.map((story) => (
                    <TableRow 
                        key={story.user_story_id} 
                        onClick={() => {
                            setSelectedRow(story.user_story_id); // Set the selected row when a row is clicked
                            setSelectedStoryForEdit(story); // Set the selected story for edit
                            setOpen(true); // Open the ViewStory dialog
                        }}
                        className={`unselectable ${story.user_story_id === selectedRow ? 'selectedRow' : ''}`}
                    >
                    <TableCell>{story.title}</TableCell>
                    <TableCell>{projectContext?.EPICS.find(epic => epic.epic_id === story.epic_id)?.name}</TableCell>
                    <TableCell>{projectContext?.SPRINTS.find(sprint => sprint.sprint_id === story.sprint_id)?.name || ''}</TableCell>
                   <TableCell>
                        {projectContext?.SPRINTS
                            ?.find(sprint => sprint.sprint_id === story.sprint_id)?.start_date 
                                ? new Date(projectContext?.SPRINTS.find(sprint => sprint.sprint_id === story.sprint_id)?.start_date).toLocaleDateString() 
                                : ''}
                    </TableCell>
                    <TableCell>
                        {projectContext?.SPRINTS
                            ?.find(sprint => sprint.sprint_id === story.sprint_id)?.end_date 
                                ? new Date(projectContext?.SPRINTS?.find(sprint => sprint.sprint_id === story.sprint_id)?.end_date).toLocaleDateString() 
                                : ''}
                    </TableCell>
                    <TableCell>{statusDisplayNames[story.status]}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
        </Table>
      </TableContainer>
      <Box display='flex' alignItems='flex-start' justifyContent='space-between'>
        <Pagination count={Math.ceil(filteredStories.length / rowsPerPage)} page={page} onChange={handleChangePage} sx={{ margin: '10px 0' }} />
        <Typography sx={{ margin: '15px 10px 0 0' }}>Showing {start}-{end} of {total}</Typography>
      </Box>
      
      <ViewStory 
        open={open} 
        handleClose={handleClose} 
        selectedStoryForEdit={selectedStoryForEdit}
        projectMembers={projectMembers}
        projectContext={projectContext}
      />
    </div>
  );
}