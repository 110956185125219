import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Select, MenuItem, Typography } from '@mui/material';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import DocumentsClass from '../../../classes/DocumentsClass';
import DataService from '../../../classes/DataService';

export default function NewDocument({ 
    open, 
    handleClose, 
    newTitle, 
    setNewTitle, 
    documentType, 
    setDocumentType, 
    project, 
    Auth0_user,
    setProjectDocuments,
    setSelectedDocument,
    setAlert,
    userPlan,
    projectContext,
    setProjectContext
}) {
    const documentsClass = new DocumentsClass();
    const [creationIsLoading, setCreationIsLoading] = useState(false);

    const handleDocumentTypeChange = (e) => {
        const selectedType = e.target.value;
        setDocumentType(selectedType);
        if (selectedType !== 'Custom Document') {
            setNewTitle(selectedType);
        } else {
            setNewTitle('');
        }
    };

    const handleSubmit = async (event) => {
        console.log("Project Context: ", projectContext);
        setAlert({ id: Date.now(), severity: "info", message: "Generating " + documentType + ". This may take a moment.", autoHideDuration: null });
        handleClose();
        let projectDocument;
        let newDocument;
        try {
            if (documentType === 'Custom Document') {
                projectDocument = {
                    title: newTitle,
                    body: "<h2>" + newTitle + "</h2><p>Edit this document to add content.</p>",
                }
                newDocument = await documentsClass.createDocument(projectDocument, project.project_id, Auth0_user.sub);
            } else {
                newDocument = await documentsClass.generateDocument(project, documentType, Auth0_user, projectContext);
                projectDocument = {
                    title: newTitle,
                    body: newDocument.body,
                }
            }
            // Decode the newDocument here
            let decodedDocument = { ...newDocument };
            if (typeof newDocument.body === 'string') {
                decodedDocument.body = atob(newDocument.body);
            }
            // Publish the document if userPlan is "Free" of "Basic"
            if (userPlan === "Free" || userPlan === "Basic") {
                await DataService.updateDocument(decodedDocument.document_id, { status: 'published' });
                decodedDocument.status = 'published'; // Update the status in the local state
            }
            setProjectDocuments(prevDocuments => [...prevDocuments, decodedDocument]);
            setProjectContext(prevContext => ({
                ...prevContext,
                DOCUMENTS: [...prevContext.DOCUMENTS, decodedDocument]
            }));
            setSelectedDocument(decodedDocument);
            setAlert({ id: Date.now(), severity: "success", message: documentType + " has been generated successfully.", autoHideDuration: 6000 });
        } catch (error) {
            setAlert({ id: Date.now(), severity: "error", message: "Document generation failed.", autoHideDuration: 6000 });
        } finally {
            handleClose();
        }
    };

    return (
        <>
            <Dialog 
                open={open} 
                onClose={handleClose} 
                fullWidth 
                minWidth='md'
            >
                <DialogTitle>Create New Document</DialogTitle>
                <DialogContent>
                    <Typography variant='body1' sx={{ marginBottom: '40px' }}>
                        Create a custom document, or choose a document with the <AutoAwesome /> label to select an AI generated document.
                    </Typography>
                    <Select
                        value={documentType}
                        onChange={handleDocumentTypeChange}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="Select Document Type..." disabled><em>Select Document Type...</em></MenuItem>
                        <MenuItem value="Custom Document">Custom Document</MenuItem>
                        <MenuItem value="Project Charter"><AutoAwesome sx={{ marginRight: '10px' }}/>Project Charter</MenuItem>
                        <MenuItem value="Budget Estimate and Financial Analysis"><AutoAwesome sx={{ marginRight: '10px' }}/>Budget Estimate and Financial Analysis</MenuItem>
                        <MenuItem value="Resource Plan"><AutoAwesome sx={{ marginRight: '10px' }}/>Resource Plan</MenuItem>
                        <MenuItem value="Risk Assessment"><AutoAwesome sx={{ marginRight: '10px' }}/>Risk Assessment</MenuItem>
                        <MenuItem value="Agile Methodology Framework"><AutoAwesome sx={{ marginRight: '10px' }}/>Agile Methodology Framework</MenuItem>
                        <MenuItem value="Market Analysis"><AutoAwesome sx={{ marginRight: '10px' }}/>Market Analysis</MenuItem>
                        <MenuItem value="Stakeholder Analysis"><AutoAwesome sx={{ marginRight: '10px' }}/>Stakeholder Analysis</MenuItem>
                        <MenuItem value="Business Case"><AutoAwesome sx={{ marginRight: '10px' }}/>Business Case</MenuItem>
                        <MenuItem value="Project Roadmap"><AutoAwesome sx={{ marginRight: '10px' }}/>Project Roadmap</MenuItem>
                        <MenuItem value="Benefits Realization Plan"><AutoAwesome sx={{ marginRight: '10px' }}/>Benefits Realization Plan</MenuItem>
                    </Select>
                    <TextField
                        margin="dense"
                        label="Title"
                        type="text"
                        fullWidth
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        disabled={documentType !== 'Custom Document'}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!documentType || documentType === "Select Document Type..."}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}