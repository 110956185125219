export default class CreateStakeholderAnalysisPrompt {
    constructor(projectContext) {
        this.projectContext = projectContext;
    }

    build() {

        return `
        You are an expert project manager.

        **Your Task:**
        - Create a complete Stakeholder Analysis document using the Project Details to guide your response.
        - Use the STORIES and DOCUMENTS from the **Project Details** (if any are provided) to help you flesh out each section with as much useful detail as possible for the **Required Sections**
        - Use your best judgement to generate realistic data if not enough information is present
        - Use your best judgement to determine what the project is and its significance based on the **Project Details** and research all available project data related to provide detailed information for each section of the **Required Sections**

        **Instructions:**
        - Respond in CommonMark format only.
        - Use the provided information to complete each section in full without requiring additional user input.
        - Do not use placeholders unless information is explicitly missing from the Project Details.
        
        **Accepted Formatting:**
        - **Text Formatting:**
            Bold,
            Italic,
            Underline,
            Strikethrough,
            Blockquote,
            Code Block,
            Header 1,
            Header 2,
            Ordered List,
            Bulleted List,
            Subscript,
            Superscript,
            Indent,
            Outdent,
            Right to Left Text Direction
        - **Other Formatting:**
            Header Levels (1 6, or None),
            Text Color,
            Background Color,
            Font Family,
            Text Alignment (Left, Center, Right, Justify),
            Links

        **Required Sections:**
        - Stakeholder Identification
        - Roles and Responsibilities
        - Level of Influence and Interest
        - Communication Requirements
        - Expectations and Objectives
        - Potential Impact on the Project
        - Alignment with Project Goals
        - Stakeholder Relationships
        - Risk Assessment Related to Stakeholders (**ONLY** use a list format for this section based on **Accepted Formatting**)
        - Engagement Strategy

        **Important Instructions:**
        - **Complete** each section with as much useful detail as possible using the information provided in the **Project Details** (DOCUMENTS, STORIES, EPICS).
        - **DO NOT** generate placeholders and **use** of **your knowledge** and the data included in the **Project Details** to fill in every aspect of the Stakeholder Analysis.
        - **DO NOT** respond without generating the requested Stakeholder Analysis.
        - **DO NOT** include any explanations, instructions, or formatting not listed in the instructions.
        - **DO NOT** include the ID of any data referenced (e.g. **NEVER** include identifiers such as ID, epic_id, sprint_id, user_story_id or document_id from the **Project Details** in the document you generate).
        - **NEVER** format any section in the **Required Sections** using tables or any other format not included in **Accepted Formatting**

        **Project Details:** ${JSON.stringify(this.projectContext)}
        `.replace(/\s+/g, ' ').trim(); // removes all newline characters and extra spaces
    }
}