import EpicDto from './EpicDto.js';
import SprintDto from './SprintDto.js';
import UserStoryDto from './UserStoryDto.js';
import DocumentDto from './DocumentDto.js';

export default class ProjectContextDto {
    constructor() {
        this.ID = '';
        this.PROJECT_NAME = '';
        this.PROJECT_DESCR = '';
        this.EPICS = [];
        this.SPRINTS = [];
        this.STORIES = [];
        this.DOCUMENTS = [];
    }
    
    static fromJSON(json) {
        const context = new ProjectContextDto();
        context.PROJECT_ID = json.ID;
        context.PROJECT_NAME = json.PROJECT_NAME;
        context.PROJECT_DESCRIPTION = json.PROJECT_DESCR;
        context.EPICS = json.EPICS.map(epic => EpicDto.fromJSON(epic));
        context.SPRINTS = json.SPRINTS.map(sprint => SprintDto.fromJSON(sprint));
        context.STORIES = json.STORIES.map(story => UserStoryDto.fromJSON(story));
        context.DOCUMENTS = json.DOCUMENTS.map(document => DocumentDto.fromJSON(document));
        return context;
    }

    static setDocuments(context, documents) {
        if (!(context instanceof ProjectContextDto)) 
            throw new Error("Invalid context: Must be an instance of ProjectContextDto");

        context.DOCUMENTS = documents.map(document => DocumentDto.fromJSON(document));
        return context;
    }
}