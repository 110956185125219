import React, { useState, useEffect } from 'react';
import { 
    TextField, 
    Button, 
    Box, 
    Paper, 
    Typography, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    IconButton, 
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth0 } from '@auth0/auth0-react';
import DataService from '../../../classes/DataService';
import ProjectAlerts from '../ProjectAlerts';

export default function MemberManagement({ project, projectMembers, setProjectMembers }) {
    const { user } = useAuth0();
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const [CheckingEmail, setCheckingEmail] = useState(false);
    const [alert, setAlert] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [memberToRemove, setMemberToRemove] = useState(null);
    const [inviteEmail, setInviteEmail] = useState('');

    useEffect(() => {
        setUserExists(false);
        setEmailValid(isValidEmail(email));
    }, [email]);

    const handleEmailChange = (e) => {
        setInviteEmail(e.target.value);
        setEmail(e.target.value);
        setEmailValid(isValidEmail(e.target.value));
    };

    const isValidEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.test(email);
    }

    const handleInvite = () => {
        if (isValidEmail(email)) {
            const isAlreadyMember = projectMembers.some(member => member.email === email);
            if (isAlreadyMember) {
                // console.log('Email is already a project member:', email);
                setAlert({ message: 'The provided email belongs to an existing project member', severity: 'info', autoHideDuration: 6000 });
            } else {
                checkUserExists(email);
                setAlert({ message: 'Checking if '+ email + ' is a Meteor user', severity: 'info', autoHideDuration: 6000 });
            }
        } else {
            console.log('Invalid email format');
        }
    }

    const handleRemove = async () => {
        try {
            await DataService.deleteProjectMember(project.project_id, memberToRemove.user_id, memberToRemove.email);
            setAlert({ message: 'Member was removed from the project', severity: 'success', autoHideDuration: 6000 });
            // Update the projectMembers state to reflect the removal
            const updatedProjectMembers = projectMembers.filter(member => member.user_id !== memberToRemove.user_id);
            setProjectMembers(updatedProjectMembers);
        } catch (error) {
            console.error('Failed to remove member:', error);
            setAlert({ message: 'Failed to remove member', severity: 'error', autoHideDuration: 6000 });
        }
        handleCloseDialog();
    };
    
    const handleOpenDialog = (member) => {
        setMemberToRemove(member);
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const checkUserExists = async (email) => {
        setCheckingEmail(true);
        try {
            const user = await DataService.getAuth0UserByEmail(email);
            if (Array.isArray(user) && user.length > 0) {
                setUserExists(true);
                // Assuming the user object has a property 'user_id'
                await DataService.addProjectMember(project.project_id, user[0].user_id, email);
                setAlert({ message: 'User was added as a project member', severity: 'success', autoHideDuration: 6000 });
                setInviteEmail('');


                // Update the projectMembers state to reflect the addition
                const updatedProjectMembers = [...projectMembers, user[0]];
                setProjectMembers(updatedProjectMembers);
            } else {
                setAlert({ message: email + ' is not a Meteor user. At this time you can only invite existing users.', severity: 'error', autoHideDuration: 6000 });
                setUserExists(false);
            }
        } catch (error) {
            setUserExists(false);
        }
        setCheckingEmail(false);
    };

    const removeAlert = () => {
        setAlert(null);
    }

    return (
        <Box>
            <Box sx={{ marginBottom: '10px' }}>
                <Typography variant='h6'>Project Members</Typography>
            </Box>
            <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{color: 'white', fontWeight: 'bold',}}>Name</TableCell>
                                <TableCell sx={{color: 'white', fontWeight: 'bold',}}>Email</TableCell>
                                <TableCell sx={{color: 'white', fontWeight: 'bold',}}>Role</TableCell>
                                {user.sub === project.owner_id && (
                                    <TableCell sx={{color: 'white', fontWeight: 'bold',}}>Remove</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectMembers && projectMembers.map(member => (
                                <TableRow key={member.user_id}>
                                    <TableCell>{member.name}</TableCell>
                                    <TableCell>{member.email}</TableCell>
                                    <TableCell>{member.user_id === project.owner_id ? 'Owner' : 'Member'}</TableCell>
                                    {user.sub === project.owner_id && (
                                        <TableCell>
                                            <IconButton onClick={() => handleOpenDialog(member)}>
                                                {member.user_id !== project.owner_id && (
                                                    <DeleteIcon />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {user.sub === project.owner_id && (
                <Box display='flex' alignItems='center' sx={{ marginTop: '10px' }}>
                    <TextField
                        label="Invite New Member Email"
                        variant="outlined"
                        value={inviteEmail}
                        onChange={handleEmailChange}
                        sx={{ width: '30%', marginTop: '10px' }}
                    />
                    <Button 
                        variant='text' 
                        color='primary'
                        sx={{ marginTop: '10px', marginLeft: '10px' }}
                        disabled={!emailValid}
                        onClick={handleInvite}
                    >
                        Invite
                    </Button>
                    {CheckingEmail && (
                        <Box  display='flex' alignItems='center'>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            )}
            <ProjectAlerts alert={alert} removeAlert={removeAlert} />
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>
                    {"Confirm Removal"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Are you sure you want to remove ${memberToRemove?.name} as a project member?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRemove} color="error" autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}