import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Paper, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip, Card, CardActions, CardContent, Box, Avatar, ListItemIcon, TextField, CircularProgress, LinearProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Free from '@mui/icons-material/Sell.js';
import Basic from '@mui/icons-material/Verified.js';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import DataService from '../../classes/DataService';
import AccountController from './Account.controller.js';
import Loading from '../Loading.js';

const Account = ({ userProfileImage, setUserProfileImage, userName, setUserName, stripeSubscription, fullUser }) => {
    let accountController;
    const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const userID = user.sub;
    const [currentName, setCurrentName] = useState(userName);
    const [currentEmail, setCurrentEmail] = useState(user.email);
    const [initialName, setInitialName] = useState(userName);
    const [initialEmail, setInitialEmail] = useState(user.email);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState(false);
    const [userPlan, setUserPlan] = useState('');
    const [monthlyPrice, setMonthlyPrice] = useState(0);
    const [nextBillingDate, setNextBillingDate] = useState('');
    const [redirecting, setRedirecting] = useState(false);
    const [trialExpirationDate, setTrialExpirationDate] = useState('');
    const [subscriptionFrequency, setSubscriptionFrequency] = useState('');
    const navigate = useNavigate();

        useEffect(() => {
        setTrialExpirationDate(fullUser.user_metadata.free_trial_expiration_date);
        accountController = new AccountController(user, setUserProfileImage);
    
        if (stripeSubscription === 'No Stripe subscription found') {
            DataService.getAuth0UserByID(userID).then(userDetails => {
                const isTrialActive = () => {
                    if (userDetails.user_metadata && userDetails.user_metadata.free_trial_expiration_date) {
                        const freeTrialExpiration = new Date(userDetails.user_metadata.free_trial_expiration_date);
                        const currentDate = new Date();
                        return currentDate < freeTrialExpiration;
                    }
                    return false;
                };
                if (isTrialActive()) {
                    setUserPlan('Free Trial');
                }
                setLoading(false);
            }).catch(error => {
                console.error("Failed to fetch user details:", error);
                setLoading(false);
            });
        } else if (stripeSubscription) {
            setSubscriptionFrequency(stripeSubscription.billingFrequency);
            setUserPlan(stripeSubscription.billingFrequency); // Ensure userPlan is set correctly
            setMonthlyPrice(new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(stripeSubscription.subscriptionPrice));
            
            let nextBillingDateObj = new Date(stripeSubscription.nextBillingDate);
            setNextBillingDate(`${nextBillingDateObj.getMonth() + 1}/${nextBillingDateObj.getDate()}/${nextBillingDateObj.getFullYear()}`);
            
            setLoading(false);
        }
    
        // console.log('Full User:', fullUser);
        // console.log('User Plan:', userPlan);
        // console.log('Stripe Subscription:', stripeSubscription);
        // console.log('User ID:', userID);
    }, [stripeSubscription, userID, fullUser]);

    const handleSaveImage = async () => {
        setImageLoading(true);
        if (selectedImage) {
            if(userProfileImage) {
                const url = new URL(userProfileImage);
                const imageName = url.pathname.split('/').pop();
                if(user.picture && user.picture.startsWith('https://imrsmeteor.blob.core.windows.net/profile-images')) {
                    await DataService.deleteUserPicture(imageName);
                }
            }
            const response = await DataService.uploadImage(selectedFile);
            if (response) {
                const pictureUrl = response; // assuming the response contains the URL of the uploaded image
                const userData = {
                    picture: pictureUrl
                };
                await DataService.updateUserData(userID, userData);
                setUserProfileImage(pictureUrl);
            }
            setImageLoading(false);
            handleCloseDialog();
        }
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setSelectedImage(null);
        setSelectedFile(null);
        setOpen(false);
        setImageLoading(false);
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = URL.createObjectURL(e.target.files[0]);
            setSelectedImage(img);
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleNameChange = (event) => {
        if (user.sub.startsWith('auth0')) {
            setCurrentName(event.target.value);
            setUserName(event.target.value);
        }
    };

    const handleEmailChange = (event) => {
        setCurrentEmail(event.target.value);
    };

    const handleSave = async () => {
        setLoading(true);
        if (currentName !== initialName) {
            const userData = {
                name: currentName
            };
            await DataService.updateUserData(userID, userData);
            setInitialName(currentName);
            setUserName(currentName);
        }
        setLoading(false);
    };

    const upgradePlan = () => {
        navigate('/plan-selection');
    };

    const handleManagePlanClick = async () => {
        setRedirecting(true);
        try {
            let customerPortalLink = await DataService.getCustomerPortal(user.email);
            window.location.href = customerPortalLink.url;
        } catch (error) {
            console.error('Error redirecting to customer portal:', error);
        }
    };

    const isSaveDisabled = currentName === initialName && currentEmail === initialEmail;

    return loading ? (
        <Loading />
    ) : (
        <div>
            <Typography variant="h5">Account Settings</Typography>
            <Divider sx={{ margin: '20px 0' }} />
            <Typography variant="h6">User Profile</Typography>
            <div>
                <Box 
                    sx={{ 
                        margin: '2rem 1rem 0 0',
                        position: 'relative', 
                        width: 100, 
                        height: 100, 
                        ...(user.sub.startsWith('auth0') ? { '&:hover img': { filter: 'brightness(50%)' } } : {}),
                        ...(user.sub.startsWith('auth0') ? { '&:hover svg': { opacity: 1 } } : {}),
                    }}
                >
                    <Avatar 
                        src={userProfileImage}
                        key={userProfileImage}
                        sx={{ 
                            width: 100,
                            height: 100,
                            transition: 'filter 0.3s', 
                            filter: 'brightness(100%)',
                            objectFit: 'cover',
                        }}
                    />
                    {user.sub.startsWith('auth0') ? (
                        <CameraAltIcon 
                            sx={{ 
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            transform: 'translate(-50%, -50%)', 
                            opacity: 0, 
                            transition: 'opacity 0.3s',
                            color: 'white',
                            '&:hover': { cursor: 'pointer' }
                            }}
                            onClick={handleOpenDialog}
                        />
                    ) : null}
                </Box>
            </div>
            <Paper elevation={0} sx={{ p: 2, minWidth: '40%', maxWidth: '500px', marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <TextField
                        variant='standard'
                        value={currentName}
                        label="Full Name"
                        onChange={handleNameChange}
                        fullWidth
                        InputProps={{ 
                            style: { cursor: user.sub.startsWith('auth0') ? 'text' : 'default' }
                        }}
                        disabled={!user.sub.startsWith('auth0')}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                    <ListItemIcon>
                        <EmailIcon />
                    </ListItemIcon>
                    <TextField
                        disabled
                        readOnly={true}
                        variant="standard"
                        value={currentEmail}
                        label="Email Address"
                        fullWidth
                        InputProps={{ 
                            style: { cursor: 'pointer' }
                        }}
                    />
                </div>
            </Paper>
            {user.sub.startsWith('auth0') ? (
                <Button 
                    variant="contained" 
                    color="primary" 
                    sx={{ mt: 2 }}
                    disabled={isSaveDisabled}
                    onClick={handleSave}
                >
                    Save
                </Button>
            ) : null }
            <Card variant='outlined' sx={{ backgroundColor: '#f5f5f5', minWidth: '500px', width: '40%', marginTop: '20px' }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='h5' sx={{ fontWeight: 'bold', marginBottom: '10px' }}>Subscription</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#E62824', marginLeft: 1 }}>
                            {userPlan ? userPlan : ''}
                        </Typography>
                    </Box>
                    {userPlan !== 'Free Trial' && (
                        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
                            {monthlyPrice && (
                                <>
                                    <strong>{monthlyPrice}</strong> + applicable taxes
                                </>
                            )}
                        </Typography>
                    )}
                    {userPlan !== 'Free Trial' && nextBillingDate && (
                        <Typography variant='body2'>Next Billing Date: <strong>{nextBillingDate}</strong></Typography>
                    )}
                    {userPlan === 'Free Trial' && trialExpirationDate && (
                        <Typography variant='body2'>
                            Trial Expiration Date: <strong>{new Date(trialExpirationDate).toLocaleDateString('en-US')}</strong>
                        </Typography>
                    )}
                    {stripeSubscription.cancelAtPeriodEnd && (
                        <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" sx={{ marginTop: '10px' }}>
                            <Typography variant='caption' sx={{ fontStyle: 'italic' }}>
                                You have cancelled your subscription.
                            </Typography>
                            <Typography variant='caption' sx={{ fontStyle: 'italic' }}>
                                Your plan will be cancelled on the next billing date.
                            </Typography>
                        </Box>
                    )}
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    {userPlan !== 'Free Trial' ? (
                        <Button size='small' color='primary' disabled={redirecting} onClick={handleManagePlanClick}>Manage Plan</Button>
                    ) : (
                        <Button size='small' color='secondary' onClick={upgradePlan}>Upgrade Plan</Button>
                    )}
                </CardActions>
                {redirecting && <LinearProgress />}
            </Card>
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Change Profile Photo</DialogTitle>
                <DialogContent>
                    {selectedImage && <img src={selectedImage} alt="Selected" style={{ width: '100%', height: 'auto' }} />}
                    <input
                        accept="image/png, image/jpeg, image/webp, image/gif"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        type="file"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" component="span">
                            Select Image
                        </Button>
                    </label>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" disabled={imageLoading}>
                        Cancel
                    </Button>
                    <Button onClick={handleSaveImage} color="primary" disabled={!selectedImage || imageLoading}>
                        Save
                    </Button>
                </DialogActions>
                {imageLoading && (
                    <Box>
                        <CircularProgress
                            size={20}
                            style={{ position: 'absolute', bottom: '20px', left: '20px' }}
                        />
                    </Box>
                )}
            </Dialog>
        </div>
    );
};

export default Account;