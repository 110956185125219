import React from 'react';
import { Box, Avatar, Fab, Tooltip } from '@mui/material';
import TessAvatarImage from '../../../assets/images/Tess-Headshot-Medium.webp';
import AutoAwesome from '@mui/icons-material/AutoAwesome';

export default function AiChatButton({ anchorEl, handleClick, handleClose }) {
    return (
        <Box sx={{ position: 'fixed', right: 20, bottom: 40 }}>
            <Fab 
                aria-label='Talk with Tess' 
                sx={{ 
                    width: '50px', 
                    height: '50px', 
                    backgroundColor: '#707387', 
                    color: 'white', 
                    '&:hover': { 
                        color: 'black' 
                    } 
                }} 
                onClick={handleClick}
            >
                <AutoAwesome />
            </Fab>
        </Box>
    )
}