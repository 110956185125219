// EditEpic.js
import React, { useState } from 'react';
import { Drawer, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, CircularProgress } from '@mui/material';
import DataService from '../../../classes/DataService.js';
import { useParams, Link } from 'react-router-dom';

const EditEpic = ({ 
    open, 
    handleClose, 
    selectedEpicForEdit, 
    setSelectedEpicForEdit, 
    //refreshEpics, 
    setDrawerOpen, 
    epics, 
    setEpics, 
    setSelectedEpic,
    projectContext,
    setProjectContext }) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const [deleting, setDeleting] = useState(false);
    const [saving, setSaving] = useState(false);

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteEpic = () => {
        setDeleting(true);
        DataService.deleteEpic(selectedEpicForEdit.epic_id, id)
            .then(() => {
                // setTimeout(() => {
                //     setDeleting(false);
                //     handleClose();
                //     refreshEpics();
                //     setOpenDeleteDialog(false);
                // }, 3000);

                const updatedEpics = epics.filter(epic => epic.epic_id !== selectedEpicForEdit.epic_id);
                setEpics(updatedEpics);
                setProjectContext(prevContext => ({
                    ...prevContext,
                    EPICS: updatedEpics
                }));

                setDeleting(false);
                handleClose();
                //refreshEpics();
                setOpenDeleteDialog(false);
            })
            .catch(e => {
                console.error("There was a problem deleting the epic: ", e);
            });
    };

    const handleEpicUpdate = (event) => {

        setSaving(true);
        event.preventDefault();
        setErrors({});

        // Check if the name field is empty
        if (!selectedEpicForEdit.name.trim()) {
            // If it is, set an error message and return from the function
            setErrors({ name: 'Name is required' });
            setSaving(false);
            return;
        }

        DataService.updateEpic(selectedEpicForEdit.epic_id, selectedEpicForEdit)
        .then(response => {
            // console.log("Epic updated successfully");
            // Find the index of the epic that was updated
            const index = epics.findIndex(epic => epic.epic_id === selectedEpicForEdit.epic_id);
            // Replace the epic at that index with the updated epic
            const updatedEpics = [...epics];
            updatedEpics[index] = selectedEpicForEdit;
            // Update the state with the new list of epics
            setEpics(updatedEpics);
            setProjectContext(prevContext => ({
                ...prevContext,
                EPICS: updatedEpics
            }));
            setSaving(false);
            setSelectedEpic(selectedEpicForEdit);
            //refreshEpics();
            setDrawerOpen(false);
        })
        .catch(e => {
            console.error("There was a problem updating the epic: ", e);
        });

        // setTimeout(() => {
        //     setSaving(false);
        //     setDrawerOpen(false);
        // }, 3000);
    };

    return (
        <Drawer anchor='right' open={open} onClose={handleClose} ModalProps={{disableRestoreFocus: true}}>
            <div style={{ padding: '20px', width: '500px' }}>
                <h2>Edit Epic</h2>
                <form noValidate autoComplete="off" onSubmit={handleEpicUpdate}>
                    <TextField
                        required
                        error={!!errors.name}
                        helperText={errors.name}
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        value={selectedEpicForEdit ? selectedEpicForEdit.name : ''}
                        onChange={(e) => setSelectedEpicForEdit({ ...selectedEpicForEdit, name: e.target.value })}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        multiline
                        maxRows={10}
                        value={selectedEpicForEdit ? selectedEpicForEdit.description : ''}
                        onChange={(e) => setSelectedEpicForEdit({ ...selectedEpicForEdit, description: e.target.value })}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                        <Box>
                        <Button 
                            onClick={handleClose} 
                            sx={{ 
                                color: "gray", 
                                '&:hover': {
                                    backgroundColor: "rgba(0.2,0.2,0.2,.02)"
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                        </Box>
                        <Button color="error" onClick={handleOpenDeleteDialog}>Delete</Button>
                    </Box>
                </form>
                <CircularProgress style={{ display: saving ? 'block' : 'none', marginTop: '20px', position: 'absolute', right: 25, top: 25, width: '25px', height: '25px' }} />
            </div>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>{"Delete Epic"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this epic?
                    </DialogContentText>
                    <CircularProgress style={{ display: deleting ? 'block' : 'none', position: 'absolute', bottom: 15, left: 15, width: '25px', height: '25px' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button color="error" onClick={handleDeleteEpic}>Delete</Button>
                </DialogActions>
            </Dialog>
        </Drawer>
    );
}

export default EditEpic;