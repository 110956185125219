const backlogStyles = {
    lanesContainer: {
      display: 'flex',
      // overflowX: 'auto',
      width: '100%', // Use 100% to fill the parent container
      height: '100vh',
      marginBottom: '20px',
    },
    lane: {
      width: '300px', // Set a fixed width for all lanes
      margin: '0 10px',
      backgroundColor: '#f5f7fa',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      minHeight: '80%',
      padding: '10px',
      borderRadius: '8px',
    },
    epics: {
      width: '20%',
      minWidth: '225px',
      margin: '0 0 10px 0',
      backgroundColor: '#f5f7fa',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      padding: '10px',
      borderRadius: '8px',
    },
    sprint_backlog: {
      width: '100%',
      margin: '0 0 0 10px',
      backgroundColor: '#f5f7fa',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      padding: '10px',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
    },
    sprint_backlog_active: {
      width: '100%',
      margin: '0 0 0 10px',
      backgroundColor: '#f5f7fa',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      padding: '10px',
      border: '1px solid #74c476',
      borderRadius: '8px',
    },
    product_backlog: {
      width: '100%',
      margin: '10px 0 0 10px',
      backgroundColor: '#f5f7fa',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      padding: '10px',
      borderRadius: '8px',
      marginBottom: '10px'
    },
    storyContainer: {
      position: 'relative',
      boxSizing: 'border-box',
      margin: '0 0 10px 0',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '10px',
      backgroundColor: '#fff',
      borderRadius: '5px',
    },
    editableComment: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
};

const epicColors = [
  {id: 1, displayName: 'Blue', name: 'blue', primary: '#0d6efd', secondary: '#dbe9ff'},
  {id: 2, displayName: 'Green', name: 'green', primary: '#02a102', secondary: '#d4edda'},
  {id: 3, displayName: 'Red', name: 'red', primary: '#dc3545', secondary: '#f8d7da'},
  {id: 4, displayName: 'Yellow', name: 'yellow', primary: '#e0dd04', secondary: '#666501'},
  {id: 5, displayName: 'Purple', name: 'purple', primary: '#6f42c1', secondary: '#eae2f8'},
  {id: 6, displayName: 'Teal', name: 'teal', primary: '#288080', secondary: '#c4f2f2'},
  {id: 7, displayName: 'Pink', name: 'pink', primary: '#d63384', secondary: '#f8d7da'},
  {id: 8, displayName: 'Orange', name: 'orange', primary: '#FFA500', secondary: '#633200'},
  {id: 9, displayName: 'Light Blue', name: 'lightBlue', primary: '#cff4fc', secondary: '#077287'},
  {id: 10, displayName: 'Light Green', name: 'lightGreen', primary: '#d4edda', secondary: '#455248'},
  {id: 11, displayName: 'Light Red', name: 'lightRed', primary: '#f8d7da', secondary: '#706062'},
  {id: 12, displayName: 'Light Yellow', name: 'lightYellow', primary: '#f7f7d2', secondary: '#636353'},
  {id: 13, displayName: 'Light Purple', name: 'lightPurple', primary: '#d2c2f0', secondary: '#4f495c'},
  {id: 14, displayName: 'Light Teal', name: 'lightTeal', primary: '#c5eded', secondary: '#405454'},
  {id: 15, displayName: 'Light Pink', name: 'lightPink', primary: '#f5cbe0', secondary: '#52424a'},
  {id: 16, displayName: 'Light Orange', name: 'lightOrange', primary: '#fcd5b3', secondary: '#54473c'},
];

const statusColors = [
  {status: 'todo', primary: '#EEEEEE', secondary: '#636363'},
  {status: 'in-progress', primary: 'rgba(66, 135, 245, 0.25)', secondary: '#2a4570'},
  {status: 'done', primary: '#cef5d6', secondary: '#46594a'}
]

export default backlogStyles;
export { epicColors };
export { statusColors };