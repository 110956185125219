import React from 'react';
import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

const Loading = () => {
    return (
        <Box sx={{
            width: '100%',
            height: '50vh', // Full viewport height
            display: 'flex',
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'center', // Center vertically
            alignItems: 'center' // Center horizontally
        }}>
            <Typography variant='h6'>Loading...</Typography>
            <LinearProgress style={{width: '20%', color: 'primary'}} />
        </Box>
    );
};

export default Loading;