import React, { useState, useEffect } from 'react';
import { Drawer, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, MenuItem, Avatar, Tooltip, InputAdornment, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DataService from '../../../classes/DataService.js';
import StoryComments from './StoryComments';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, Link } from 'react-router-dom';

const EditStory = ({ 
    open, 
    handleClose, 
    selectedStoryForEdit, 
    setRefreshStories, 
    projectMembers, 
    userPlan,
    projectContext,
    setProjectContext
}) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const [epics, setEpics] = useState([]);
    const [formFields, setFormFields] = useState({});
    const [storyComments, setStoryComments] = useState([]);
    const Auth0_user = useAuth0().user;
    const { id } = useParams();
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };



    const handleDeleteStory = () => {
        setDeleting(true);
        DataService.deleteUserStory(selectedStoryForEdit.user_story_id)
            .then(() => {
                // StoryFunctions.checkAndAssignPriorities(stories, setStories);
                // console.log("Story deleted successfully");

                // setTimeout(() => {
                //     setDeleting(false);
                //     handleClose();
                //     refreshStories();
                //     setOpenDeleteDialog(false);
                // }, 3000);
                
                setProjectContext(prevContext => ({
                    ...prevContext,
                    STORIES: prevContext.STORIES.filter(story => story.user_story_id !== selectedStoryForEdit.user_story_id)
                }));

                //setDeleting(false);
                handleClose();
                
                // Ensure the component re-renders even if refreshStories is already true 
                setRefreshStories(prev => !prev);

                setOpenDeleteDialog(false);
            })
            .catch(e => {
                console.error("There was a problem deleting the story: ", e);
            })
            .finally(() => setDeleting(false));
    };

    const handleStoryUpdate = (event) => {
        setSaving(true);
        event.preventDefault();
        setErrors({});

        // Check if the title field is empty
        if (!formFields.title.trim()) {
            // If it is, set an error message and return from the function
            setErrors({ title: 'Title is required' });
            setSaving(false);
            return;
        }

        DataService.updateUserStory(selectedStoryForEdit.user_story_id, formFields)
            .then(updatedStory  => {
                // setTimeout(() => {
                //     setSaving(false);
                //     handleClose();
                //     refreshStories();
                // }, 3000);

                setProjectContext(prevContext => ({
                    ...prevContext,
                    STORIES: prevContext.STORIES.map(story =>
                        story.user_story_id === updatedStory.user_story_id ? updatedStory : story
                    )
                }));
                //setSaving(false);
                handleClose();

                // Ensure the component re-renders even if refreshStories is already true 
                setRefreshStories(prev => !prev);
                
            })
            .catch(e => {
                console.error("There was a problem updating the story: ", e);
            })
            .finally(() => setSaving(false));
    }

    // useEffect(() => {
    //     if (selectedStoryForEdit && selectedStoryForEdit.project_id) {
    //         DataService.getEpicsByProjectId(selectedStoryForEdit.project_id)
    //             .then(response => {
    //                 // console.log("Epics fetched successfully: ", response);
    //                 setEpics(response);
    //             })
    //             .catch(e => {
    //                 console.error("There was a problem fetching the epics: ", e);
    //             });
    //     }
    // }, [selectedStoryForEdit]);

    useEffect(() => {
        // Update formFields state when selectedStoryForEdit changes       
        setFormFields(selectedStoryForEdit);
    }, [selectedStoryForEdit]);

    return (
        <Drawer anchor='right' open={open} onClose={handleClose} ModalProps={{disableRestoreFocus: true}}>
            <div style={{ padding: '20px', width: '750px' }}>
                <h2>{selectedStoryForEdit ? selectedStoryForEdit.title : ''}</h2>
                <form noValidate autoComplete="off" onSubmit={handleStoryUpdate}>
                    <Box>
                        <TextField
                            required
                            error={!!errors.title}
                            helperText={errors.title}
                            margin="normal"
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            value={formFields ? formFields.title : ''}
                            onChange={(e) => setFormFields({ ...formFields, title: e.target.value })}
                        />
                    </Box>
                    <Box>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        multiline
                        maxRows={10}
                        value={formFields ? formFields.description : ''}
                        onChange={(e) => setFormFields({ ...formFields, description: e.target.value })}
                    />
                    </Box>
                    <Box>
                        <Autocomplete
                            value={formFields ? projectContext?.EPICS?.find(epic => epic.epic_id === formFields.epic_id) : null}
                            onChange={(event, newValue) => {
                                setFormFields({ ...formFields, epic_id: newValue ? newValue.epic_id : null });
                            }}
                            options={projectContext?.EPICS}
                            getOptionLabel={(option) => option ? option.name : ''}
                            isOptionEqualToValue={(option, value) => value ? option.epic_id === value.epic_id : false}
                            filterOptions={(options, params) => {
                                const filtered = options.filter((option) =>
                                    option.name.toLowerCase().includes(params.inputValue.toLowerCase())
                                );
                                return filtered;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Epic"
                                    margin="normal"
                                    fullWidth
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            maxRows={10}
                            label="Acceptance Criteria"
                            value={formFields ? formFields.acceptance_criteria : ''}
                            onChange={(e) => setFormFields({ ...formFields, acceptance_criteria: e.target.value })}
                        />
                    </Box>
                    {userPlan !== 'Free' &&
                        <Box>
                            <TextField
                                select
                                label="Status"
                                margin="normal"
                                fullWidth
                                value={formFields ? formFields.status : ''}
                                onChange={(e) => setFormFields({ ...formFields, status: e.target.value })}
                            >
                                <MenuItem value="todo">TODO</MenuItem>
                                <MenuItem value="in-progress">IN PROGRESS</MenuItem>
                                <MenuItem value="done">DONE</MenuItem>
                            </TextField>
                        </Box>
                    }
                    <Box>
                        <TextField
                            label="Story Points"
                            margin="normal"
                            fullWidth
                            value={formFields ? formFields.story_points : ''}
                            onChange={(e) => setFormFields({ ...formFields, story_points: e.target.value })}
                        />
                    </Box>
                    {(userPlan !== 'Free' && userPlan !== 'Basic') && (
                        <Box>
                            <Autocomplete
                                value={formFields ? projectMembers.find(member => member.user_id === formFields.assigned_user) || null : null}
                                onChange={(event, newValue) => {
                                    setFormFields({ ...formFields, assigned_user: newValue ? newValue.user_id : null });
                                }}
                                options={projectMembers}
                                getOptionLabel={(option) => option ? option.name : ''}
                                isOptionEqualToValue={(option, value) => value ? option.user_id === value : false}
                                filterOptions={(options, params) => {
                                    const filtered = options.filter((option) =>
                                        option.name.toLowerCase().includes(params.inputValue.toLowerCase())
                                    );
                                    return filtered;
                                }}
                                renderOption={(props, option) => (
                                    <Tooltip title={option.email}>
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <Avatar 
                                                src={option.user_metadata && option.user_metadata.picture ? option.user_metadata.picture : option.picture} 
                                                sx={{ width: 20, height: 20, marginRight: '10px' }} 
                                            />
                                            {option.name}
                                        </Box>
                                    </Tooltip>
                                )}
                                renderInput={(params) => {
                                    const assignedUser = formFields ? projectMembers.find(member => member.user_id === formFields.assigned_user) : null;
                                    return (
                                        <TextField
                                            {...params}
                                            label="Assigned User"
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: assignedUser ? (
                                                    <InputAdornment position="start">
                                                        <Avatar 
                                                            src={assignedUser.user_metadata && assignedUser.user_metadata.picture ? assignedUser.user_metadata.picture : assignedUser.picture} 
                                                            sx={{ width: 30, height: 30, marginRight: '10px' }} 
                                                        />
                                                    </InputAdornment>
                                                ) : null,
                                            }}
                                        />
                                    );
                                }}
                            />
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                        <Box>
                        <Button 
                            onClick={(event) => {
                                event.preventDefault();
                                handleClose();
                            }} 
                            sx={{ 
                                color: "gray", 
                                '&:hover': {
                                    backgroundColor: "rgba(0.2,0.2,0.2,.02)"
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                        </Box>
                        <Button color="error" onClick={handleOpenDeleteDialog}>Delete</Button>
                    </Box>
                </form>
                <CircularProgress style={{ display: saving ? 'block' : 'none', marginTop: '20px', width: '25px', height: '25px' }} />
                {userPlan === 'Professional' &&
                    <StoryComments selectedStoryForEdit={selectedStoryForEdit} projectMembers={projectMembers} />
                }
            </div>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>{"Delete Story"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this story?
                    </DialogContentText>
                    <CircularProgress style={{ display: deleting ? 'block' : 'none', position: 'absolute', bottom: 15, left: 15, width: '25px', height: '25px' }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button color="error" onClick={handleDeleteStory}>Delete</Button>
                </DialogActions>
            </Dialog>
        </Drawer>
    );
}

export default EditStory;