import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Box } from '@mui/material';
import '../../styles/NewProject.css';
import ChatBotCommunicator from '../../classes/ChatBotCommunicator.js'; // Import the ChatBotCommunicator class
import CircularProgress from '@mui/material/CircularProgress/index.js';
import DocumentsClass from '../../classes/DocumentsClass.js';
import InputAdornment from '@mui/material/InputAdornment/index.js';
import IconButton from '@mui/material/IconButton/index.js';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome.js';
import '../../styles/ScrollbarStyles.css';
import Tooltip from '@mui/material/Tooltip/index.js';
import ProjectService from '../../services/ProjectService.js'; // Import the ProjectService
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';


const NewProject = ({ onProjectCreated, onClose }) => {
  const [appName, setAppName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading indicator
  const [errorMessage, setErrorMessage] = useState('');
  const Auth0_user = useAuth0().user;
  const documentsClass = new DocumentsClass();
  const projectService = new ProjectService();
  const nodeServiceHost = process.env.REACT_APP_NODE_SERVICE_HOST || 'http://localhost:3002';

  const handleAppNameChange = (event) => {
    setAppName(event.target.value);
  };
  
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleEnhanceAppName = async (appName, setAppName) => {
      const promptPrefix = "I'm starting a new project and would like help coming up with a name for it. Take the name I give you and come up with a more creative name. Respond with the name only. The name is: ";
      await handleSendToChatbot(appName, setAppName, promptPrefix);
  };

  const handleEnhanceDescription = async (description, setDescription) => {
      const promptPrefix = "I'm starting a new project and would like help enhancing its description. Take the description I give you and make it more appealing. Keep the tone of the description professional as it will not be used for marketing. Keep the description to 1 paragraph, no more than 3 sentences. Respond with the description only. The description is: ";
      await handleSendToChatbot(description, setDescription, promptPrefix);
  };

  const handleCreateProject = async () => {
    setLoading(true);
    try {
      // Create a new project in your application
      axios.post(`${nodeServiceHost}/projects`, {
        name: appName,
        description: description,
        account_id: null,
        owner_id: Auth0_user.sub,
        start_date: new Date(),
      })
      .then(response => {
        // console.log(response);
        onProjectCreated();
        onClose();

        // Create a new document for the project
        const projectDocument = {
          title: appName,
        };
        documentsClass.createHomePage(projectDocument, response.data.project_id, Auth0_user.sub)
          .then(docResponse => {
            // console.log('Document created:', docResponse);
          })
          .catch(docError => {
            console.error('Error creating document:', docError);
          });
      })
      .catch(error => {
        console.error('Error creating project: ', error);
      });
    } catch (error) {
      console.error('Error creating project:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleSendToChatbot = async (data, setData, promptPrefix) => {
    setLoading(true); // Set loading to true when sending message
    setErrorMessage(''); // Clear the error message
    try {
      // Create an instance of ChatBotCommunicator
      const chatBotCommunicator = new ChatBotCommunicator('AIzaSyBpFVWg1k_4g0uK4ULzOvifgsHG-URkQG0');
      const response = await chatBotCommunicator.sendMessage(promptPrefix + data);
      // Check the status of the response
      if (response.status !== 200) {
        // If the status is not 200, set the error message
        setErrorMessage(response.text);
      } else {
        // If the status is 200, update the data
        const newText = response.text || ''; // Assuming response has a 'text' property
        setData(newText);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false); // Set loading to false after API response
    }
  }; 

  return (
    <Grid>
      <Grid item xs={6} className='custom-scrollbar'>
        <div className='NewProject custom-scrollbar'>
          <Typography variant="h5" gutterBottom data-testid="new-project-title">
            New Project 
          </Typography>
          <TextField
            label="Project Name"
            variant="outlined"
            fullWidth
            value={appName}
            onChange={handleAppNameChange}
            margin="normal"
            InputProps={{
              endAdornment: (
                <Tooltip title="Have Tess help you" arrow={true} placement='right'>
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleEnhanceAppName(appName, setAppName)}
                      disabled={!appName || loading}
                    >
                      <AutoAwesomeIcon />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            maxRows={10}
            value={description}
            onChange={handleDescriptionChange}
            margin="normal"
            InputProps={{
              inputProps: { className: 'custom-scrollbar' },
              endAdornment: (
                <Tooltip title="Have Tess help you" arrow={true} placement='right'>
                  <InputAdornment position="end" style={{ alignSelf: 'flex-start', paddingTop: '5px' }}>
                    <IconButton
                      onClick={() => handleEnhanceDescription(description, setDescription)}
                      disabled={!description || loading}
                    >
                      <AutoAwesomeIcon />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          <br />
          <Button
            sx={{ marginTop: 2 }}
            variant="contained"
            color="primary"
            onClick={handleCreateProject}
            disabled={!appName || !description || loading}
          >
            Create Project
          </Button>
          {loading && (
            <Box>
              <CircularProgress
                size={20}
                style={{ position: 'absolute', bottom: '20px', marginLeft: '170px' }}
              />
            </Box>
          )}         
        </div>
        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
        </div>        
      </Grid>
    </Grid>
  );
}

export default NewProject;
