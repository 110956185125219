import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, ListItem, ListItemText, TextField, InputAdornment, IconButton, Pagination, ListItemAvatar, Avatar } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Link } from 'react-router-dom';
import NewProject from './NewProject.js';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useAuth0 } from '@auth0/auth0-react';
import DataService from '../../classes/DataService';
import Loading from '../Loading.js';
import ProjectPlanDowngrade from './ProjectPlanDowngrade.js';

const Projects = ({ stripeSubscription }) => {
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [projectList, setProjectList] = useState([]);
  const itemsPerPage = 10;
  const Auth0_user = useAuth0().user;
  const nodeServiceHost = process.env.REACT_APP_NODE_SERVICE_HOST || 'http://localhost:3002';
  const [loading, setLoading] = useState(true);
  const [downgrade, setDowngrade] = useState(false);
  const [disableNewProjects, setDisableNewProjects] = useState(false);

  const fetchProjects = async () => {
    try {
      const ownedProjectsResponse = await axios.get(`${nodeServiceHost}/projects`);
      const ownedProjects = ownedProjectsResponse.data
        .filter(project => project.owner_id === Auth0_user.sub && project.is_active);

      let finalProjectList = [...ownedProjects];

      const collabProjectsIds = await DataService.getCollabProjects(Auth0_user.sub);
      const collabProjectsPromises = collabProjectsIds.map(projectId => axios.get(`${nodeServiceHost}/projects/${projectId}`));
      const collabProjectsResponses = await Promise.all(collabProjectsPromises);
      const collabProjects = collabProjectsResponses
        .map(response => response.data)
        .filter(project => project.is_active);

      finalProjectList = [...finalProjectList, ...collabProjects];

      setProjectList(finalProjectList);
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [stripeSubscription]);

  const handleProjectCreated = async () => {
    await fetchProjects();
  };

  const handleDeleteConfirm = () => {
    axios.delete(`${nodeServiceHost}/projects/${projectToDelete.project_id}`)
      .then(response => {
        handleProjectCreated(); // Refresh the project list
      })
      .catch(error => {
        console.error('Error deleting project: ', error);
      });

    setDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredProjects = projectList.filter(project => 
    project.name.toLowerCase().includes(searchTerm.toLowerCase()) && project.is_active === true
  );

  const paginatedProjects = filteredProjects.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return loading ? (
    <Loading />
  ) : downgrade ? (
    <ProjectPlanDowngrade />
  ) : (
    <div className='project-component'>
      <Typography variant="h5" align="left" sx={{ marginBottom: '50px' }}>Projects</Typography>
      <Button variant="contained" color="primary" disabled={disableNewProjects} onClick={handleClickOpen} style={{float: 'right'}}>
        Create Project
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <NewProject onProjectCreated={handleProjectCreated} onClose={handleClose} />
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {projectToDelete && `Are you sure you want to delete the ${projectToDelete.name} project?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        sx={{ marginTop: '20px', marginBottom: '20px'}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchTerm('')}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          ),
        }}
      />
      <Box>
        {paginatedProjects.map((project) => (
          <ListItem key={project.project_id}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box display="flex" alignItems="center">
                <Link 
                  to={{
                    pathname: `/projects/${project.project_id}`,
                    state: { project: project }
                  }} 
                  style={{ textDecoration: 'none' }}
                >
                  <ListItemText
                    primary={<Typography color="primary">{project.name}</Typography>}
                  />
                </Link>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" style={{ marginRight: '10px', fontStyle: 'italic' }}>
                  {project.owner_id === Auth0_user.sub ? 'Owner' : 'Member'}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: '20px' }}>
        <Pagination count={Math.ceil(filteredProjects.length / itemsPerPage)} page={page} onChange={handlePageChange} />
        <Typography variant="subtitle1">
          {`Showing ${((page - 1) * itemsPerPage) + 1}-${Math.min(page * itemsPerPage, filteredProjects.length)} of ${filteredProjects.length}`}
        </Typography>
      </Box>
    </div>
  );
};

export default Projects;