import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Tooltip, IconButton, List, Card, CardContent, Menu, MenuItem, Box, Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EditIcon from '@mui/icons-material/Edit';
import styles from '../../../classes/BacklogStyles.js';
import { epicColors } from '../../../classes/BacklogStyles.js';
import NewEpic from './NewEpic.js';
import DataService from '../../../classes/DataService.js';
import EditEpic from './EditEpic.js';
import AiEpics from './AiEpics.js';

const BacklogEpics = ({ 
    epics, 
    setEpics, 
    selectedEpic, 
    setSelectedEpic,
    projectContext,
    setProjectContext
}) => {
    const [showEditButtons, setShowEditButtons] = useState([]);
    const [highlightedEpic, setHighlightedEpic] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedEpicColor, setSelectedEpicColor] = useState(null);
    const [selectedEpicForEdit, setSelectedEpicForEdit] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const [openNewEpicDialog, setOpenNewEpicDialog] = useState(false);
    const { id } = useParams();
    const [projectDetails, setProjectDetails] = useState(null);
    const [aiEpicsOpen, setAiEpicsOpen] = useState(false);

    useEffect(() => {
        DataService.getProjectDetails(id)
        .then(data => {
            if (data) {
                setProjectDetails(data);
            }
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    }, [id]);

    useEffect(() => {
        setShowEditButtons(Array(epics.length).fill(false));
    }, [epics]);

    // todo: removed redundant with project context
    // useEffect(() => {
    //     DataService.getEpicsByProjectId(id)
    //     .then(data => {
    //         if (data) {
    //             setEpics(data);
    //             setShowEditButtons(Array(data.length).fill(false));
    //         }
    //     })
    //     .catch(error => {
    //         console.error('There has been a problem with your fetch operation:', error);
    //     });
    // }, [id]);

    // useEffect(() => {
    //     console.log('Selected Epic has updated:', selectedEpic);
    // }, [selectedEpic]);

    const handleAiEpicsClose = () => {
        setAiEpicsOpen(false);
    };

    // todo: removed, redundant since we're managing the full state in project context
    // const refreshEpics = () => {
    //     DataService.getEpicsByProjectId(id)
    //         .then(data => {
    //             if (data) {
    //                 setEpics(data);
    //                 setProjectContext(prevContext => ({
    //                     ...prevContext,
    //                     EPICS: data
    //                 }));
    //                 setShowEditButtons(Array(data.length).fill(false));
    //             }
    //         })
    //         .catch(error => {
    //             console.error('There has been a problem with your fetch operation:', error);
    //         });
    // };

    const handleEpicClick = (epic) => {
        if (selectedEpic && selectedEpic.epic_id === epic.epic_id) {
            setSelectedEpic(null);
            setHighlightedEpic(null);
        } else {
            setSelectedEpic(epic);
            setHighlightedEpic(epic);
        }
    };

    const handleMouseEnter = (index) => {
        setShowEditButtons(prevState => prevState.map((val, idx) => idx === index ? true : val));
    };
    
    const handleMouseLeave = (index) => {
        setShowEditButtons(prevState => prevState.map((val, idx) => idx === index ? false : val));
    };

    const handleColorClick = (event, epic) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedEpicColor(epic);
    };
    
        const handleColorClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = (event, epic) => {
        event.stopPropagation();
        setSelectedEpicForEdit(epic);
        setDrawerOpen(true);
    };

    const handleColorSelect = (color) => {
        setSelectedColor(color);
        setAnchorEl(null);
        if (selectedEpicColor) {
            // Update the color of the selected epic
            const updatedEpic = { ...selectedEpicColor, color_id: color.id };
            // Update the projectEpics state
            const updatedEpics = epics.map(epic => epic.epic_id === updatedEpic.epic_id ? updatedEpic : epic);
            
            // Update the epic in the database
            DataService.updateEpic(updatedEpic.epic_id, updatedEpic)
            .then(response => {
                // console.log("Epic color updated successfully");
                setEpics(updatedEpics);
                setProjectContext(prevContext => ({
                    ...prevContext,
                    EPICS: updatedEpics
                }));
            })
            .catch(e => {
                console.error("There was a problem updating the epic color: ", e);
            });
        }
    };

    return (
        <div style={styles.epics}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '.9rem' }}>
                    Epics
                </Typography>
                <Tooltip title="Generate Epics">
                    <IconButton onClick={() => setAiEpicsOpen(true)}>
                        <AutoAwesomeIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div>
                <List>
                    {epics.map((epic, index) => {
                        const epicColor = epicColors.find(color => color.id === epic.color_id);
                        const showEditButton = showEditButtons[index];
                        return (
                            <React.Fragment key={epic.epic_id}>
                                <Droppable droppableId={epic.epic_id} index={index} type="STORY">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{ userSelect: 'none' }}
                                        >
                                        <Card
                                            key={epic.epic_id}
                                            style={{
                                                margin: '10px 0',
                                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)',
                                                backgroundColor: snapshot.isDraggingOver ? '#ddd' : (highlightedEpic && highlightedEpic.epic_id === epic.epic_id ? '#ddd' : '#fff'),
                                            }}
                                            onClick={() => handleEpicClick(epic)}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={() => handleMouseLeave(index)}
                                        >
                                            <CardContent style={{ display: 'flex', alignItems: 'flex-start', padding: '10px', userSelect: 'none' }}>
                                                <Tooltip title="Change Color">
                                                    <IconButton
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundColor: epicColor ? epicColor.primary : '#000',
                                                            marginRight: '5px',
                                                            marginTop: '2px',
                                                            borderRadius: '25px',
                                                        }}
                                                        onClick={(event) => handleColorClick(event, epic)}
                                                    />
                                                </Tooltip>
                                                <Typography variant="body1" style={{ margin: '0', padding: '0' }}>{epic.name}</Typography>
                                                {showEditButton && (
                                                    <IconButton
                                                        style={{
                                                            marginLeft: 'auto',
                                                            height: '20px',
                                                            width: '20px',
                                                            backgroundColor: 'transparent'
                                                        }}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleEditClick(event, epic);
                                                        }}
                                                    >
                                                        <EditIcon sx={{ width: '20px', height: '20px' }} />
                                                    </IconButton>
                                                )}
                                            </CardContent>
                                            <div style={{ height: 0, width: 0 }}>{provided.placeholder}</div>
                                        </Card>
                                        </div> 
                                    )}
                                </Droppable>
                                
                                <Menu
                                    id="color-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleColorClose}
                                >
                                    <div style={{ display: 'flex', flexWrap: 'wrap', width: '220px' }}>
                                        {epicColors.map((color, index) => (
                                            <div style={{ width: '53px' }}>
                                                <MenuItem onClick={() => handleColorSelect(color)} title={color.displayName}>
                                                    <Box
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundColor: color.primary,
                                                            borderRadius: '50%',
                                                            boxShadow: selectedEpicColor && selectedEpicColor.color_id === color.id ? '0 0 5px 2px rgba(0, 0, 0, .4)' : 'none',
                                                        }}
                                                    />
                                                </MenuItem>
                                            </div>
                                        ))}
                                    </div>
                                </Menu>
                            </React.Fragment>
                        );
                    })}
                </List>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
                <Button
                    variant="text"
                    fullWidth
                    sx={{
                        marginTop: '10px',
                        justifyContent: 'flex-start',
                        color: '#525252',
                        marginTop: 'auto',
                        marginTop: 'auto',
                        '&:hover': {
                            backgroundColor: 'rgba(110,110,110,.1)',
                        }
                    }}
                    onClick={() => setOpenNewEpicDialog(true)}
                >
                    Create Epic
                </Button>
            </div>
            <NewEpic 
                open={openNewEpicDialog} 
                handleClose={() => setOpenNewEpicDialog(false)}
                //refreshEpics={refreshEpics}
                setProjectContext={setProjectContext}
            />
            <EditEpic 
                open={drawerOpen} 
                handleClose={() => setDrawerOpen(false)}
                selectedEpicForEdit={selectedEpicForEdit}
                setSelectedEpicForEdit={setSelectedEpicForEdit}
                //refreshEpics={refreshEpics}
                setDrawerOpen={setDrawerOpen}
                epics={epics}
                setEpics={setEpics}
                setSelectedEpic={setSelectedEpic}
                projectContext={projectContext}
                setProjectContext={setProjectContext}
            />
            <AiEpics
                projectName={projectDetails ? projectDetails.name : ''}
                projectDescription={projectDetails ? projectDetails.description : ''}
                selectedEpic={selectedEpic}
                open={aiEpicsOpen}
                onClose={handleAiEpicsClose}
                //refreshEpics={refreshEpics}
                currentEpics={epics}
                projectContext={projectContext}
                setProjectContext={setProjectContext}
            />
        </div>
    );
}

export default BacklogEpics;