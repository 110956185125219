import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import App from './App';
import { SubscriptionContext } from './contexts/SubscriptionContext'; // Import the context
import ErrorBoundary from './Components/ErrorBoundary.js';
import Logger from './classes/Logger';

const SESSION_EXPIRATION_KEY = 'session_expiration';

function Root() {
  // Initialize the subscription state
  const [subscription, setSubscription] = React.useState('professional');
  
  const logger = new Logger();

  // Log the environment variables for verification
  logger.info('Auth0 Details');
  logger.info(`Auth0 Domain: ${process.env.REACT_APP_AUTH0_DOMAIN}`);
  logger.info(`Auth0 Client: ${process.env.REACT_APP_AUTH0_CLIENT_ID}`);
  logger.info(`Auth0 Audience: ${process.env.REACT_APP_AUTH0_AUDIENCE}`);
  logger.info(`Auth0 Scope: ${process.env.REACT_APP_AUTH0_SCOPE}`);
  logger.info(`Auth0 Redirect: ${process.env.REACT_APP_AUTH0_REDIRECT_URI}`);

  const onError = (error) => {
    logger.error('Auth0 Error:', error);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onError={onError}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      }}
    >
      <ErrorBoundary>
        <Router>
          <SubscriptionContext.Provider value={{ subscription, setSubscription }}>
            <App />
          </SubscriptionContext.Provider>
        </Router>
      </ErrorBoundary>
    </Auth0Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);