import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TalkToTess from './TalkToTess.js';
import NewProject from './Projects/NewProject.js';
import Projects from './Projects/Projects.js';
import Account from './Account/Account.js';
import Settings  from './Settings.js';
import Home from './Home.js';
import ProjectDetails from './Projects/ProjectDetails.js';
import PlanSelection from './Registration/PlanSelection.js';

const AppRouter = ({ userProfileImage, setUserProfileImage, userName, setUserName, stripeSubscription, fullUser }) => {
  return (
      <Routes>
        <Route path="/" element={<Home userName={userName} />} />
        <Route path="/talk-to-tess" element={<TalkToTess />} />
        <Route path="/new-project" element={<NewProject />} />
        <Route path="/plan-selection" element={<PlanSelection />} />
        <Route path="/projects" element={<Projects stripeSubscription={stripeSubscription} />} />
        <Route path="/projects/:id" element={<ProjectDetails stripeSubscription={stripeSubscription} />} />
        <Route path="/account" element={<Account userProfileImage={userProfileImage} setUserProfileImage={setUserProfileImage} userName={userName} setUserName={setUserName} stripeSubscription={stripeSubscription} fullUser={fullUser} />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
  );
};

export default AppRouter;