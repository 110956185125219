import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, Box, Select, MenuItem, FormControl, Card, CardContent } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import DataService from '../../classes/DataService';
import Loading from '../Loading.js';

const ProjectPlanDowngrade = () => {
  const [activeProjects, setActiveProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [loading, setLoading] = useState(false);
  const Auth0_user = useAuth0().user;
  const nodeServiceHost = process.env.REACT_APP_NODE_SERVICE_HOST || 'http://localhost:3002';

  useEffect(() => {
    const fetchActiveProjects = async () => {
      try {
        const response = await axios.get(`${nodeServiceHost}/projects`);
        const activeProjects = response.data.filter(project => project.owner_id === Auth0_user.sub && project.is_active);
        setActiveProjects(activeProjects);
      } catch (error) {
        console.error('Error fetching active projects: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveProjects();
  }, [Auth0_user.sub, nodeServiceHost]);

  const handleSelectionChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const inactiveProjects = activeProjects.filter(project => project.project_id !== selectedProject);
      // console.log('Inactive Projects:', inactiveProjects); // Log inactive projects
      await Promise.all(inactiveProjects.map(project => {
        // console.log('Updating project:', project.project_id); // Log the project ID
        return DataService.updateProjectDetails(project.project_id, { is_active: false });
      }));
      window.location.reload();
    } catch (error) {
      console.error('Error updating projects: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {loading ? (
        <Loading />
      ) : (
        <Card variant='outlined' sx={{ width: '100%', maxWidth: 600, marginTop: 4 }}>
          <CardContent>
            <Typography variant="h4" display="flex" flexDirection="column" alignItems="center" justifyContent="center">Project Plan Downgrade</Typography>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Typography variant="h6" sx={{ margin: '20px 0' }}>
                You have more active projects than allowed in your plan.
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>
                Select 1 project to remain active.
              </Typography>
              <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Select
                  labelId="project-select-label"
                  value={selectedProject}
                  onChange={handleSelectionChange}
                  label="Select Project"
                  sx={{ minWidth: 200, width: '50%' }}
                >
                  {activeProjects.map(project => (
                    <MenuItem key={project.project_id} value={project.project_id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Button onClick={handleSubmit} color="primary" disabled={!selectedProject} sx={{ marginTop: '20px' }}>  
                    Submit
                </Button>
            </Box>
            <Typography variant="body2" gutterBottom sx={{ fontStyle: 'italic', textAlign: 'center', marginTop: '20px' }}>
                This action will deactivate all other projects, and cannot be undone, even if you subscribe to a paid plan later.
              </Typography>
          </CardContent>
        </Card>
      )}
      <Box sx={{ margin: '20px 0' }}>
        <Typography variant="h5">
          Or, you can subscribe to a paid plan to retain access all your active projects.
        </Typography>
      </Box>
      <Box>
        <Button variant="contained" href="/plan-selection" sx={{ backgroundColor: '#E62824' }}>
          Upgrade Plan
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectPlanDowngrade;