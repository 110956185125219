// AiMessenger.controller.js
export default class AiMessengerController {
    constructor(setAnchorEl, getAnchorEl) {
        this.setAnchorEl = setAnchorEl;
        this.getAnchorEl = getAnchorEl;
    }

    handleClick = (event) => {
        if (this.getAnchorEl() === event.currentTarget) {
            this.setAnchorEl(null);
        } else {
            this.setAnchorEl(event.currentTarget);
        }
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };
}