import React, { useState, useEffect } from 'react';
import { Avatar, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab, Typography, Box, Button, Switch, FormControlLabel } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert.js';
import BlockIcon from '@mui/icons-material/Block.js';
import DeleteIcon from '@mui/icons-material/Delete.js';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function Settings() {
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [overagesAllowed, setOveragesAllowed] = React.useState(false);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      
      const handleClose = () => {
        setAnchorEl(null);
      };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLogoUpload = (event) => {
        // handle the logo file upload here
    };

    const handleOveragesChange = (event) => {
        setOveragesAllowed(event.target.checked);
    };

    return (
        <div>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
                Settings
            </Typography>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="System Setting" />
                <Tab label="User Management" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <div>Organizational Logo</div>
                    <Button 
                        variant="contained" 
                        component="label"
                        sx={{ ml: 2 }}
                    >
                        Upload File
                        <input 
                            type="file" 
                            hidden 
                            onChange={handleLogoUpload} 
                        />
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <div>Allow AI Generation Overages</div>
                    <FormControlLabel
                        control={
                            <Switch 
                                checked={overagesAllowed} 
                                onChange={handleOveragesChange} 
                                name="overagesAllowed" 
                            />
                        }
                        label=""
                        sx={{ ml: 2 }}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box display="flex" justifyContent="space-between">
                    <Button variant="contained" color="primary">
                    Add a Team Member
                    </Button>
                </Box>
                <TableContainer>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>Last Active</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Replace this with your actual data */}
                        <TableRow>
                        <TableCell>
                            <Box display="flex" alignItems="center">
                            {/* <Avatar src={userAvatar}/> */}
                            <Box ml={2}>
                                <Typography variant="subtitle1">Rich Inman</Typography>
                                <Typography variant="subtitle2">rich.inman@imrsservices.com</Typography>
                            </Box>
                            </Box>
                        </TableCell>
                        <TableCell>Mar 25, 2024 </TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>
                            <IconButton onClick={handleClick} data-testid="more-icon">
                            <MoreVertIcon />
                            </IconButton>
                            <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            >
                            <MenuItem onClick={handleClose}>
                                <BlockIcon sx={{ mr: 1 }} />
                                Disable
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <DeleteIcon sx={{ mr: 1 }} />
                                Delete
                            </MenuItem>
                            </Menu>
                        </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
        </div>
    );
}