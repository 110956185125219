export default class DocumentDto {
    constructor() {
        this.document_id = '';
        this.title = '';
        this.body = '';
        this.created_by = '';
        this.created_on = '';
        this.updated_on = '';
        this.status = '';
        this.is_home = false;
        this.project_id = '';
    }

    static fromJSON(json) {
        const document = new DocumentDto();
        document.document_id = json.document_id;
        document.title = json.title;
        document.body = DocumentDto.decodeBody(json.body);
        document.created_by = json.created_by;
        document.created_on = json.created_on;
        document.updated_on = json.updated_on;
        document.status = json.status;
        document.is_home = json.is_home;
        document.project_id = json.project_id;
        return document;
    }

    static decodeBody(body) {
        try {
            // Try to decode the body from base64
            return atob(body);  
        } catch {
            // If it's not base64, return the original body
            return body;  
        }
    }
}