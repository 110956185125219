import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Typography, Avatar, Chip, Button, Tooltip, IconButton } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DataService from '../../../classes/DataService';
import StoryFunctions from '../../../classes/StoryFunctions';
import AccountCircle from '@mui/icons-material/AccountCircle';
import styles from '../../../classes/BacklogStyles.js';
import NewSprint from '../Sprints/NewSprint.js';
import EndSprint from '../Sprints/EndSprint.js';
import backlogStyles, { epicColors, statusColors } from '../../../classes/BacklogStyles.js';
import Story from './Story.js';
import NewStory from './NewStory.js';
import EditStory from './EditStory.js';
import AiStories from './AiStories.js';
import { use } from 'i18next';
import ProjectContextDto from '../../../dtos/ProjectContextDto.js';

const BacklogStories = ({ 
    epics, 
    setEpics, 
    stories, 
    setStories, 
    fetchStories, 
    selectedEpic, 
    projectMembers, 
    project,
    sprints,
    setSprints,
    userPlan,
    projectContext,
    setProjectContext,
    refreshStories,
    setRefreshStories
}) => {
    const [selectedStory, setSelectedStory] = useState(null);    
    const [sprintBacklogPoints, setSprintBacklogPoints] = useState(0);
    const [backlogPoints, setBacklogPoints] = useState(0);
    const { id: project_id } = useParams();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const maxPriority = Math.max(...stories.map(story => story.priority));
    const [isEditStoryOpen, setIsEditStoryOpen] = useState(false);
    const [selectedStoryForEdit, setSelectedStoryForEdit] = useState(null);
    const [isAiStoriesOpen, setIsAiStoriesOpen] = useState(false);
    const [currentStories, setCurrentStories] = useState(stories);
    const [activeSprint, setActiveSprint] = useState(false);
    const [isStartSprintOpen, setIsStartSprintOpen] = useState(false);
    const [isEndSprintOpen, setIsEndSprintOpen] = useState(false);
    const activeSprintData = sprints.find(sprint => sprint.is_active === true);
    const [ mostRecentEndedSprint, setMostRecentEndedSprint ] = useState(null);

    useEffect(() => {
        calculatePointSums();
    }, [refreshStories, selectedEpic]);

    // Update the activeSprint state whenever sprints changes
    useEffect(() => {
        const recentEndedSprint = sprints
            .filter(sprint => sprint.is_active === false && sprint.end_date)
            .sort((a, b) => new Date(b.end_date) - new Date(a.end_date))[0];
        setMostRecentEndedSprint(recentEndedSprint);
        setActiveSprint(sprints.some(sprint => sprint.is_active));
    }, [sprints]);

    useEffect(() => {
        if (selectedEpic) {
            setCurrentStories(projectContext.STORIES.filter(story => story.epic_id === selectedEpic.epic_id));
        } else {
            setCurrentStories(projectContext.STORIES);
        }
    }, [selectedEpic, refreshStories]);

    const handleOpenDialog = () => setIsDialogOpen(true);
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleSprintButton = () => {
        if (activeSprint) {
            setIsEndSprintOpen(true);
        } else {
            setIsStartSprintOpen(true);
        }
    };

    const handleStartSprintClose = () => {
        setIsStartSprintOpen(false);
    };

    const handleEndSprintClose = () => {
        setIsEndSprintOpen(false);
    };

    const getStatusColors = (status) => {
        const statusColor = statusColors.find(color => color.status === status);
        return statusColor ? { primary: statusColor.primary, secondary: statusColor.secondary } : { primary: '#000', secondary: '#000' };
    };

    const calculatePointSums = () => {
        let sprintBacklogSum = 0;
        let backlogSum = 0;

        // Filter the stories by the selected epic
        //const filteredStories = selectedEpic ? stories.filter(story => story.epic_id === selectedEpic.epic_id) : stories;
        const filteredStories = selectedEpic 
            ? projectContext.STORIES.filter(story => story.epic_id === selectedEpic.epic_id) 
            : projectContext.STORIES;

        filteredStories.forEach(task => {
            if (task.story_location === 'sprintBacklog') {
                sprintBacklogSum += task.story_points;
            } else if (task.story_location === 'backlog') {
                backlogSum += task.story_points;
            }
        });

        setSprintBacklogPoints(sprintBacklogSum);
        setBacklogPoints(backlogSum);
    };
  
    return (
            <div style={{ width: "80%" }}>                
                {userPlan !== 'Free' &&
                <div style={activeSprint ? styles.sprint_backlog_active : styles.sprint_backlog}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '.9rem' }}>
                                {activeSprint ? 'Current Sprint' : 'Sprint Backlog'}
                            </Typography>
                            {(activeSprint || mostRecentEndedSprint) && (
                                activeSprint ? (
                                    <Typography variant="caption" component="span" sx={{ marginLeft: '1rem', color: 'gray', fontStyle: 'italic' }}>
                                        <b>Current Sprint</b>: {activeSprintData?.name} - <b>Started on</b>: {activeSprintData?.start_date ? new Date(activeSprintData.start_date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }) : 'N/A'}
                                    </Typography>
                                ) : (
                                    <Typography variant="caption" component="span" sx={{ marginLeft: '1rem', color: 'gray', fontStyle: 'italic' }}>
                                        <b>Last Sprint</b>: {mostRecentEndedSprint?.name} - <b>Ended on</b>: {mostRecentEndedSprint?.end_date ? new Date(mostRecentEndedSprint.end_date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }) : 'N/A'}
                                    </Typography>
                                )
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button
                                variant="text" 
                                sx={{ 
                                    marginTop: '10px', 
                                    justifyContent: 'flex-start', 
                                    color: '#525252', 
                                    marginTop: 'auto',
                                    marginTop: 'auto',
                                            '&:hover': {
                                                backgroundColor: 'rgba(110,110,110,.1)',
                                            }
                                    }}
                                onClick={handleSprintButton}
                            >
                                {activeSprint ? 'End Sprint' : 'Start Sprint'}
                            </Button>
                            <Tooltip title="Total Points">
                                <Box 
                                    component="span" 
                                    display="inline-flex" 
                                    alignItems="center" 
                                    justifyContent="center" 
                                    width={30} 
                                    height={30}
                                    borderRadius="50%" 
                                    bgcolor="grey.200" 
                                    ml={2}
                                    fontSize={12}
                                    sx={{
                                        marginRight: '48px'
                                    }}
                                >
                                    {sprintBacklogPoints}
                                </Box>
                            </Tooltip>
                        </div>
                    </div>
                    {['sprintBacklog'].map((droppableId) => (
                        <Droppable droppableId={droppableId} key={droppableId} type="STORY">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} style={{ minHeight: '50px' }}>
                                    {currentStories
                                        ?.filter(story => story.status !== 'inactive') // Filter out inactive stories
                                        ?.filter(story => {
                                            if (selectedEpic) {
                                                return story.story_location === droppableId && story.epic_id === selectedEpic.epic_id;
                                            } else {
                                                return story.story_location === droppableId;
                                            }
                                        })
                                        .sort((a, b) => a.priority - b.priority)
                                        .map((story, index) => (
                                            <Draggable key={story.user_story_id} draggableId={story.user_story_id} index={story.priority}>
                                                {(provided) => (
                                                    <div 
                                                        ref={provided.innerRef} 
                                                        {...provided.draggableProps} 
                                                        {...provided.dragHandleProps}
                                                        onClick={() => { // Handle click event here
                                                            setSelectedStoryForEdit(story);
                                                            setIsEditStoryOpen(true);
                                                        }}
                                                    >
                                                        <Story 
                                                            story={story} 
                                                            getStatusColors={getStatusColors} 
                                                            //epics={epics} 
                                                            projectMembers={projectMembers} 
                                                            userPlan={userPlan}
                                                            projectContext={projectContext}
                                                            setProjectContext={setProjectContext} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </div>
                }
                <div style={{ 
                  ...styles.product_backlog, 
                  marginTop: userPlan === 'Free' ? '0px' : '10px'
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '.9rem' }}>
                                Product Backlog
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Tooltip title="Total Points">
                                <Box 
                                    component="span" 
                                    display="inline-flex" 
                                    alignItems="center" 
                                    justifyContent="center" 
                                    width={30} 
                                    height={30}
                                    borderRadius="50%" 
                                    bgcolor="#EEEEEE" 
                                    ml={2}
                                    fontSize={12}
                                    sx={{
                                        marginRight: '7px'
                                    }}
                                >
                                    {backlogPoints}
                                </Box>
                            </Tooltip>
                            <Tooltip title="Generate Stories">
                                <IconButton onClick={async () => {
                                        setIsAiStoriesOpen(true);
                                    }}>
                                    <AutoAwesomeIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    {['backlog'].map((droppableId) => (
                        <Droppable droppableId={droppableId} key={droppableId} type="STORY">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} style={{ minHeight: '50px' }}>
                                    {currentStories
                                        .filter(story => {
                                            if (selectedEpic) {
                                                return story.story_location === droppableId && story.epic_id === selectedEpic.epic_id;
                                            } else {
                                                return story.story_location === droppableId;
                                            }
                                        })
                                        .sort((a, b) => a.priority - b.priority)
                                        .map((story, index) => (
                                            <Draggable key={story.user_story_id} draggableId={story.user_story_id} index={story.priority}>
                                                {(provided) => (
                                                    <div 
                                                        ref={provided.innerRef} 
                                                        {...provided.draggableProps} 
                                                        {...provided.dragHandleProps}
                                                        onClick={() => {
                                                            setSelectedStoryForEdit(story);
                                                            setIsEditStoryOpen(true);
                                                        }}
                                                    >
                                                        <Story 
                                                            story={story} 
                                                            getStatusColors={getStatusColors} 
                                                            //epics={epics} 
                                                            setEpics={setEpics} 
                                                            projectMembers={projectMembers} 
                                                            userPlan={userPlan}
                                                            projectContext={projectContext}
                                                            setProjectContext={setProjectContext} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                    <Button
                        variant="text"
                        sx={{
                            marginTop: '10px',
                            justifyContent: 'flex-start',
                            color: '#525252',
                            marginTop: 'auto',
                            marginTop: 'auto',
                            '&:hover': {
                                backgroundColor: 'rgba(110,110,110,.1)',
                            }
                        }}
                        onClick={handleOpenDialog}
                    >
                        Create Story
                    </Button>
                    <NewStory 
                        open={isDialogOpen} 
                        handleClose={handleCloseDialog} 
                        setRefreshStories={setRefreshStories} 
                        projectMembers={projectMembers}
                        selectedEpic={selectedEpic}
                        //epics={epics}
                        userPlan={userPlan}
                        projectContext={projectContext}
                        setProjectContext={setProjectContext}
                    />
                </div>
            <EditStory
                open={isEditStoryOpen}
                handleClose={() => {
                    setIsEditStoryOpen(false);
                    setSelectedStoryForEdit(null); // Clear the selected story when the drawer is closed
                }}
                selectedStoryForEdit={selectedStoryForEdit}
                setRefreshStories={setRefreshStories}
                projectMembers={projectMembers}
                userPlan={userPlan}
                projectContext={projectContext}
                setProjectContext={setProjectContext}
            />
            <AiStories 
                open={isAiStoriesOpen} 
                onClose={() => setIsAiStoriesOpen(false)} 
                projectName={project.name}
                projectDescription={project.description}
                selectedEpic={selectedEpic}
                currentStories={currentStories}
                projectContext={projectContext}
                setProjectContext={setProjectContext}
                setRefreshStories={setRefreshStories}
            />
            <NewSprint 
                project_id={project_id} 
                //stories={projectContext.STORIES} 
                setSprints={setSprints} 
                open={isStartSprintOpen} 
                onClose={handleStartSprintClose} 
                projectContext={projectContext}
                setProjectContext={setProjectContext}
            />
            <EndSprint
                //stories={projectContext.STORIES}
                //sprints={sprints}
                setSprints={setSprints}
                open={isEndSprintOpen}
                onClose={handleEndSprintClose}
                projectContext={projectContext}
                setProjectContext={setProjectContext}
                setRefreshStories={setRefreshStories}
            />
            </div>
    );
};

export default BacklogStories;