import CreateAgileMethodologyFrameworkPrompt from '../prompts/CreateAgileMethodologyFrameworkPrompt';
import CreateBenefitsRealizationPlanPrompt from '../prompts/CreateBenefitsRealizationPlanPrompt';
import CreateBudgetEstimateAndFinancialAnalysisPrompt from '../prompts/CreateBudgetEstimateAndFinancialAnalysisPrompt';
import CreateBusinessCasePrompt from '../prompts/CreateBusinessCasePrompt';
import CreateMarketAnalysisPrompt from '../prompts/CreateMarketAnalysisPrompt';
import CreateProjectCharterPrompt from '../prompts/CreateProjectCharterPrompt';
import CreateProjectRoadmapPrompt from '../prompts/CreateProjectRoadmapPrompt';
import CreateResourcePlanPrompt from '../prompts/CreateResourcePlanPrompt';
import CreateRiskAssessmentPrompt from '../prompts/CreateRiskAssessmentPrompt';
import CreateStakeholderAnalysisPrompt from '../prompts/CreateStakeholderAnalysisPrompt';

class CreateProjectDocument {
    constructor(chatBotCommunicator) {
        this.chatBotCommunicator = chatBotCommunicator;
    }

    async createDocument(documentType, description, projectName, projectContext) {
        switch (documentType) {
            case 'Market Analysis':
                return this.createMarketAnalysis(projectContext);
            case 'Stakeholder Analysis':
                return this.createStakeholderAnalysis(projectContext);
            case 'Business Case':
                return this.createBusinessCase(projectContext);
            case 'Project Charter':
                return this.createProjectCharter(projectContext);
            case 'Budget Estimate and Financial Analysis':
                return this.createBudgetEstimateAndFinancialAnalysis(projectContext);
            case 'Resource Plan':
                return this.createResourcePlan(projectContext);
            case 'Risk Assessment':
                return this.createRiskAssessment(projectContext);
            case 'Agile Methodology Framework':
                return this.createAgileMethodologyFramework(projectContext);
            case 'Project Roadmap':
                return this.createProjectRoadmap(projectContext);
            case 'Benefits Realization Plan':
                return this.createBenefitsRealizationPlan(projectContext);
            default:
                throw new Error(`Unsupported document type: ${documentType}`);
        }
    }

    async createMarketAnalysis(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are an expert business analyst and marketer. Create a market analysis in a formatted document for a new project with the project name "${projectName}" and the project description "${description}". Use only the following formatting options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting not explicitly listed above. Include a title and all the following SECTIONS in the document: * Background * Target Market - Demographics - Psychographics - Discovery Channels - Buyer Personas * Competitive Landscape * Market Trends Incorporate the following DATA_ITEMS where appropriate within each SECTION: * Market Size and Growth * User Behavior and Preferences * Competitor Analysis * Market Trends * Regulatory Environment * Pricing Strategies * Distribution Channels * Customer Feedback on Existing Products * Technological Factors Use your existing knowledge to complete the document in full without any additional input from the user. If you need to ask any questions, use your best judgment to answer them. Flesh out each section with as much useful detail as possible. For data not provided to you, use your best judgment to generate realistic data. Do not use placeholders for the data. { "PROJECT_NAME": "${projectName}", "PROJECT_DESC": "${description}" }`;
            const prompt = new CreateMarketAnalysisPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createStakeholderAnalysis(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are an expert project manager. Given the PROJECT_NAME and PROJECT_DESCRPTION, create a Stakeholder Analysis Document in a formatted document. Use only the following formatting options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above. Include a title and all the following SECTIONS in the document: * Stakeholder Identification * Roles and Responsibilities * Level of Influence and Interest * Communication Requirements * Expectations and Objectives * Potential Impact on the Project * Alignment with Project Goals * Stakeholder Relationships * Risk Assessment Related to Stakeholders * Engagement Strategy For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. If you need to ask any questions, use your best judgment to answer them for me. Flesh out each section into as much useful detail as possible. Again, for data not provided to you, use your best judgment to generate the data for me. Do not use placeholders for the data. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateStakeholderAnalysisPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createBusinessCase(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are a senior manager and expert project manager. Given the PROJECT_NAME and PROJECT_DESCRIPTION, create a Business Case in a formatted document using only the following options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above. Include a title and all the following SECTIONS in the document: * Market Research Data * Technical Specifications and Requirements * Financial Information * Resource Availability * Risk Analysis Data * Regulatory and Compliance Information * Stakeholder Information * Marketing Strategy Details * Post-Launch Plan For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. If you encounter information gaps, clearly indicate in the document that these details need to be gathered in the future. Flesh out each section with as much useful detail as possible, using your best judgment to generate realistic data when necessary. Avoid using placeholders or fictional content. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateBusinessCasePrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createProjectCharter(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. Do not include the HTML tag in your response. You are an expert project manager and business analyst. Given the PROJECT_NAME and PROJECT_DESCRIPTION, create a Project Charter in a formatted document using only the following options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above. Include the following SECTIONS in the document: * Project Title and Description * Business Case and Justification * Project Scope * Goals and Objectives * Key Stakeholders * High-Level Requirements * Constraints and Assumptions * High-Level Timeline * Budget Overview * Project Organization * Risk Management Overview * Change Management Plan * Success Criteria * Authorization For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. If you encounter information gaps, clearly indicate in the document that these details need to be gathered in the future. Flesh out each section with as much useful detail as possible, using your best judgment to generate realistic data when necessary. Avoid using placeholders or fictional content. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateProjectCharterPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createBudgetEstimateAndFinancialAnalysis(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are a finance manager and expert project manager. Given the PROJECT_NAME and PROJECT_DESCRPTION, create a Budget Estimate and Financial Analysis in a formatted document. Please format the output using only the following options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Small, Normal, Large, or Huge Font Size - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above, and do not create tables or grid-like structures. Include a title and the following SECTIONS for the document: * Budget Summary * Personnel Costs * Development Costs * Marketing and Launch Costs * Operational Costs (First Year) * Total Project Budget * Financial Analysis For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. Instead of tables, use the available formatting options to present the financial data clearly and legibly. For resource costs, develop your own list of necessary resources and use industry average annual salaries for them. If you need to ask any questions, use your best judgment to answer them for me, but do not reference material that doesn’t exist. Flesh out each section into as much useful detail as possible. Again, for data not provided to you, use your best judgment to generate the data for me. Do not use placeholders or fictional content for the data. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateBudgetEstimateAndFinancialAnalysisPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createResourcePlan(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are an expert project manager. Given the PROJECT_NAME and PROJECT_DESCRIPTION, create a Resource Plan in a formatted document. Please use only the following formatting options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above. Include a title and all the following SECTIONS in the document: * Executive Summary * Resource Identification * Roles and Responsibilities * Resource Allocation * Resource Schedule * Budget and Cost Management (include industry average annual salaries) * Procurement Plan * Human Resource Management * Risk Management for Resources * Communication Plan for Resource Management * Tools and Techniques for Resource Management * Performance Metrics and Monitoring * Change Management Process * Contingency Plan * Approval and Sign-off For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. If you encounter information gaps, clearly indicate in the document that these details need to be gathered in the future. Flesh out each section with as much useful detail as possible, using your best judgment to generate realistic data and scenarios when necessary. Avoid using placeholders or fictional content. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateResourcePlanPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createRiskAssessment(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are a senior manager and expert project manager and business analyst. Given the PROJECT_NAME and PROJECT_DESCRIPTION, create a Risk Assessment in a formatted document. Please use only the following formatting options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above. Include a title and all the following SECTIONS in the document: * Introduction * Project Overview * Risk Identification * Risk Analysis (including Probability and Impact Assessment) * Risk Prioritization * Risk Mitigation Strategies * Risk Monitoring and Control * Risk Response Planning * Risk Review and Reporting Schedule * Conclusion For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. If you encounter information gaps, clearly indicate in the document that these details need to be gathered in the future. Flesh out each section with as much useful detail as possible, using your best judgment to generate realistic data and scenarios when necessary. Avoid using placeholders or fictional content. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateRiskAssessmentPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createAgileMethodologyFramework(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are an expert project management leader. Given the PROJECT_NAME and PROJECT_DESCRIPTION, create an Agile Methodology Framework document in a formatted document. Please use only the following formatting options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above. Include a title and all the following SECTIONS in the document: * Project Charter * Product Backlog * Sprint Planning * Sprint Backlog * Daily Stand-ups * Sprint Review * Sprint Retrospective * Release Planning * Definition of Done * Burndown Charts * Velocity Tracking * Risk Management * Stakeholder Engagement * Continuous Improvement Plan * Project Closure For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. If you encounter information gaps, clearly indicate in the document that these details need to be gathered in the future. Flesh out each section with as much useful detail as possible, using your best judgment to generate realistic data and scenarios when necessary. Avoid using placeholders or fictional content. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateAgileMethodologyFrameworkPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createProjectRoadmap(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are an expert project manager and product owner. Given the PROJECT_NAME and PROJECT_DESCRIPTION, create a project roadmap in a formatted document. Please use only the following formatting options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above. Include a title and all the following SECTIONS in the document: * Key Activities (with detailed descriptions) * Work Streams (with clear identification of the work involved) * Milestones (with target dates and significance) * Timeline (a visual representation of the project schedule using text formatting to denote time periods and milestones) For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. If you encounter information gaps, clearly indicate in the document that these details need to be gathered in the future. Flesh out each section with as much useful detail as possible, using your best judgment to generate realistic data and timelines when necessary. Avoid using placeholders or fictional content. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateProjectRoadmapPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }

    async createBenefitsRealizationPlan(projectContext) {
        try {
            //const prompt = `Respond in HTML format only. Do not include the HTML tag in your response. You are an expert project manager and business analyst. Given the PROJECT_NAME and PROJECT_DESCRIPTION, create a Benefits Realization Plan in a formatted document. Please use only the following formatting options: * **Text Formatting:** - Bold - Italic - Underline - Strikethrough - Blockquote - Code Block - Header 1 - Header 2 - Ordered List - Bulleted List - Subscript - Superscript - Indent - Outdent - Right-to-Left Text Direction * **Other Formatting:** - Header Levels (1-6, or None) - Text Color - Background Color - Font Family - Text Alignment (Left, Center, Right, Justify) - Links Please avoid using any formatting that is not explicitly listed above. Include a title and all the following SECTIONS in the document: * Executive Summary * Introduction * Project Overview * Benefit Identification * Benefit Measurement and Metrics * Benefit Owners * Implementation Strategy * Monitoring and Reporting * Challenges and Mitigation Strategies * Timeline for Benefit Realization * Roles and Responsibilities * Communication Plan * Review and Update Mechanism * Conclusion For each SECTION, use your existing knowledge to complete the document in full without any additional input from the user. If you encounter information gaps, clearly indicate in the document that these details need to be gathered in the future. Flesh out each section with as much useful detail as possible, using your best judgment to generate realistic data and scenarios when necessary. Avoid using placeholders or fictional content. { "PROJECT_NAME": "${projectName}", "PROJECT_DESCRIPTION": "${description}" }`;
            const prompt = new CreateBenefitsRealizationPlanPrompt(projectContext).build();
            const response = await this.chatBotCommunicator.generateContent(prompt);
            return response;
        } catch (error) {
            if (error.status === 500) {
                return error;
            }
            throw error;
        }
    }
}

export default CreateProjectDocument;