import React, { useEffect, useState } from 'react';
import { Box, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Paper, Typography, CircularProgress } from '@mui/material';
import { Delete, Edit, Print } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../styles/Document.css';
import '../../../styles/RichTextEditorStyles.css';
import DataService from '../../../classes/DataService';

export default function ProjectDocumentView({ 
    setProjectDocuments, 
    selectedDocument, 
    setSelectedDocument, 
    setIsEditing, 
    userPlan,
    projectContext,
    setProjectContext,
    projectDocuments }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await DataService.deleteDocument(selectedDocument.document_id);
            
            const documents = projectDocuments.filter(doc => doc.document_id !== selectedDocument.document_id);
        
            setProjectDocuments(documents);
            setProjectContext(prevContext => ({
                ...prevContext,
                DOCUMENTS: documents
            }));
            setSelectedDocument(null);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        const editor = document.querySelector('.ql-editor');
        const clone = editor.cloneNode(true);
        printWindow.document.write('<html><head><title>Print</title><style>');
        const quillStyles = Array.from(document.querySelectorAll('style'))
            .filter(style => style.innerHTML.includes('.ql-'))
            .map(style => style.innerHTML)
            .join('\n');
        printWindow.document.write(quillStyles);
        printWindow.document.write(`
            .ql-editor blockquote { border-left: 4px solid #ccc; margin-bottom: 5px; margin-top: 5px; padding-left: 16px; }
            .ql-editor pre { background-color: #f0f0f0; border-radius: 3px; white-space: pre-wrap; margin-bottom: 5px; margin-top: 5px; padding: 5px 10px; }
            .ql-editor code { background-color: #f0f0f0; border-radius: 3px; font-size: 85%; padding: 2px 4px; }
            @media print { .ql-editor { overflow: visible !important; } }
        `);
        printWindow.document.write('</style></head><body>');
        printWindow.document.body.appendChild(clone);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <>
            {loading && (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
                    <Typography variant="h6" color="error">{error.message}</Typography>
                </Box>
            )}
            {!loading && !error && selectedDocument && (
                <>
                    <Box display="flex" justifyContent="flex-end" sx={{ margin: '5px 0', maxHeight: '100vh', overflowY: 'auto' }}>
                        <IconButton onClick={handlePrint}>
                            <Print />
                        </IconButton>
                            <IconButton onClick={handleEditClick}>
                                <Edit />
                            </IconButton>
                        {!selectedDocument.is_home && (
                            <IconButton onClick={handleClickOpen}>
                                <Delete />
                            </IconButton>
                        )}
                    </Box>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>{"Are you sure?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Paper variant='outlined' sx={{ backgroundColor: '#ffeded', padding: '10px' }}>
                                    Deleting this document is a permanent operation and cannot be undone.
                                </Paper>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleDelete} color="error" autoFocus>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Box sx={{ paddingLeft: '30px', border: 'none' }}>
                        <ReactQuill
                            value={selectedDocument.body}
                            readOnly={true}
                            theme="snow"
                            modules={{ 
                                toolbar: false,
                                clipboard: {
                                    matchVisual: false
                                }
                            }}
                            style={{ border: 'none' }}
                        />
                    </Box>
                </>
            )}
        </>
    );
}