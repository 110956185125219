import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Autocomplete, Box, Avatar, Tooltip, InputAdornment } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DataService from '../../../classes/DataService';

const NewStory = ({ 
    open, 
    handleClose, 
    setRefreshStories, 
    projectMembers, 
    selectedEpic, 
    //epics, 
    userPlan,
    projectContext,
    setProjectContext
}) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [acceptanceCriteria, setAcceptanceCriteria] = useState('');
    const [storyPoints, setStoryPoints] = useState('');
    const [assignedUser, setAssignedUser] = useState(null);
    const { id: projectId } = useParams();
    // const [epics, setEpics] = useState([]);
    const [epicId, setEpicId] = useState(null);
    const [sprintId, setSprintId] = useState(null);
    const Auth0_user = useAuth0().user;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedEpic) {
            setEpicId(selectedEpic.epic_id);
        } else {
            setEpicId(null);
        }
    }, [open, selectedEpic]);

    const resetFields = () => {
        setTitle('');
        setDescription('');
        setEpicId('');
        setAcceptanceCriteria('');
        setStoryPoints('');
        setAssignedUser('');
    };

    const handleCreate = async (event) => {
        setLoading(true);
        event.preventDefault();

        if (!title.trim()) {
            // Handle error
            return;
        }

        let finalAssignedUser = assignedUser;
        if (assignedUser === '') {
            finalAssignedUser = null;
        }

        let finalStoryPoints = Number.isInteger(Number(storyPoints)) ? Number(storyPoints) : null;
        //console.log('finalStoryPoints:', finalStoryPoints);

        try {
            const storyData = {
                project_id: projectId,
                epic_id: epicId,
                sprint_id: sprintId,
                title: title,
                description: description,
                acceptance_criteria: acceptanceCriteria,
                priority: 9999,
                status: 'todo',
                story_points: finalStoryPoints,
                assigned_user: finalAssignedUser,
                story_location: 'backlog'
            };

            const createdStory = await DataService.createUserStory(storyData);

            setProjectContext(prevContext => ({
                ...prevContext,
                STORIES: [...prevContext.STORIES, createdStory]
            }));

            // setTimeout(() => {
            //     setLoading(false);
            //     resetFields();
            //     handleClose();
            //     refreshStories();
            // }, 3000);
            
            setLoading(false);
            resetFields();
            handleClose();
            
            // Ensure the component re-renders even if refreshStories is already true 
            setRefreshStories(prev => !prev);
        } catch (error) {
            // Handle error
            console.error('Failed to create new story', error);
        }
    };

    return (
        <Dialog open={open} onClose={() => {handleClose(); resetFields();}}>
            <DialogTitle>Create Story</DialogTitle>
            <form onSubmit={handleCreate}>
                <DialogContent>
                    <TextField
                        required
                        margin="dense"
                        id="title"
                        label="Title"
                        type="text"
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        multiline
                        maxRows={10}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <Autocomplete
                        value={selectedEpic ? selectedEpic : (epicId ? projectContext?.EPICS?.find(epic => epic.epic_id === epicId) : null)}
                        onChange={(event, newValue) => {
                            setEpicId(newValue ? newValue.epic_id : null);
                        }}
                        options={projectContext?.EPICS}
                        getOptionLabel={(option) => option ? option.name : ''}
                        isOptionEqualToValue={(option, value) => value ? option.epic_id === value.epic_id : false}
                        filterOptions={(options, params) => {
                            const filtered = options.filter((option) =>
                                option.name.toLowerCase().includes(params.inputValue.toLowerCase())
                            );
                            return filtered;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Epic"
                                margin="dense"
                                fullWidth
                            />
                        )}
                    />
                    <TextField
                        margin="dense"
                        id="acceptanceCriteria"
                        label="Acceptance Criteria"
                        type="text"
                        fullWidth
                        multiline
                        maxRows={10}
                        value={acceptanceCriteria}
                        onChange={(e) => setAcceptanceCriteria(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="storyPoints"
                        label="Story Points"
                        type="number"
                        fullWidth
                        value={storyPoints}
                        onChange={(e) => setStoryPoints(e.target.value)}
                    />
                    {(userPlan !== 'Free' && userPlan !== 'Basic') &&
                        <Autocomplete
                            value={assignedUser ? projectMembers.find(member => member.user_id === assignedUser) : null}
                            onChange={(event, newValue) => {
                                setAssignedUser(newValue ? newValue.user_id : null);
                            }}
                            options={projectMembers}
                            getOptionLabel={(option) => option ? option.name : ''}
                            isOptionEqualToValue={(option, value) => value ? option.user_id === value.user_id : false}
                            filterOptions={(options, params) => {
                                const filtered = options.filter((option) =>
                                    option.name.toLowerCase().includes(params.inputValue.toLowerCase())
                                );
                                return filtered;
                            }}
                            renderInput={(params) => {
                                const assignedUserDetails = assignedUser ? projectMembers.find(member => member.user_id === assignedUser) : null;
                                return (
                                    <TextField
                                        {...params}
                                        label="Assigned User"
                                        margin="dense"
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: assignedUserDetails ? (
                                                <InputAdornment position="start">
                                                    <Avatar src={assignedUserDetails.picture} sx={{ width: 20, height: 20, marginRight: '10px' }} />
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                    />
                                );
                            }}
                            renderOption={(props, option, { selected }) => (
                                <Tooltip title={option.email}>
                                    <Box component="li" {...props}>
                                        <Avatar src={option.picture} sx={{ width: 20, height: 20, marginRight: '10px' }} />
                                        {option.name}
                                    </Box>
                                </Tooltip>
                            )}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {handleClose(); resetFields();}} disabled={loading}>Cancel</Button>
                    <Button type="submit" disabled={loading}>Create</Button>
                </DialogActions>
                {loading && (
                    <Box>
                    <CircularProgress
                        size={20}
                        style={{ position: 'absolute', bottom: '20px', left: '20px' }}
                    />
                    </Box>
                )}                
            </form>
        </Dialog>
    );
};

export default NewStory;