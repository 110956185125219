import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, InputAdornment, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send.js';
import LoadingIndicator from '../LoadingIndicator.js';
import '../styles/ScrollbarStyles.css';

export default function ChatInput({ sendMessage, clearMessages, isProcessing }) {
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(isProcessing);
      }, [isProcessing]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleUserInput = (input) => {
        const trimmedInput = input.trim();
        if (trimmedInput) {
            setInput('');
            setLoading(true);
            // Call sendMessage with an object and a callback to reset loading state
            sendMessage({ text: trimmedInput, sender: 'user' }, () => setLoading(false));
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleUserInput(event.target.value);
            event.preventDefault();
        }
    };

    return (
        <Box sx={{ mb: 0, pl: 0, pr: 10, pb: 0 }}>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Type your message here..."
                value={input}
                onChange={handleInputChange}
                multiline
                minRows={1}
                maxRows={10}
                onKeyDown={handleKeyPress}
                disabled={loading} // Disable the text field when loading
                InputProps={{
                    style: { padding: '13px', backgroundColor: 'white' },
                    endAdornment: (
                        <InputAdornment position="end">
                            {loading ? (
                                <LoadingIndicator />
                            ) : (
                                <IconButton color="primary" onClick={() => handleUserInput(input)} disabled={!input.trim() || loading}>
                                    <SendIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& textarea::-webkit-scrollbar': {
                        width: '6px',
                        height: '6px',
                    },
                    '& textarea::-webkit-scrollbar-thumb': {
                        backgroundColor: '#e4e3e3',
                        borderRadius: '3px',
                    },
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '15px',
                        position: 'relative', // Add this to contain the pseudo-element
                        '&::after': { // Add this pseudo-element
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'rgba(211, 211, 211, 0.5)', // Grey overlay
                            borderRadius: 'inherit',
                            transition: 'background-color 0.3s',
                            pointerEvents: 'none', // Allow clicks to pass through
                            opacity: 0, // Start invisible
                        },
                        '&.Mui-disabled::after': { // Show the overlay when disabled
                            opacity: 1,
                        },
                    },
                }}
            />
        </Box>
    );
}