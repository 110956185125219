import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import GenerateProjectPlan from '../../../classes/GenerateProjectPlan';
import { Dialog, DialogTitle, DialogContent, CircularProgress, List, ListItem, ListItemText, Button, Checkbox, Typography, Box, Avatar } from '@mui/material';
import DataService from '../../../classes/DataService';
import TessAvatarImage from '../../../assets/images/Tess-Headshot-Small.webp';
import ProjectContextDto from '../../../dtos/ProjectContextDto';

const AiEpics = ({
    projectName, 
    projectDescription, 
    selectedEpic, 
    open, 
    onClose, 
    //refreshEpics, 
    currentEpics,
    projectContext,
    setProjectContext}) => {
    const [epics, setEpics] = useState(null);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState([]);
    const epicsData = [];
    const { id } = useParams();

    const generateProjectPlan = new GenerateProjectPlan(projectName, projectDescription, selectedEpic, epicsData);

    const fetchEpics = async () => {
        const abortController = new AbortController();
        setLoading(true);
        let result;
        try {

            //pre-emptively get documents if any exist
            //and set project context (attempt to lazy load docs)
            await getDocuments();

            if (currentEpics) {
                result = await generateProjectPlan.generateMoreEpics(null, null, projectContext, { signal: abortController.signal });
            } else {
                result = await generateProjectPlan.generateEpics(null, projectContext, { signal: abortController.signal });
            }
            setEpics(result.epics);
            setChecked(result.epics.map((_, index) => index));
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                throw error;
            }
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleClose = () => {
        if (!loading) {
            setEpics(null); // clear the results object
            onClose(); // call the onClose prop function
        }
    };

    const getDocuments = async () => {
        
        if (projectContext.DOCUMENTS.length > 0) return;
        
        let documents = await DataService.getDocumentsByProjectId(projectContext.PROJECT_ID);
        let context = ProjectContextDto.setDocuments(projectContext, documents);

        setProjectContext(prevContext => ({
            ...prevContext,
            DOCUMENTS: context.DOCUMENTS
        }));
    };

    const createEpics = async () => {
        setLoading(true);
        const newEpics = [];
        try {
            for (let index of checked) {
                const epic = epics[index];
                epic.project_id = id;
                const createdEpic = await DataService.createEpic(epic);
                newEpics.push(createdEpic);
            }

            setProjectContext(prevContext => ({
                ...prevContext,
                EPICS: [...prevContext.EPICS, ...newEpics]
            }));
        } catch (error) {
            console.error('Error creating epics:', error);
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
            fullWidth
            disableBackdropClick={loading}
            disableEscapeKeyDown={loading}
        >
        <DialogContent>
            {loading ? (
                <>
                    <Typography variant="h6">Generating Epics</Typography>
                    <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                        <CircularProgress size={20} />
                    </Box>
                </>
            ) : (
                <>
                    {!epics && Array.isArray(currentEpics) && currentEpics.length > 0 && (
                    <>
                        <Typography variant="h6">Generate More Epics</Typography>
                        <Box display="flex" alignItems="flex-start">
                            <Avatar alt="Tess Avatar" src={TessAvatarImage} sx={{ margin: '20px 0' }} />
                            <Typography variant="body1" sx={{ margin: '20px 0', marginLeft: '10px' }}>
                                I see you have some epics. If you need more, I can generate them for you. You know, because you're too lazy to do it yourself.
                                <br/>
                                Just click the generate button... or don't. I don't care.
                            </Typography>
                        </Box>
                        <hr />
                        <List>
                            {currentEpics.map((epic, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={epic.name} secondary={epic.description} />
                                </ListItem>
                            ))}
                        </List>
                    </>
                    )}
                    {!epics && (!Array.isArray(currentEpics) || currentEpics.length === 0) && (
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h6">Generate Epics</Typography>
                            <Box display="flex" alignItems="center" sx={{ marginTop: '20px', marginBottom: '20px' }}>
                                <Avatar alt="Tess Avatar" src={TessAvatarImage} />
                                <div style={{ marginLeft: '10px' }}>
                                    <Typography variant="body1">
                                        I know, the whole reason you're here is so I'll do this for you.
                                        <br />
                                        It's fine. Just click the generate button already and let me get to it.
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    )}
                    {epics && (
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h6">Review the Generated Epics</Typography>
                            <Box display="flex" alignItems="flex-start" sx={{ marginTop: '20px' }}>
                                <Avatar alt="Tess Avatar" src={TessAvatarImage} />
                                <div style={{ marginLeft: '20px' }}>
                                    <Typography variant="body1">
                                        <b>Uncheck</b> any epics you don't want. 
                                        <br />
                                        If you don't like any of them, that's fine. Just click CANCEL and we'll forget this ever happened.
                                    </Typography>
                                </div>
                            </Box>
                            <hr />
                        </Box>
                    )}
                    {Array.isArray(epics) && (
                        <>
                            <hr />
                            <List>
                                {epics.map((epic, index) => {
                                    const labelId = `checkbox-list-label-${index}`;

                                    return (
                                        <ListItem key={index} role={undefined} dense button onClick={handleToggle(index)}>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.indexOf(index) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                            <ListItemText id={labelId} primary={epic.name} secondary={epic.description} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </>
                    )}
                </>
                )}
                <Button 
                    onClick={handleClose}
                    sx={{ 
                        color: "gray", 
                        '&:hover': {
                            backgroundColor: "rgba(0.2,0.2,0.2,.02)"
                        }
                    }}
                    disabled={loading}
                >
                    Cancel
                </Button>
                <Button onClick={epics ? createEpics : fetchEpics} disabled={loading}>
                    {epics ? 'Accept' : 'Generate'}
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default AiEpics;