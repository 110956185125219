export default class SprintDto {
    constructor() {
        this.sprint_id = '';
        this.project_id = '';
        this.name = '';
        this.start_date = '';
        this.end_date = '';
        this.is_active = false;
    }

    static fromJSON(json) {
        const sprint = new SprintDto();
        sprint.sprint_id = json.sprint_id;
        sprint.project_id = json.project_id;
        sprint.name = json.name;
        sprint.start_date = json.start_date;
        sprint.end_date = json.end_date;
        sprint.is_active = json.is_active;
        return sprint;
    }
}