import React, { useEffect, useRef } from 'react';
import { Box, Typography, Grid, Button, Tooltip } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import PlaylistRemove from '@mui/icons-material/PlaylistRemove';
import ReactMarkdown from 'react-markdown';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../../../../styles/ScrollbarStyles.css';

const userMessageStyle = {
    backgroundColor: '#e0edfa',
    color: 'black',
    borderRadius: '20px',
    padding: '0 10px',
    margin: '10px',
    textAlign: 'left',
    maxWidth: '85%',
    wordWrap: 'break-word',
    display: 'inline-block',
    alignSelf: 'flex-end',
};

const aiMessageStyle = {
    backgroundColor: '#f3f2f7',
    color: 'black',
    borderRadius: '20px',
    padding: '0 10px',
    margin: '10px',
    maxWidth: '85%',
    wordWrap: 'break-word',
    display: 'inline-block',
};

export default function MessageDisplay({ messages }) {
    const { user } = useAuth0();
    const messageEndRef = useRef(null);

    useEffect(() => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleCopy = (message) => {
        navigator.clipboard.writeText(message);
    };

    return (
        <Box className='custom-scrollbar' style={{ height: 'calc(100%)', overflow: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {messages.length === 0 ? (
                <Box>
                    <Grid container justifyContent="flex-start">
                        <Box style={aiMessageStyle}>
                        <Typography sx={{ padding: '10px' }}>
                            Hello! I'm Tess, Meteor's AI Assistant.
                        </Typography>
                        <Typography sx={{ padding: '10px' }}>
                            I can help you with a variety of tasks, such as answering questions, providing information, and more.
                        </Typography>
                        <Typography sx={{ padding: '10px' }}>
                            If you want to start a new conversation, click the <PlaylistRemove /> button above.
                        </Typography>
                        <Typography sx={{ padding: '10px' }}>
                            <b>But be careful!</b> Clicking that button will <Typography sx={{ textDecoration: 'underline' }}>clear all chat history.</Typography>
                        </Typography>
                        </Box>
                    </Grid>
                    {/* <Grid container justifyContent="flex-end">
                        <Box style={userMessageStyle}>
                            <Typography>
                                This is the user.
                            </Typography>
                        </Box>
                    </Grid> */}
                </Box>
            ) : (
                messages.map((message, index) => (
                    <Grid container justifyContent={message.sender === 'user' ? "flex-end" : "flex-start"}>
                        <Box key={index} style={message.sender === 'user' ? userMessageStyle : aiMessageStyle}>
                            <Typography color="textPrimary">
                                {message.text.startsWith('```') && message.text.endsWith('```') ? (
                                    <SyntaxHighlighter language="javascript" style={darcula}>
                                        {message.text.slice(3, -3)}
                                    </SyntaxHighlighter>
                                ) : (
                                    <ReactMarkdown>{message.text}</ReactMarkdown>
                                )}
                            </Typography>
                            {message.sender !== 'user' && (
                                <Box>
                                    <Button 
                                        onClick={() => handleCopy(message.text)} 
                                        sx={{ 
                                            padding: '0', 
                                            margin: '0 0 10px 0', 
                                            minWidth: '0', 
                                            '&:hover': { 
                                                backgroundColor: 'transparent',
                                            } 
                                        }}
                                    >
                                        <Tooltip title='Copy' placement='bottom'>
                                            <ContentCopy 
                                                sx={{ 
                                                    width: '15px', 
                                                    height: '15px', 
                                                    color: 'lightgray',
                                                    '&:hover': {
                                                        color: 'gray'
                                                    }
                                                }} 
                                            />
                                        </Tooltip>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                ))
            )}
            <div ref={messageEndRef} />
        </Box>
    )
}