import React, { useState, useEffect } from 'react';
import DataService from '../../../classes/DataService.js';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const StoryComments = ({ selectedStoryForEdit, projectMembers }) => {
    const [storyComments, setStoryComments] = useState([]);
    const Auth0_user = useAuth0().user;

    useEffect(() => {
        if (selectedStoryForEdit && selectedStoryForEdit.user_story_id) {
            DataService.getStoryComments(selectedStoryForEdit.user_story_id)
            .then(response => {
                const commentsWithEmail = response.map(comment => {
                    const projectMember = projectMembers.find(member => member.user_id === comment.created_by);
                    return { 
                        ...comment,
                        email: projectMember ? projectMember.email : ''
                    };
                });

                // Sort comments by date
                commentsWithEmail.sort((a, b) => {
                    const dateA = new Date(a.created_on);
                    const dateB = new Date(b.created_on);
                    return dateB - dateA; // for descending order
                });

                setStoryComments(commentsWithEmail);
            })
            .catch(e => {
                console.error("There was a problem fetching the story comments: ", e);
            });
        }
    }, [selectedStoryForEdit, projectMembers, storyComments]);

    return (
        <div>
            <h3>Comments</h3>
            {storyComments.length > 0 ? (
                <List>
                    {storyComments.map((comment, index) => {
                        const newestDate = comment.edited_on > comment.created_on ? comment.edited_on : comment.created_on;
                        return (
                            <ListItem key={index} alignItems='flex-start//0'>
                                <ListItemAvatar>
                                    <Avatar 
                                        src={projectMembers.find(member => member.user_id === comment.created_by)?.user_metadata?.picture || projectMembers.find(member => member.user_id === comment.created_by)?.picture} 
                                        sx={{ width: 40, height: 40 }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={comment.comment}
                                    secondary={
                                        <>
                                            <Typography component="span" variant="caption" color="textSecondary" display="block" sx={{ marginTop: '10px' }}>
                                                {projectMembers.find(member => member.user_id === comment.created_by)?.name} - {comment.email}
                                            </Typography>
                                            <Typography component="span" variant="caption" color="textSecondary" display="block">
                                                {new Date(newestDate).toLocaleDateString() + ' ' +
                                                new Date(newestDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            ) : (
                <p>No comments yet</p>
            )}
        </div>
    );
};

export default StoryComments;