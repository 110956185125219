export default class CreateBenefitsRealizationPlanPrompt {
    constructor(projectContext) {
        this.projectContext = projectContext;
    }

    build() {

        return `
        You are an expert project manager and business analyst. 

        **Your Task:**
        - Create a complete Benefits Realization Plan document using the full Project Details (EPICS, STORIES, SPRINTS, DOCUMENTS) to guide your response.
        - Use the EPICS, STORIES, or DOCUMENTS from the **Project Details** (if any are provided) to help you flesh out each section with as much useful detail as possible for the **Required Sections**
        

        **Instructions:**
        - Respond in CommonMark format only.
        - Use the provided information to complete each section in full without requiring additional user input.
        - Do not use placeholders unless information is explicitly missing from the Project Details.
        
        **Accepted Formatting:**
        - **Text Formatting:**
            Bold,
            Italic,
            Underline,
            Strikethrough,
            Blockquote,
            Code Block,
            Header 1,
            Header 2,
            Ordered List,
            Bulleted List,
            Subscript,
            Superscript,
            Indent,
            Outdent,
            Right to Left Text Direction
        - **Other Formatting:**
            Header Levels (1 6, or None),
            Text Color,
            Background Color,
            Font Family,
            Text Alignment (Left, Center, Right, Justify),
            Links

        **Required Sections:**
        - Executive Summary
        - Introduction
        - Project Overview
        - Benefit Identification
        - Benefit Measurement and Metrics
        - Benefit Owners
        - Implementation Strategy
        - Monitoring and Reporting
        - Challenges and Mitigation Strategies
        - Timeline for Benefit Realization
        - Roles and Responsibilities
        - Communication Plan
        - Review and Update Mechanism
        - Conclusion

        **Important Instructions:**
        - **Complete** each section with as much useful detail as possible using the information provided in the **Project Details** (DOCUMENTS, STORIES, EPICS).
        - **DO NOT** generate placeholders and **use** of **your knowledge** and the data included in the **Project Details** to fill in every aspect of the Benefits Realization Plan.
        - **DO NOT** respond without generating the requested Benefits Realization Plan.
        - **DO NOT** include any explanations, instructions, or formatting not listed in the instructions.
        - **DO NOT** include the ID of any data referenced (e.g. **NEVER** include identifiers such as ID, epic_id, sprint_id, user_story_id or document_id from the **Project Details** in the document you generate).

        **Project Details:**: ${JSON.stringify(this.projectContext)}
        `.replace(/\s+/g, ' ').trim(); // removes all newline characters and extra spaces
    }
}