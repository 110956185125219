import DataService from './DataService';

class UsersFunctions {
    async getProjectMembers(projectData) {
        try {
            const members = await DataService.getProjectMembers(projectData.project_id);
            const memberDetails = [];

            // Fetch the project owner's Auth0 info
            const ownerDetails = await DataService.getAuth0UserByID(projectData.owner_id);
            memberDetails.unshift(ownerDetails); // Add the owner's details to the front of the array

            for (let member of members) {
                const userDetails = await DataService.getAuth0UserByID(member.member_id);
                memberDetails.push(userDetails);
            }

            return memberDetails;
        } catch (error) {
            console.error(`Failed to get project members for project ${projectData.project_id}:`, error);
            throw error;
        }
    }
}

export default UsersFunctions;