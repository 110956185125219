export default class GenerateEpicsPrompt {
    constructor(projectName, projectDescription, projectContext) {
        this.projectName = projectName;
        this.projectDescription = projectDescription;
        this.projectContext = projectContext;
    }

    build() {

        return `
        You will act as a Business Analyst and Project Manager for a new project based on the PROJECT_NAME, the PROJECT_DESCRIPTION as defined in the Project Details.

        **Your Task:**
        - Do a deep analysis of the project needs and the steps that will be required to complete it, then create all the epics necessary to complete the project. 
        - Use the full Project Details (EPICS, STORIES, DOCUMENTS) to guide your response.
        - Avoid generating duplicate epics with the same name or content as existing epics in the Project Details.
        - Ensure each new epic complements the existing project context, building on existing STORIES or DOCUMENTS wherever applicable.
        - Do not respond without giving epics. 
        - Do not use any placeholders, instead using the provided links or your existing knowledge to fill define the epic name and description. 
        - Do not include any explanations or instructions. 
        
        **Important Instructions:**
        - **ABSOLUTELY NO** markdown or formatting like \`\`\`json or \`\`\`.
        - **DO NOT** include any explanations, instructions, or additional text outside of the JSON response.
        - **DO NOT** use any markup, markdown, or code formatting of any kind.
        - **ONLY** output the story response format in a plain JSON object, exactly following the structure provided below. 
        - **USE** normal quotes instead of smart quotes.
        - **DO NOT** include the ID of any data referenced.
        - **LIMIT the number of epics to between 5 and 7** to ensure focus and manageable scope.
        
        Project Details: ${JSON.stringify(this.projectContext)}.
        
        **Required Response Format** (The **only** output should match this JSON format exactly):
        {
            "epics": [
                {
                    "name": "epic name",
                    "description": "epic description"
                }
            ]
        }`.replace(/\s+/g, ' ').trim(); // removes all newline characters and extra spaces
    }
}