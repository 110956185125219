import React, { useState, useEffect } from 'react';
import { List, ListItemText, Button, Box, Typography, Divider } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Home from '@mui/icons-material/Home';
import Article from '@mui/icons-material/Article';
import { Description, Folder, AddCircle } from '@mui/icons-material';
import '../../../styles/ScrollbarStyles.css';
import NewDocument from './NewDocument';

export default function ProjectDocuments({ 
    projectDocuments, 
    setProjectDocuments, 
    selectedDocument, 
    setSelectedDocument, 
    project, 
    Auth0_user,
    setAlert,
    userPlan,
    setProjectContext,
    projectContext
}) {
    const draftDocuments = projectDocuments.filter(document => document.status === 'draft');
    const publishedDocuments = projectDocuments.filter(document => document.status === 'published');
    const [open, setOpen] = React.useState(true);
    const userDraftDocuments = draftDocuments.filter(document => Auth0_user.sub === document.created_by);
    const [openNewDocument, setOpenNewDocument] = useState(false);
    const [newTitle, setNewTitle] = useState('');
    const [documentType, setDocumentType] = useState('');

    useEffect(() => {
        autoPublishDocument();
    }, []);

    const autoPublishDocument = () => {
        if ((userPlan === 'Free' || userPlan === 'Basic') && userDraftDocuments.length === 1) {

            const documents = projectDocuments.map(document => 
                document.status === 'draft' && document.created_by === Auth0_user.sub 
                ? { ...document, status: 'published' } 
                : document
            );

            setProjectDocuments(documents);

            setProjectContext(prevContext => ({
                ...prevContext,
                DOCUMENTS: documents
            }));
        }
    };

    const handleOpenNewDocument = () => {
        // console.log("Opening new document...");
        setDocumentType('Select Document Type...');
        setOpenNewDocument(true);
    };

    const handleCloseNewDocument = () => {
        // console.log("Closing new document...");
        setNewTitle('');
        setDocumentType('Select Document Type...');
        setOpenNewDocument(false);
    };
    
    // Sort the published documents so that documents with is_home set to true come first
    publishedDocuments.sort((a, b) => b.is_home - a.is_home);
    

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Box className='custom-scrollbar' sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 188px)', minWidth: '209px', width: '100%', overflow: 'auto', padding: '0 5px' }}>
            <Button 
                onClick={handleOpenNewDocument} 
                disabled={userPlan === 'Free' && projectDocuments.length >= 2}
                sx={{
                    justifyContent: 'flex-start',
                    color: '#525252',
                    margin: '5px',
                    '&:hover': {
                        color: 'black',
                        backgroundColor: '#b8c4d6',
                    }
                }}
            >
                <AddCircle sx={{ marginRight: '10px' }}/>
                New Document
            </Button>
            <Divider />
            <NewDocument 
                open={openNewDocument} 
                handleClose={handleCloseNewDocument} 
                newTitle={newTitle}
                setNewTitle={setNewTitle}
                documentType={documentType}
                setDocumentType={setDocumentType}
                setProjectDocuments={setProjectDocuments}
                project={project}
                Auth0_user={Auth0_user}
                setSelectedDocument={setSelectedDocument}
                setAlert={setAlert}
                userPlan={userPlan}
                projectContext={projectContext}
                setProjectContext={setProjectContext}
            />
            <List sx={{  }}>
                {publishedDocuments.map((document, index) => (
                    <ListItemButton 
                        key={document.document_id} 
                        sx={{ 
                            backgroundColor: document === selectedDocument ? '#e9eef7' : 'inherit',
                            '&:hover': {
                                backgroundColor: document === selectedDocument ? '#b8c4d6' : '#e9eef7',
                            },
                            borderRadius: document === selectedDocument ? '5px' : '5px',
                        }}
                        onClick={() => setSelectedDocument(document)}
                    >
                        <ListItemIcon>{document.is_home ? <Home /> : <Article />}</ListItemIcon>
                        <ListItemText primary={<Typography sx={{ fontSize: '0.875rem', ml: -3 }}>{document.title}</Typography>} />
                    </ListItemButton>
                ))}
                {userDraftDocuments.length > 0 && (
                    <>
                        <ListItemButton 
                            onClick={handleClick}
                            sx={{
                                borderRadius: '5px',
                                '&:hover': {
                                    backgroundColor: document === selectedDocument ? '#b8c4d6' : '#e9eef7',
                                },
                            }}
                        >
                            <ListItemIcon>
                                <Folder />
                            </ListItemIcon>
                            <ListItemText 
                                primary={
                                    <Typography 
                                        sx={{ 
                                            fontWeight: 'bold', 
                                            fontSize: '0.9rem', 
                                            ml: -3 }}
                                        >
                                        DRAFTS
                                    </Typography>
                                }
                            />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {userDraftDocuments.map((document, index) => (
                                    <ListItemButton 
                                        key={document.document_id}
                                        sx={{ 
                                            backgroundColor: document === selectedDocument ? '#e9eef7' : 'inherit',
                                            '&:hover': {
                                                backgroundColor: document === selectedDocument ? '#b8c4d6' : '#e9eef7',
                                            },
                                            borderRadius: document === selectedDocument ? '5px' : '5px',
                                            margin: '0 0px 0px 10px'
                                        }}
                                        onClick={() => setSelectedDocument(document)}
                                    >
                                        <ListItemIcon><Description /></ListItemIcon>
                                        <ListItemText primary={<Typography sx={{ fontSize: '0.875rem', ml: -3 }}>{document.title}</Typography>} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                    </>
                )}
            </List>
        </Box>
    );
}