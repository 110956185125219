import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

export default function MessageInput({ messages, setMessages, sendMessage, isProcessing, setIsProcessing }) {
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(isProcessing);
    }, [isProcessing]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleUserInput = (input) => {
        const trimmedInput = input.trim();
        if (trimmedInput) {
            setInput('');
            setLoading(true);
            const inputObject = { text: trimmedInput, sender: 'user', created_on: new Date() };
            sendMessage(inputObject, messages, setMessages, setIsProcessing, () => setLoading(false));
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleUserInput(event.target.value);
            event.preventDefault();
        }
    };

    return (
        <Box sx={{ m: 1, pl: 0, pr: 2, pb: 0, width: '100%' }}>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Type your message here..."
                value={input}
                onChange={handleInputChange}
                multiline
                minRows={1}
                maxRows={10}
                onKeyDown={handleKeyPress}
                disabled={loading}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton color="primary" onClick={() => handleUserInput(input)} disabled={!input.trim() || isProcessing}>
                                {isProcessing ? <CircularProgress size={24} /> : <SendIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
}