import React, { useEffect, useState } from 'react';
import { Box, Typography, Chip, Avatar, ListItem, Tooltip } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import StoryFunctions from '../../../classes/StoryFunctions';
import styles, { epicColors } from '../../../classes/BacklogStyles';

const formatStatus = (status) => {
    switch (status) {
      case 'todo':
        return 'TODO';
      case 'in-progress':
        return 'IN PROGRESS';
      case 'done':
        return 'DONE';
      default:
        return status;
    }
};

const Story = ({ 
    story, 
    getStatusColors, 
    //epics, 
    projectMembers, 
    userPlan,
    projectContext,
    setProjectContext
}) => {
    const [epicColor, setEpicColor] = useState(null);
    const [epic, setEpic] = useState(null);
    const [assignedUser, setAssignedUser] = useState(null);
    

    useEffect(() => {
        if (projectContext.EPICS && story.epic_id) {
            const foundEpic = projectContext.EPICS.find(e => e.epic_id === story.epic_id);
            setEpic(foundEpic);

            if (foundEpic && foundEpic.color_id) {
                const color = epicColors.find(color => color.id === foundEpic.color_id);
                setEpicColor(color);
            }
        } else {
            setEpic(null);
            setEpicColor(null);
        }
    //}, [story, epics, story.epic_id, assignedUser, projectMembers]);
    }, [story, projectContext.EPICS, story.epic_id, assignedUser, projectMembers]);

    useEffect(() => {
        if (projectMembers && story.assigned_user) {
            const foundMember = projectMembers.find(member => member.user_id === story.assigned_user);
            setAssignedUser(foundMember);
        } else {
            setAssignedUser(null);
        }
    }, [story, projectMembers, story.assigned_user]);

    return (
        <ListItem
            style={{ 
                margin: '0 0 5px 0',
                padding: '10px',
                backgroundColor: 'white',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'space-between',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box display="inline" alignItems="left" style={{ userSelect: 'none' }}>
                <Typography style={story.status === 'done' ? { textDecoration: 'line-through' } : {}}>
                    {story.title}
                </Typography>
                <Typography variant='caption'>
                    {story.description ? (story.description.length > 60 ? story.description.substring(0, 60) + '...' : story.description) : ''}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" style={{ userSelect: 'none' }}>
                {epic && epicColor && (
                    <Chip 
                        label={epic.name.length > 20 ? `${epic.name.substring(0, 20)}...` : epic.name}
                        variant='filled'
                        size='small'
                        style={{ 
                            marginRight: '10px',
                            backgroundColor: epicColor.primary,
                            color: epicColor.secondary 
                        }} 
                    />
                )}
                {userPlan !== 'Free' &&
                    <Chip 
                        label={
                            <Typography variant="caption">
                                {formatStatus(story.status)}
                            </Typography>
                        }
                        variant='filled'
                        size='small'
                        style={{ 
                            marginLeft: 'auto', 
                            backgroundColor: getStatusColors(story.status).primary, 
                            color: getStatusColors(story.status).secondary 
                        }} 
                    />
                }
                <Box
                    component="span" 
                    display="inline-flex" 
                    alignItems="center" 
                    justifyContent="center" 
                    width={24} 
                    height={24} 
                    borderRadius="50%" 
                    bgcolor={"#EEEEEE"}
                    ml={2}
                    fontSize={12}
                >
                    {story.story_points == null ? '-' : story.story_points}
                    {/* {story.priority} */}
                </Box>
                    {assignedUser ? (
                        <Tooltip title={
                            <React.Fragment>
                                <Typography variant='body2' color="inherit">{assignedUser.name}</Typography>
                                <Typography variant='caption' color="inherit">{assignedUser.email}</Typography>
                            </React.Fragment>
                        }>
                            <Avatar 
                                src={assignedUser.user_metadata && assignedUser.user_metadata.picture ? assignedUser.user_metadata.picture : assignedUser.picture || <AccountCircle />} 
                                sx={{ width: 24, height: 24, ml: 2, fontSize: 12 }}
                            />  
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </Box>
        </ListItem>
    );
};

export default Story;