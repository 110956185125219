import React, { useState } from 'react';
import DataService from '../../../classes/DataService';
import StoryFunctions from '../../../classes/StoryFunctions';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Typography, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const NewSprint = ({ 
    project_id, 
    //stories, 
    setSprints, 
    open, 
    onClose,
    projectContext,
    setProjectContext
}) => {
    const [name, setName] = useState('');
    const now = new Date();
    const localDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().slice(0,10);
    const [startDate, setStartDate] = useState(localDate);
    const [loading, setLoading] = useState(false);

    const startSprint = async (sprintName, startDate) => {
        setLoading(true);
        // Use the parameters as the new sprint details
        let newSprintName = sprintName;

        // Use dataService to create a new sprint
        const newSprint = await DataService.createSprint(project_id, newSprintName, startDate);
        // console.log('New Sprint:', newSprint);
        StoryFunctions.assignSprintToStories(projectContext.STORIES, newSprint.sprint_id)
            .then(() => setProjectContext(prevContext => ({
                ...prevContext,
                SPRINTS: [...prevContext.SPRINTS, newSprint],
                STORIES: prevContext.STORIES.map(story =>
                    story.story_location === 'sprintBacklog' && 
                    story.status !== 'inactive'
                        ? { ...story, sprint_id: newSprint.sprint_id }
                        : story
                )
            })));
        
        setSprints(prevSprints => [...prevSprints, newSprint]);
        // setTimeout(() => {
        //     setLoading(false);
        // }, 3000);
        setLoading(false);
    };

    const handleCreate = async (event) => {
        setLoading(true);
        event.preventDefault();
        await startSprint(name, startDate);
        // setTimeout(() => {
        //     setLoading(false);
        //     onClose();
        //     setName('');
        //     setStartDate('');
        // }, 3000);
        setName('');
        setStartDate('');
        setLoading(false);
        onClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Create a New Sprint</DialogTitle>
                <form onSubmit={handleCreate}>
                    <DialogContent>
                        <Typography gutterBottom variant="body1">
                            You're about to start a new sprint. All the stories in the Sprint Backlog will appear in the Sprint Board where you can manage them.
                        </Typography>
                        <TextField
                            required
                            margin="dense"
                            label="Name"
                            type="text"
                            fullWidth
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={() => {
                                setName('');
                                setStartDate('');
                                onClose();
                            }} 
                            color="error"
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" disabled={loading}>
                            Create
                        </Button>
                    </DialogActions>
                    {loading && (
                        <Box>
                        <CircularProgress
                            size={20}
                            style={{ position: 'absolute', bottom: '20px', left: '20px' }}
                        />
                        </Box>
                    )}
                </form>
            </Dialog>
        </div>
    );
};

export default NewSprint;