import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Box, Card, CardContent, Button, CardMedia, Chip, Link, List, ListItem } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SubscriptionContext } from '../contexts/SubscriptionContext';
import DataService from '../classes/DataService';
import ReactMarkdown from 'react-markdown';

const Welcome = ({ userName }) => {
  const { user } = useAuth0(); // Get the user's information
  const navigate = useNavigate();
  const { subscription } = React.useContext(SubscriptionContext);
  const [newsArticles, setNewsArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [simulatedLoading, setSimulatedLoading] = useState(false);

  // Simulate loading time
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // console.log('Simulated loading complete');
  //     setSimulatedLoading(false);
  //   }, 3000);

  //     return () => clearTimeout(timer);
  //   }, []);

  useEffect(() => {
    setLoading(true);
    DataService.getLatestNews()
      .then(data => {
        const articles = Array.isArray(data) ? data : [data];
        setNewsArticles(articles);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const renderChatButton = () => {
    if (subscription === 'professional') {
      return <Button size="small" onClick={() => navigate('/talk-to-tess')}>Chat with Tess</Button>;
    } else {
      return <Chip icon={<AutoAwesomeIcon />} label="Upgrade to Professional" color='success' size="small" />;
    }
  };

  return (
    <div>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Welcome, {userName || user.name}!</Typography>
      <Typography gutterBottom variant="h5" sx={{ marginTop: '2rem', fontWeight: 'bold' }}>The Latest News</Typography>
      <Box>
        {loading || simulatedLoading ? (
          <Skeleton variant="rounded" animation="wave" width="93%" height={118} />
            ) : (
              newsArticles.map(article => (
                <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'row', minWidth: '93%', maxWidth: '93%' }} key={article.id}>
                  <Box>
                    <img
                        style={{ width: '200px', height: '100%', objectFit: 'cover' }}
                        src={article.image}
                        alt={article.title}
                    />
                  </Box>
                  <CardContent sx={{ flex: '1 1 auto', wordBreak: 'break-word' }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {new Date(article.posted_date).toLocaleString(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        timeZoneName: 'short'
                      })}
                    </Typography>
                    <Typography variant="h5" sx={{ wordBreak: 'break-word' }}>
                        {article.title}
                    </Typography>
                    <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                        <ReactMarkdown>{article.body}</ReactMarkdown>
                    </Typography>
                  </CardContent>
                </Card>
              ))
            )}
      </Box>
      <div style={{ marginTop: '40px' }}>
        <Typography gutterBottom variant='h5' sx={{ fontWeight: 'bold' }}>Getting Started</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <Card variant='outlined' sx={{ minWidth: '30%', maxWidth: '30%' }}>
            <CardMedia
              component="img"
              height="140"
              image="https://meteorstore001.blob.core.windows.net/web-images/automated-planning.webp"
              alt="Create Project Plans"
            />
            <CardContent>
              <Typography variant="h5" >Project Management</Typography>
              <Typography variant="body2"><p>With only a project title and description, Meteor can generate comprehensive project plans at the click of a button. Navigate to the Project section to start now, or watch our tutorials first.</p></Typography>
              <Typography variant="h6">Tutorials</Typography>
              <List>
                <ListItem>
                  <a href="https://youtu.be/dylcBluh5-c" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>How to Navigate the Projects List and Create New Projects</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/gJ3dMeZ1eEA" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Introduction to the Project Workspace</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/--fXP0E6_SE" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Creating, Generating, and Editing Epics</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/nwsSrWzckCw" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Creating, Generating, and Editing Stories</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/9C9byAC-d4Y" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Plan, Start, Execute, and End Sprints</Typography>
                  </a>
                </ListItem>
              </List>         
            </CardContent>
          </Card>
          <Card variant='outlined' sx={{ minWidth: '30%', maxWidth: '30%' }}>
          <CardMedia
              component="img"
              height="140"
              image="https://meteorstore001.blob.core.windows.net/web-images/document-generation.jpg"
              alt="Create Project Plans"
            />
            <CardContent>
              <Typography variant="h5">Project Documents</Typography>
              <Typography variant="body2"><p>Need a project charter, market analysis, or business case? Meteor has you covered. Navigate to one of your projects, then head over to the Documents section. You'll have documents ready in moments!</p></Typography>
              <Typography variant="h6">Tutorials</Typography>
              <List>
                <ListItem>
                  <a href="https://youtu.be/VODPQAOF6dU" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Introduction to the Document Section</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/Qyf9r4IOjus" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Creating Custom Documents</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/k6WMZFMiYqo" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Generating Documents</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/s7iMY0uOMC4" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Editing Documents</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/wFAKFNmq9uQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Publishing Documents</Typography>
                  </a>
                </ListItem>
                <ListItem>
                  <a href="https://youtu.be/64TJ4DVvwCs" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Printing Documents</Typography>
                  </a>
                </ListItem>
              </List> 
            </CardContent>
          </Card>
          <Card variant='outlined' sx={{ minWidth: '30%', maxWidth: '30%' }}>
            <CardMedia
                component="img"
                height="140"
                image="https://meteorstore001.blob.core.windows.net/web-images/ai-chat.jpg"
                alt="Create Project Plans"
              />
            <CardContent>
              <Typography variant="h5">Chat with Tess</Typography>
              <Typography variant="body2"><p>Tess is our powerful AI Assistant. She'll be right there to answer questions or help you brainstorm as you plan, execute, and complete your project.</p></Typography>
              <Typography variant="h6">Tutorials</Typography>
              <List>
                <ListItem>
                  <a href="https://youtu.be/djzEyDz7Y0k" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: '#1976d2' }}>Interacting With the Tess AI Assistant</Typography>
                  </a>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Box>
      </div>
    </div>
  );
};

export default Welcome;