// FILE: NewEpic.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Box } from '@mui/material';
import DataService from '../../../classes/DataService';
import CircularProgress from '@mui/material/CircularProgress';

const NewEpic = ({ 
    open, 
    handleClose, 
    //refreshEpics,
    setProjectContext
}) => {
    const [newEpicName, setNewEpicName] = useState('');
    const [error, setError] = useState(false);
    const [description, setDescription] = useState('');
    const { id: projectId } = useParams(); // Get project_id from URL
    const [loading, setLoading] = useState(false);

    const handleCreate = async (event) => {
        setLoading(true);
        event.preventDefault();

        if (!newEpicName.trim()) {
            setError(true);
            setLoading(false);
            return;
        }

        try {
            let createdEpic = await DataService.createEpic({ project_id: projectId, name: newEpicName, description });
            // setTimeout(() => {
            //     setNewEpicName('');
            //     setDescription('');
            //     handleClose();
            //     refreshEpics();
            //     setLoading(false);
            // }, 3000);

            setProjectContext(prevContext => ({
                ...prevContext,
                EPICS: [...prevContext.EPICS, createdEpic]
            }));

            setNewEpicName('');
            setDescription('');
            handleClose();
            //refreshEpics();
        } catch (error) {
            // Handle error
            console.error('Failed to create new epic', error);
        }
        setLoading(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create New Epic</DialogTitle>
            <form onSubmit={handleCreate}>
                <DialogContent>
                    <DialogContentText>
                        Please enter the details of the new epic.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Epic Name"
                        type="text"
                        fullWidth
                        required
                        value={newEpicName}
                        onChange={(e) => {
                            setNewEpicName(e.target.value);
                            setError(false); // Reset error when user types
                        }}
                        error={error} // Show error if error is true
                        helperText={error ? 'Epic Name is required' : ''}
                    />
                    <TextField // Add TextField for description
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button type="submit" disabled={loading}>Create</Button>
                </DialogActions>
                {loading && (
                    <Box>
                    <CircularProgress
                        size={20}
                        style={{ position: 'absolute', bottom: '20px', left: '20px' }}
                    />
                    </Box>
                )}
            </form>
            
        </Dialog>
    );
};

export default NewEpic;