// ScrumBoard.js
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, CardContent, Typography, Box, Avatar, Tooltip, Skeleton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DataService from '../../../classes/DataService';
import StoryFunctions from '../../../classes/StoryFunctions';
import EditStory from '../UserStories/EditStory';
import styles, { epicColors } from '../../../classes/BacklogStyles';
import Loading from '../../Loading';

const ScrumBoard = ({ 
  projectEpics, 
  projectMembers, 
  sprints, 
  userPlan,
  projectContext,
  setProjectContext,
  refreshStories,
  setRefreshStories
}) => {
  const { id: project_id } = useParams();
  const [projectStories, setProjectStories] = useState([]);
  const [stories, setStories] = useState({
      'todo': [],
      'in-progress': [],
      'done': []
  });
  const [selectedStoryForEdit, setSelectedStoryForEdit] = useState(null);
  const [isEditStoryOpen, setIsEditStoryOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Check if there are any active sprints
  const activeSprints = sprints.filter(sprint => sprint.is_active);

  // const fetchStories = async () => {
  //     let fetchedStories = await DataService.getUserStoriesByProjectId(project_id);
  //     fetchedStories = fetchedStories.filter(story => story.story_location === 'sprintBacklog');
  //     setProjectStories(fetchedStories);
  // };

  const getEpicColor = (epic) => {
    const epicColor = epicColors.find(color => color.id === epic.color_id);
    return epicColor ? { primary: epicColor.primary, secondary: epicColor.secondary } : { primary: '#000', secondary: '#000' };
  };

  // useEffect for projectStories
  useEffect(() => {
      const sortAndSetStories = async () => {
          if (projectStories && projectStories.length > 0) {
              // Sort the stories by their priorities
              let sortedStoriesArray = await StoryFunctions.checkAndAssignPrioritiesForSprintBoard(projectStories);

              // Group the sorted stories by their statuses
              const sortedStories = sortedStoriesArray.reduce((acc, story) => {
                  if (acc.hasOwnProperty(story.status)) {
                      acc[story.status].push(story);
                  }
                  return acc;
              }, {
                  'todo': [],
                  'in-progress': [],
                  'done': []
              });
              
              setStories(sortedStories);
              // setTimeout(() => setLoading(false), 3000);
              setLoading(false);
          }
      };
      sortAndSetStories();
  }, [project_id, projectStories, projectEpics]);

  useEffect(() => {
      setLoading(true);
      setProjectStories(projectContext.STORIES.filter(story => story.story_location === 'sprintBacklog'));
      setLoading(false);
  }, [project_id, refreshStories]);

  const onBoardDragEnd = async (result) => {
    const newState = await StoryFunctions.onBoardDragEnd(result, stories);
    setStories(newState);
  };
  
  if (activeSprints.length === 0) {
    return (
        <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center"
            flexDirection="column"
            style={{ height: '70vh' }}
        >
          <Box>
            <Typography variant='h5'>
              You must have an active sprint to use the Sprint Board<br/>
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1' style={{ fontStyle: 'italic' }}>
              Head over the the backlog to plan and start a sprint
            </Typography>
          </Box>
        </Box>
    );
  }

  return loading ? (
    <Box display="flex" flexDirection="row" height="100%" sx={{ marginTop: '10px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '24%' }}>
        <Skeleton variant="rounded" animation="wave" width="50%" height="20px" sx={{ margin: '10px 0 2px 0' }} />
        <Skeleton variant="rounded" animation="wave" width="100%" height="500px" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '24%', marginLeft: '20px' }}>
        <Skeleton variant="rounded" animation="wave" width="50%" height="20px" sx={{ margin: '10px 0 2px 0' }} />
        <Skeleton variant="rounded" animation="wave" width="100%" height="500px" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '24%', marginLeft: '20px' }}>
        <Skeleton variant="rounded" animation="wave" width="50%" height="20px" sx={{ margin: '10px 0 2px 0' }} />
        <Skeleton variant="rounded" animation="wave" width="100%" height="500px" />
      </Box>
    </Box>
  ) : (
    <DragDropContext onDragEnd={onBoardDragEnd}>
      <div style={styles.lanesContainer}>
        {Object.entries(stories).map(([columnId, columnStories], index) => (
          <div key={columnId}>
            <div style={{ fontWeight: 'bold', fontSize: '.9rem', margin: '20px 0 0 15px'}}>
              {columnId.replace('-', ' ').toUpperCase()}
            </div>
            <Droppable droppableId={columnId}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={styles.lane}
                >
                  {columnStories.filter(story => story.story_location === 'sprintBacklog').map((story, index) => {
                        const epic = projectEpics ? projectEpics.find(epic => epic.epic_id === story.epic_id) : undefined;
                        let epicColor;
                        if (epic && epicColors) {
                            epicColor = epicColors.find(color => color.id === epic.color_id);
                        }
                        return (
                            <Draggable key={story.user_story_id} draggableId={story.user_story_id} index={index}>
                            {(provided) => (
                                <Card
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{...styles.storyContainer, ...provided.draggableProps.style, boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)'}}
                                onClick={() => {
                                  setSelectedStoryForEdit(story);
                                  setIsEditStoryOpen(true);
                                }}
                                >
                                <CardContent style={{textAlign: 'left', padding: '0', }}>
                                  <Box>
                                    <Typography 
                                      variant="body" 
                                      color="text.primary"
                                      style={story.status === 'done' ? { textDecoration: 'line-through' } : {}}
                                    >
                                      {story.title} 
                                    </Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: '15px', minHeight: '30px' }}>
                                      {
                                          (() => {
                                              const assignedMember = projectMembers.find(member => member.user_id === story.assigned_user);
                                              if (assignedMember) {
                                                return (
                                                    <Tooltip title={
                                                        <React.Fragment>
                                                            <Typography variant='body2' color="inherit">{assignedMember.name}</Typography>
                                                            <Typography variant='caption' color="inherit">{assignedMember.email}</Typography>
                                                        </React.Fragment>
                                                    }>
                                                        {assignedMember.picture ? (
                                                            <Avatar 
                                                              sx={{ width: 25, height: 25, fontSize: '.7rem' }} 
                                                              src={assignedMember.user_metadata && assignedMember.user_metadata.picture ? assignedMember.user_metadata.picture : assignedMember.picture}
                                                            />
                                                        ) : (
                                                            <AccountCircle sx={{ width: 25, height: 25, color: 'gray', bottom: '5px' }} />
                                                        )}
                                                    </Tooltip>
                                                );
                                              }
                                          })()
                                      }
                                  </Box>
                                  {epic && (
                                    <Box
                                      style={{
                                        backgroundColor: getEpicColor(epic).primary,
                                        color: getEpicColor(epic).secondary,
                                        borderRadius: '6px',
                                        padding: '2px 5px',
                                        marginRight: '10px',
                                        fontSize: '.8rem',
                                        position: 'absolute', // Make the box positioned relative to the card
                                        bottom: '10px', // Position it at the bottom
                                        right: '0', // Position it at the right
                                      }}
                                    >
                                      {epic.name.length > 20 ? `${epic.name.substring(0, 20)}...` : epic.name}
                                    </Box>
                                  )}
                                </CardContent>
                              </Card>
                            )}
                            </Draggable>
                        );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
        <EditStory
          open={isEditStoryOpen}
          handleClose={() => {
              setIsEditStoryOpen(false);
              setSelectedStoryForEdit(null); // Clear the selected story when the drawer is closed
          }}
          selectedStoryForEdit={selectedStoryForEdit}
          setRefreshStories={setRefreshStories}
          projectMembers={projectMembers}
          userPlan={userPlan}
          projectContext={projectContext}
          setProjectContext={setProjectContext}
      />
      </div>
    </DragDropContext>
    
  );
};

export default ScrumBoard;