export default class CreateBudgetEstimateAndFinancialAnalysisPrompt {
    constructor(projectContext) {
        this.projectContext = projectContext;
    }

    build() {

        return `
        You are a finance manager and expert project manager. 

        **Your Task:**
        - Create a complete Budget Estimate and Financial Analysis document using the full Project Details to guide your response.
        - Use the STORIES and DOCUMENTS from the **Project Details** (if any are provided) to help you flesh out each section with as much useful detail as possible for the **Required Sections**
        - Format the financial data clearly and legibly using lists in the **Required Sections** based on the **Accepted Formatting** and include as much useful detail as possible
        - Include an overall evaulation and analysis of the financial data included in this document and use your best judgement to provide a realistic analysis in the **Financial Analysis** section of the **Required Sections**
        - Use your best judgment to generate realistic data if not enough information is present

        **Instructions:**
        - Respond in CommonMark format only.
        - Use the provided information to complete each section in full without requiring additional user input.
        - Do not use placeholders unless information is explicitly missing from the Project Details.
        
        **Accepted Formatting:**
        - **Text Formatting:**
            Bold,
            Italic,
            Underline,
            Strikethrough,
            Blockquote,
            Code Block,
            Header 1,
            Header 2,
            Ordered List,
            Bulleted List,
            Subscript,
            Superscript,
            Indent,
            Outdent,
            Right to Left Text Direction
        - **Other Formatting:**
            Header Levels (1 6, or None),
            Text Color,
            Background Color,
            Font Family,
            Text Alignment (Left, Center, Right, Justify),
            Links
        
        **Required Sections:**
        - Budget Summary
        - Personnel Costs
        - Development Costs
        - Marketing and Launch Costs
        - Operational Costs (First Year)
        - Total Project Budget
        - Financial Analysis

        **Important Instructions:**
        - **Complete** each section with as much useful detail as possible using the information provided in the **Project Details** (DOCUMENTS, STORIES, EPICS).
        - **DO NOT** generate placeholders and **use** of **your knowledge** and the data included in the **Project Details** to fill in every aspect of the Budget Estimate and Financial Analysis.
        - **DO NOT** respond without generating the requested Budget Estimate and Financial Analysis.
        - **DO NOT** include any explanations, instructions, or formatting not listed in the instructions.
        - **DO NOT** include the ID of any data referenced (e.g. **NEVER** include identifiers such as ID, epic_id, sprint_id, user_story_id or document_id from the **Project Details** in the document you generate).

        **Project Details:** ${JSON.stringify(this.projectContext)}
        `.replace(/\s+/g, ' ').trim(); // removes all newline characters and extra spaces
    }
}