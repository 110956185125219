import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import BacklogEpics from '../Epics/BacklogEpics.js';
import BacklogStories from '../UserStories/BacklogStories.js';
import DataService from '../../../classes/DataService.js';
import StoryFunctions from '../../../classes/StoryFunctions';
import { DragDropContext } from 'react-beautiful-dnd';
import { use } from 'i18next';

const ProjectBacklog = ({ 
  projectMembers, 
  project, 
  sprints, 
  setSprints, 
  projectEpics, 
  setProjectEpics, 
  stories, 
  setStories, 
  userPlan,
  projectContext,
  setProjectContext,
  refreshStories,
  setRefreshStories
}) => {
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const id = project.project_id;
  const [dragResult, setDragResult] = useState(null);
  const [loading, setLoading] = useState(true);

  const onDragEnd = async (result) => {
    const activeSprint = sprints.find(sprint => sprint.is_active === true);
    const sprint_id = activeSprint ? activeSprint.sprint_id : '';

    // Check if there was a valid drop location
    if (!result.destination) return;

    // If story is dragged between the backlog and sprintBacklog, confirm action via dialog
    if (activeSprint && result.source.droppableId === 'backlog' && result.destination.droppableId === 'sprintBacklog') {
      setDragResult(result); // Store the drag result for later use
      setOpenDialog(true);   // Show confirmation dialog
    } else {
      // Otherwise, immediately update the priorities
      await handleDragUpdate(result, sprint_id);
    }
  };

  // Handle the drag update logic and update both state and backend
  const handleDragUpdate = async (result, sprint_id) => {
    let list = projectContext.STORIES.sort((a, b) => a.priority - b.priority);
    StoryFunctions.onDragEnd(result, list, (reorderedStories) => {
      
      // Update the project context with the new full list of stories (reordered and unchanged)
      setProjectContext(prevContext => ({
        ...prevContext,
        STORIES: reorderedStories
      }));

      // Ensure the component re-renders even if refreshStories is already true 
      setRefreshStories(prev => !prev);

    }, sprint_id, selectedEpic);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  
  const handleConfirm = async () => {
    const activeSprint = sprints.find(sprint => sprint.is_active === true);
    const sprint_id = activeSprint ? activeSprint.sprint_id : '';
    await handleDragUpdate(dragResult, sprint_id); // Use the stored drag result
    setOpenDialog(false);
  };

  useEffect(() => {
    setStories(projectContext.STORIES);
    setLoading(false);
  }, [id]);

  return loading ? (
    <Box display="flex" flexDirection="row">
      <Skeleton variant="rounded" animation="wave" width={300} height={500} />
      <Box width="100%">
        <Skeleton variant="rounded" animation="wave" width="100%" height={250} sx={{ marginLeft: "10px" }} />
        <Skeleton variant="rounded" animation="wave" width="100%" height={240} sx={{ margin: "10px 0 0 10px" }} />
      </Box>
    </Box>
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box display="flex" flexDirection="row">
        <BacklogEpics 
          epics={projectContext?.EPICS} 
          setEpics={setProjectEpics} 
          selectedEpic={selectedEpic} 
          setSelectedEpic={setSelectedEpic}
          projectContext={projectContext}
          setProjectContext={setProjectContext}
        />
        <BacklogStories 
          epics={projectContext?.EPICS} 
          setProjectEpics={setProjectEpics} 
          stories={stories} 
          setStories={setStories} 
          selectedEpic={selectedEpic} 
          projectMembers={projectMembers} 
          project={project} 
          sprints={sprints} 
          setSprints={setSprints}
          userPlan={userPlan} 
          projectContext={projectContext}
          setProjectContext={setProjectContext}
          refreshStories={refreshStories}
          setRefreshStories={setRefreshStories}
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle>{"Confirm Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Adding stories to an active sprint is not recommended and can effect the sprint's progress. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </DragDropContext>
  );
};

export default ProjectBacklog;