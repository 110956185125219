import axios from 'axios';

class ProjectService {
    constructor() {
        this.url = "http://localhost:3000";
        this.axiosInstance = axios.create({
            baseURL: this.url
        });
    }

    async createProject(name, description, projectKey) {
        const body = {
            name: name,
            description: description,
            projectKey: projectKey
        };

        try {
            const response = await this.axiosInstance.post('/projects', body);
            return response.data;
        } catch (error) {
            console.error(`Error creating project: ${error}`);
            return null;
        }
    }
}

export default ProjectService;