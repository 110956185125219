import DataService from '../../classes/DataService';

class AccountController {
    constructor(user, setUserProfileImage) {
        this.user = user;
        this.userID = user.sub;
        this.setUserProfileImage = setUserProfileImage;
    }
    baseURL = process.env.REACT_APP_NODE_SERVICE_HOST || 'http://localhost:3002';

    fetchUser = async () => {
        if (this.user) {
            const fullUser = await DataService.getAuth0UserByID(this.user.sub);
            if (this.user.sub.startsWith('auth0')) {
                this.setUserProfileImage(this.user.picture);
            } else if (fullUser && fullUser.user_metadata && fullUser.user_metadata.picture) {
                this.setUserProfileImage(fullUser.user_metadata.picture);
            } else {
                this.setUserProfileImage(this.user.picture);
            }
            // console.log('Full User:', fullUser);
          }
    };

    handleSaveImage = async (selectedImage, selectedFile, setOpen) => {
        if (selectedImage) {
            const file = selectedImage;
            const response = await DataService.uploadImage(selectedFile);
            // console.log('Uploaded Image Response', response);
            if (response) {
                const pictureUrl = response;
                await DataService.updateUserPicture(this.userID, pictureUrl);
                this.setUserProfileImage(pictureUrl); // Set the new profile image
                setOpen(false); // Close the dialog
                this.fetchUser();
            }
        }
    };

    handleImageChange = (e, setSelectedImage, setSelectedFile) => {
        if (e.target.files && e.target.files[0]) {
            let img = URL.createObjectURL(e.target.files[0]);
            setSelectedImage(img);
            setSelectedFile(e.target.files[0]);
        }
    };

    handleNameChange = (event, setCurrentName) => {
        setCurrentName(event.target.value);
    };

    handleEmailChange = (event, setCurrentEmail) => {
        setCurrentEmail(event.target.value);
    };

    handleSaveUser = async (currentName, currentEmail) => {
        const userData = {
            name: currentName,
            email: currentEmail
        };
        const response = await DataService.updateUserData(this.userID, userData);
        if (response) {
            this.fetchUser();
        }
    };

    async getUserPlan(plan_id) {
        try {
            const response = await fetch(`${this.baseURL}/plans/${plan_id}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Failed to fetch user plan:", error);
            throw error;
        }
    }
}

export default AccountController;