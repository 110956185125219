import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Typography, Button, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import DataService from '../classes/DataService';

const Root = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const EmailVerification = ({ fullUser }) => {
    const { user, logout } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleVerifyEmail = async () => {
        setLoading(true);
        setError(null);
        try {
            await DataService.sendAuth0EmailVerification(fullUser.user_id);
            setSuccess(true);
        } catch (err) {
            setError('Failed to send verification email. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleGoToMeteor = () => {
        logout();
    };

    if (loading) {
        return (
            <Root>
                <CircularProgress />
                <Typography variant="body1" gutterBottom>
                    Sending verification email...
                </Typography>
            </Root>
        );
    }

    if (success) {
        return (
            <Root>
                <Typography variant="h4" gutterBottom>
                    Verification Email Sent
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Please check your email to verify your address. 
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Make sure to check your spam folder if you don't see the email in your inbox.
                </Typography>
                <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleGoToMeteor}
                >
                    Return to Meteor
                </StyledButton>
                <Typography variant="body2" sx={{ marginTop: '20px' }}>
                    Having trouble verifying your email? <a href="https://imrsservices.com" target="_blank" rel="noopener noreferrer">Contact support</a>.
                </Typography>
            </Root>
        );
    }

    return (
        <Root>
            <Box>
                <Typography variant="h4" gutterBottom>
                    Email Not Verified
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Check your inbox to verify your email address.
                </Typography>
                {error && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {error}
                    </Typography>
                )}
                <Box sx={{ marginBottom: "20px" }}>
                    <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={handleGoToMeteor}
                        sx={{ marginRight: '10px' }}
                    >
                        Return to Meteor
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={handleVerifyEmail}
                    >
                        Resend Verification Email
                    </StyledButton>
                </Box>
                <Typography variant="body2" gutterBottom>
                    <i>Make sure to check your spam folder if you don't see the email in your inbox.</i>
                </Typography>
            </Box>
        </Root>
    );
};

export default EmailVerification;