export default class CreateAgileMethodologyFrameworkPrompt {
    constructor(projectContext) {
        this.projectContext = projectContext;
    }

    build() {

        return `
        You are an expert project management leader.

        **Your Task:**
        - Create a complete Agile Methodology Framework document using the full Project Details (EPICS, STORIES, SPRINTS, DOCUMENTS) to guide your response.
        - Flesh out each section with as much useful detail as possible
        - Use the EPICS, STORIES, or SPRINTS from the **Project Details** (if any are provided) to help you populate the **Product Backlog**, **Sprint Planning**, **Sprint Backlog**, and **Release Planning** of the **Required Sections**
        - Use the DOCUMENTS from the **Project Details** (if any are provided) to help you populate the remaining sections of the **Required Sections** and make sure to flesh out these sections with as much useful detail as possible

        **Instructions:**
        - Respond in CommonMark format only.
        - Use the provided information to complete each section in full without requiring additional user input.
        - Do not use placeholders unless information is explicitly missing from the Project Details.
        
        **Accepted Formatting:**
        - **Text Formatting:**
            Bold,
            Italic,
            Underline,
            Strikethrough,
            Blockquote,
            Code Block,
            Header 1,
            Header 2,
            Ordered List,
            Bulleted List,
            Subscript,
            Superscript,
            Indent,
            Outdent,
            Right to Left Text Direction
        - **Other Formatting:**
            Header Levels (1 6, or None),
            Text Color,
            Background Color,
            Font Family,
            Text Alignment (Left, Center, Right, Justify),
            Links

        **Required Sections:**
        - Project Charter
        - Product Backlog
        - Sprint Planning
        - Sprint Backlog
        - Daily Stand-ups
        - Sprint Review
        - Sprint Retrospective
        - Release Planning
        - Definition of Done
        - Burndown Charts
        - Velocity Tracking
        - Risk Management
        - Stakeholder Engagement
        - Continuous Improvement Plan
        - Project Closure

        **Important Instructions:**
        - **Complete** each section with as much useful detail as possible using the information provided in the **Project Details** (DOCUMENTS, STORIES, EPICS).
        - **DO NOT** generate placeholders and **use** of **your knowledge** and the data included in the **Project Details** to fill in every aspect of the Agile Methodology Framework.
        - **DO NOT** respond without generating the requested Agile Methodology Framework.
        - **DO NOT** include any explanations, instructions, or formatting not listed in the instructions.
        - **DO NOT** include the ID of any data referenced (e.g. **NEVER** include identifiers such as ID, epic_id, sprint_id, user_story_id or document_id from the **Project Details** in the document you generate).

        **Project Details:** ${JSON.stringify(this.projectContext)}
        `.replace(/\s+/g, ' ').trim(); // removes all newline characters and extra spaces
    }
}