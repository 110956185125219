import DataService from './DataService';

class StoryFunctions {
    static async updateStories(stories) {
        // Iterate over the stories
        for (let story of stories) {
            // Update the story in the database
            try {
                const response = await DataService.updateUserStory(story.user_story_id, story);
            } catch (error) {
                console.error('Error updating story:', error);
            }
        }
    }

    static checkAndAssignPriorities(stories, setStories) {
        let updatedStories = [...stories];

        // Sort the stories based on their priority
        updatedStories.sort((a, b) => a.priority - b.priority);

        // Assign new priorities to each story in the updatedStories array
        updatedStories.forEach((story, index) => {
            story.priority = index;
        });

        // Update the state of the stories
        setStories(updatedStories);

        // Persist the changes to the backend
        updatedStories.forEach(story => {
            DataService.updateUserStory(story.user_story_id, { 
                epic_id: story.epic_id, 
                priority: story.priority, 
                story_location: story.story_location,
                sprint_id: story.sprint_id
            })
            .then(() => {
                // console.log('Story updated successfully');
            })
            .catch((error) => {
                console.error('Failed to update story:', error);
            });
        });

        return stories;
    }

    static async checkAndAssignPrioritiesForSprintBoard(stories) {
        // Define the order of the statuses
        const statusOrder = ['todo', 'in-progress', 'done'];

        // Sort the stories by status and then by priority
        stories.sort((a, b) => {
            // Compare the statuses
            const statusComparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
            if (statusComparison !== 0) {
                return statusComparison;
            }

            // If the statuses are the same, compare the priorities
            return a.priority - b.priority;
        });

        // Iterate over the stories
        for (let i = 0; i < stories.length; i++) {
            // Always update the priority of the story to its index
            stories[i].priority = i;

            // // Update the story in the database
            // try {
            //     const response = await DataService.updateUserStory(stories[i].user_story_id, { priority: stories[i].priority });
            // } catch (error) {
            //     console.error('Error updating story:', error);
            // }
        }

        // Return the updated stories
        return stories;
    }

    static async onBoardDragEnd(result, stories) {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return stories;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return stories;
        }

        // Find the story in its original status list
        const draggableStoryIndex = stories[source.droppableId].findIndex(story => String(story.user_story_id) === String(draggableId));
        let draggableStory = stories[source.droppableId][draggableStoryIndex];

        // Remove the story from its original position
        stories[source.droppableId].splice(draggableStoryIndex, 1);

        // Update the status of the story
        draggableStory.status = destination.droppableId;

        // Insert the story at the new position
        if (destination.index >= stories[destination.droppableId].length) {
            stories[destination.droppableId].push(draggableStory);
        } else {
            stories[destination.droppableId].splice(destination.index, 0, draggableStory);
        }

        // Assign priorities continuously across statuses
        let priority = 0;
        for (let status of ['todo', 'in-progress', 'done']) {
            for (let story of stories[status]) {
                story.priority = priority++;
            }
        }

        // Persist the changes to the backend
        for (let status of ['todo', 'in-progress', 'done']) {
            for (let story of stories[status]) {
                try {
                    await DataService.updateUserStory(story.user_story_id, { ...story });
                } catch (error) {
                    console.error('Failed to update story:', error);
                }
            }
        }

        return stories;
    }

   static onDragEnd(result, stories, setStories, sprint_id, selectedEpic) {
        // console.log('Result', result.destination.droppableId);
        const { destination, source, draggableId } = result;

        if (!destination) 
            return;

        let draggedStory = stories.find(story => story.user_story_id === draggableId);
        let updatedStories = stories.filter(story => story.user_story_id !== draggableId);

        // Adjust the destination index for moving from sprintBacklog to backlog
        let destinationIndex = destination.index;
        if (source.droppableId === 'sprintBacklog' && destination.droppableId === 'backlog') {
            destinationIndex = Math.max(destination.index - 1, 0);
            draggedStory.sprint_id = null;
        }

        // If moving from backlog to sprintBacklog, assign sprint_id (if available)
        if (destination.droppableId === 'sprintBacklog') {
            //destinationIndex = destination.index >= 0 ? destination.index : 0; // Ensure valid top placement
            draggedStory.sprint_id = sprint_id || null; // Set sprint_id if available, otherwise leave it as null
        }

        // Update the story_location when moved between different droppables
        if (source.droppableId !== destination.droppableId) {
            draggedStory.story_location = destination.droppableId;
        }

        // Insert the dragged story into the updated list at the destination index
        updatedStories.splice(destinationIndex, 0, draggedStory);

        updatedStories.forEach((story, index) => {
            story.priority = index;
        });

        // Update the state of the stories
        setStories(updatedStories);

        // batch persist changes to the backend
        DataService.batchUpdateStories(updatedStories.map(story => ({
            user_story_id: story.user_story_id,
            epic_id: story.epic_id,
            sprint_id: story.sprint_id,
            priority: story.priority,
            story_location: story.story_location
        })))
        .then(() => {
            console.log('Batch update successful');
        })
        .catch((error) => {
            console.error('Failed to update stories:', error);
        });
    }

    static async getEpic(epic_id) {
        try {
            const epic = await DataService.getEpicByID(epic_id);
            return epic;
        } catch (error) {
            console.error('Error getting epic:', error);
        }
    }

    static async assignSprintToStories(projectStories, sprintId) {
        // Iterate over the stories
        for (let story of projectStories) {
            // Check if the story's location is 'sprintBacklog' and the story's status is not 'inactive'
            if (story.story_location === 'sprintBacklog' && story.status !== 'inactive') {
                // console.log('Setting sprint_id to', sprintId, 'for story:', story);
                // Set the story's sprint_id to the given sprint_id
                story.sprint_id = sprintId;
                // console.log('Story after setting Sprint ID:', story);
                // Update the story in the database
                try {
                    const response = await DataService.updateUserStory(story.user_story_id, story);
                } catch (error) {
                    console.error('Error updating story:', error);
                }
            }
        }
    }

    static async deactivateDoneStories(stories) {
        // Iterate over the stories
        for (let story of stories) {

            // Check if the story's status is 'done'
            if (story.status === 'done') {
                story.story_location = 'inactive';
                story.status = 'inactive';
            } 
            
            // If the story's status is neither 'done' nor 'inactive', set sprint_id to null
            else if (story.status !== 'inactive' && story.status !== 'done') {
                story.sprint_id = null;
            }

            // Update the story in the database
            try {
                const response = await DataService.updateUserStory(story.user_story_id, story);
            } catch (error) {
                console.error('Error updating story:', error);
            }
        }
    }
}

export default StoryFunctions;