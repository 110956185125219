import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import GenerateProjectPlan from '../../../classes/GenerateProjectPlan';
import { Dialog, DialogTitle, DialogContent, CircularProgress, List, ListItem, ListItemText, Button, Checkbox, Typography, Box, Avatar } from '@mui/material';
import DataService from '../../../classes/DataService';
import TessAvatarImage from '../../../assets/images/Tess-Headshot-Small.webp';
import UserStoryDto from '../../../dtos/UserStoryDto';
import ProjectContextDto from '../../../dtos/ProjectContextDto';

const AiStories = ({
    projectName, 
    projectDescription, 
    selectedEpic, 
    open, 
    onClose, 
    currentStories,
    projectContext,
    setProjectContext,
    setRefreshStories
    }) => {
    const [stories, setStories] = useState(null);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState([]);
    const [error, setError] = useState(false);
    const storiesData = [];
    const { id } = useParams();

    const generateProjectPlan = new GenerateProjectPlan(projectName, projectDescription, selectedEpic, storiesData);

    const fetchStories = async () => {
        setError(false);
        const abortController = new AbortController();
        setLoading(true);
        let result;
        try {

            //pre-emptively get documents if any exist
            //and set project context (attempt to lazy load docs)
            await getDocuments();

            if (selectedEpic) {
                result = await generateProjectPlan.generateStoriesWithEpic(selectedEpic, stories, id, projectContext, { signal: abortController.signal });
            } else {
                result = await generateProjectPlan.generateStoriesWithoutEpic(null, id, projectContext, { signal: abortController.signal });
            }

            // Check if the result is valid and contains stories
            if (!result || !result.stories || !Array.isArray(result.stories) || result.stories.length === 0) {
                console.error('No stories were generated:', result);
                setError(true);
                return;
            }

            // Set epic_id for each story in result.stories (if applicable)
            if (result && result.stories && selectedEpic) {
                result.stories = result.stories.map(story => ({
                    ...story,
                    epic_id: selectedEpic.epic_id
                }));
            }

            // Set the stories state and pre-check all generated stories
            setStories(result.stories);
            setChecked(result.stories.map((_, index) => index));

        } catch (error) {
            // if (error.name === 'AbortError') {
            //     console.log('Fetch aborted');
            // } else {
            //     throw error;
            // }
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            } else if (error.response?.data?.candidates?.[0]?.finishReason === "SAFETY") {
                // Handle safety-related response
                console.error('AI response flagged for safety concerns:', error.response.data);
                setError(true);
            } else {
                console.error('Error generating stories:', error);
                setError(true);
            }
        } finally {
            setLoading(false);
        }
        
    };  

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleClose = () => {
        if (!loading) {
            setStories(null);
            onClose();
            setError(false);
            //refreshStories();
        }
    };

    const getDocuments = async () => {
        if (projectContext.DOCUMENTS.length > 0) return;
        
        let documents = await DataService.getDocumentsByProjectId(projectContext.PROJECT_ID);
        let context = ProjectContextDto.setDocuments(projectContext, documents);

        setProjectContext(prevContext => ({
            ...prevContext,
            DOCUMENTS: context.DOCUMENTS
        }));
    };

    const createStories = async () => {
        setLoading(true);

        // Retrieve existing list of stories
        const relevantStories = projectContext.STORIES; 

        // Find the current highest priority within the relevant stories, default to -1 if no stories exist
        const highestPriority = relevantStories.length > 0 
            ? Math.max(...relevantStories.map(story => story.priority || 0), 0)
            : -1; // Set to -1 so the first story gets priority 0

        // Start assigning new priorities from here
        let priorityCounter = highestPriority + 1; 

        let generated = checked.map(index => {
            const story = stories[index];
            story.project_id = id;
            story.priority = priorityCounter++; // Assign a unique priority
    
            if (selectedEpic) {
                story.epic_id = selectedEpic.epic_id; // Link to the selected epic if applicable
            }

            if (typeof story.acceptance_criteria === 'object' && story.acceptance_criteria !== null) {
                // Convert the object into a numbered list string
                let criteriaList = '';
                let i = 1;
                for (let key in story.acceptance_criteria) {
                    criteriaList += `${i}. ${story.acceptance_criteria[key]}\n`;
                    i++;
                }
                story.acceptance_criteria = criteriaList;
            }
    
            return story;
        });
        
        let newStories = (await DataService.batchCreateStories(generated))
            .map(story => UserStoryDto.fromJSON(story));

        // Update the project context with the new stories
        setProjectContext(prevContext => ({
            ...prevContext,
            STORIES: [...prevContext.STORIES, ...newStories]
        }));
    
        // Ensure the component re-renders even if refreshStories is already true 
        setRefreshStories(prev => !prev);
        setLoading(false);
        handleClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
            fullWidth
            disableBackdropClick={loading}
            disableEscapeKeyDown={loading}
        >
            <DialogContent>
                <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                    {loading ? (
                        <>
                            <Typography variant="h6">Generating Stories</Typography>
                            <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                                <CircularProgress size={20} />
                            </Box>
                        </>
                    ) : (
                        <>
                            {error ? (
                                <Box display="flex" flexDirection="column" sx={{ marginBottom: '20px' }}>
                                    <Typography variant="h6">Error Generating Stories</Typography>
                                    <Typography variant="body1">
                                        There was a problem creating the stories. Please try again.
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    {!stories && Array.isArray(currentStories) && currentStories.length > 0 && (
                                        <>
                                            <Typography variant="h6">Generate More Stories</Typography>
                                            <Box display="flex" alignItems="flex-start">
                                                <Avatar alt="Tess Avatar" src={TessAvatarImage} sx={{ margin: '20px 0' }} />
                                                <Typography variant="body1" sx={{ margin: '20px 0', marginLeft: '10px' }}>
                                                    I see you have some stories. If you need more, I can generate them for you. You know, because you're too lazy to do it yourself.
                                                    <br/>
                                                    Just click the generate button... or don't. I don't care.
                                                </Typography>
                                            </Box>
                                            <Box maxHeight="400px" overflow="auto">
                                                <List>
                                                    {currentStories.map((story, index) => (
                                                        <ListItem key={index}>
                                                            <ListItemText primary={story.title} secondary={story.description} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        </>
                                    )}
                                    {!stories && (!Array.isArray(currentStories) || currentStories.length === 0) && (
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="h6">Generate Stories</Typography>
                                            <Box display="flex" alignItems="center" sx={{ marginTop: '20px', marginBottom: '20px' }}>
                                                <Avatar alt="Tess Avatar" src={TessAvatarImage} />
                                                <div style={{ marginLeft: '10px' }}>
                                                    <Typography variant="body1">
                                                        I know, the whole reason you're here is so I'll do this for you.
                                                        <br />
                                                        It's fine. Just click the generate button already and let me get to it.
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </Box>
                                    )}
                                    {stories && (
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="h6">Review the Generated Stories</Typography>
                                            <Box display="flex" alignItems="flex-start" sx={{ marginTop: '20px' }}>
                                                <Avatar alt="Tess Avatar" src={TessAvatarImage} />
                                                <div style={{ marginLeft: '20px' }}>
                                                    <Typography variant="body1">
                                                        <b>Uncheck</b> any stories you don't want. 
                                                        <br />
                                                        If you don't like any of them, that's fine. Just click CANCEL and we'll forget this ever happened.
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </Box>
                                    )}
                                    {Array.isArray(stories) && (
                                        <>
                                            <hr />
                                            <Box maxHeight="400px" overflow="auto">
                                            <List>
                                                {stories.map((story, index) => {
                                                    const labelId = `checkbox-list-label-${index}`;

                                                    return (
                                                        <ListItem key={index} role={undefined} dense button onClick={handleToggle(index)}>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={checked.indexOf(index) !== -1}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                            <ListItemText id={labelId} primary={story.title} secondary={story.description} />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                            </Box>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <Box>
                        <Button 
                            onClick={handleClose}
                            sx={{ 
                                color: "gray", 
                                '&:hover': {
                                    backgroundColor: "rgba(0.2,0.2,0.2,.02)"
                                }
                            }}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button onClick={error ? fetchStories : (stories ? createStories : fetchStories)} disabled={loading}>
                            {error || !stories ? 'Generate' : 'Accept'}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AiStories;