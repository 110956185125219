export default class GenerateStoriesWithEpicPrompt {
    constructor(projectName, projectDescription, epic, stories, projectContext) {
        this.projectName = projectName;
        this.projectDescription = projectDescription;
        this.epic = epic;
        this.stories = stories;
        this.projectContext = projectContext;
    }

    build() {

        return `
        You will act as the entire Scrum team for a new project. 

        **Your Task:**
        - Consider the PROJECT_TITLE and PROJECT_DESCRIPTION, then create all the user stories necessary to complete only the ${this.epic.name} defined as ${this.epic.description}.
        - Use the full Project Details (EPICS, STORIES, DOCUMENTS) to guide your response.
        - Assume a single developer can execute 8 story points every 2 week sprint. 
        - Use your best judgment when pointing stories, ensuring every story has points associated with them. 
        - Avoid generating stories that already exist or overlap with the existing stories in the Project Details.
        - Ensure that each new story adds value and fits within the context of the selected epic and the broader project.
        - Do not use any placeholders, instead using the provided links or defaults to fill in all the details completely. 
        - Do not include any explanations or instructions. 

        **Important Instructions:**
        - **ABSOLUTELY NO** markdown or formatting like \`\`\`json or \`\`\`.
        - **DO NOT** include any explanations, instructions, or additional text outside of the JSON response.
        - **DO NOT** use any markup, markdown, or code formatting of any kind.
        - **ONLY** output the story response format in a plain JSON object, exactly following the structure provided below. 
        - **USE** normal quotes instead of smart quotes.
        - **DO NOT** include the ID of any data referenced.
        - **LIMIT the number of stories to between 5 and 7** to ensure quality and manageable scope for the sprint.

        Project Details: ${JSON.stringify({ ...this.projectContext, ...JSON.parse(`{
            ${this.epic ? `"SELECTED_EPIC": "${this.epic.name}",` : ''}
            ${this.epic ? `"SELECTED_EPIC_DESCRIPTION": "${this.epic.description}"` : ''}
        }`) })}.
            
        **Required Response Format** (The **only** output should match this JSON format exactly):
        {
            "stories": [
                {
                    "project_id": project_id,
                    "epic_id": epic_id,
                    "title": title,
                    "description": description,
                    "acceptance_criteria": acceptance_criteria,
                    "priority": 999,
                    "status": "todo",
                    "story_points": story_points,
                    "story_location": "backlog"
                }
            ]
        }
        `.replace(/\s+/g, ' ').trim(); // removes all newline characters and extra spaces
    }
}