import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, Box, Select, MenuItem, FormControl, Card, CardContent } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import DataService from '../../../classes/DataService';
import Loading from '../../Loading.js';

const ProjectDocuments = ({ 
  project,
  projectContext,
  setProjectContext,
  projectDocuments,
  setProjectDocuments
  }) => {
  const [selectedDocument, setSelectedDocument] = useState('');
  const [loading, setLoading] = useState(false);
  const Auth0_user = useAuth0().user;
  const nodeServiceHost = process.env.REACT_APP_NODE_SERVICE_HOST || 'http://localhost:3002';

  // note: remove as is redundant since documents are already loaded and pass down via props...
  // const fetchDocuments = async () => {
  //   if (project) {
  //     try {
  //       const fetchedDocuments = await DataService.getDocumentsByProjectId(project.project_id);
  //       // Filter out documents where is_home is true
  //       const filteredDocuments = fetchedDocuments.filter(doc => !doc.is_home);
  //       setProjectDocuments(filteredDocuments);
  //       // console.log(filteredDocuments);
  //     } catch (error) {
  //       console.error('Error fetching project documents: ', error);
  //     }
  //   } else {
  //     console.error('Project is undefined');
  //   }
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   fetchDocuments().catch(error => {
  //     console.error('Error fetching project documents: ', error);
  //   }).finally(() => {
  //     setLoading(false);
  //   });
  // }, [project]);

  const handleSelectionChange = (event) => {
    setSelectedDocument(event.target.value);
  };

  const handleSubmit = async () => {
      setLoading(true);
      try {
          // Change the status of the selected document to 'published'
          // console.log("selected document", selectedDocument);
          await DataService.updateDocument(selectedDocument, { status: 'published' });
  
          // Update the projectDocuments state directly
          const documents = projectDocuments.map(document => 
            document.document_id === selectedDocument ? { ...document, status: 'published' } : document
          );

          setProjectDocuments(documents);
  
          const inactiveDocuments = projectDocuments.filter(doc => doc.document_id !== selectedDocument);
          await Promise.all(inactiveDocuments.map(doc => {
              return DataService.deleteDocument(doc.document_id);
          }));

          setProjectContext(prevContext => ({
            ...prevContext,
            DOCUMENTS: documents
          }));

          window.location.reload();
      } catch (error) {
          console.error('Error processing documents: ', error);
      } finally {
          setLoading(false);
      }
  };

  return (
	<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
	  {loading ? (
		<Loading />
	  ) : (
		<Card variant='outlined' sx={{ width: '100%', maxWidth: 600, marginTop: 4 }}>
		  <CardContent>
			<Typography variant="h4" display="flex" flexDirection="column" alignItems="center" justifyContent="center">Project Documents Downgrade</Typography>
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h6" sx={{ margin: '20px 0' }}>
				You have more documents than allowed in your plan.
			  </Typography>
			  <Typography variant="h6" sx={{ margin: '20px 0' }}>
				Select 1 Document to keep
			  </Typography>
			  <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Select
				  labelId="document-select-label"
				  value={selectedDocument}
				  onChange={handleSelectionChange}
          displayEmpty
				  sx={{ minWidth: 200, width: '50%' }}
				>
                    <MenuItem value="">
                        <em>Select a Document...</em>
                    </MenuItem>
				  {projectDocuments.map(doc => (
					<MenuItem key={doc.document_id} value={doc.document_id}>
					  {doc.title}
					</MenuItem>
				  ))}
				</Select>
			  </FormControl>
			</Box>
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
				<Button onClick={handleSubmit} color="primary" disabled={!selectedDocument} sx={{ marginTop: '20px' }}>  
					Submit
				</Button>
			</Box>
			<Typography variant="body2" gutterBottom sx={{ fontStyle: 'italic', textAlign: 'center', marginTop: '20px' }}>
				This action will delete all other documents, and cannot be undone.
			  </Typography>
		  </CardContent>
		</Card>
	  )}
      <Box sx={{ margin: '20px 0' }}>
        <Typography variant="h5">
          Or, you can subscribe to a paid plan to retain access all your current documents.
        </Typography>
      </Box>
      <Box>
        <Button variant="contained" href="/plan-selection" sx={{ backgroundColor: '#E62824' }}>
          Upgrade Plan
        </Button>
      </Box>
	</Box>
  );
};

export default ProjectDocuments;