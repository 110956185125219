import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Typography, Tabs, Tab } from '@mui/material';
import ProjectBacklog from './Backlog/ProjectBacklog';
import ProjectDocuments from './Documents/ProjectDocuments';
import ScrumBoard from './SprintBoard/ProjectScrumBoard';
import AllStories from './UserStories/AllStories';
import ProjectSettings from './ProjectSettings/ProjectSettings';
import DataService from '../../classes/DataService';
import UserFunctions from '../../classes/UsersFunctions';
import Loading from '../Loading';
import { useAuth0 } from '@auth0/auth0-react';
import isEqual from 'lodash/isEqual';
import ProjectContextDto from '../../dtos/ProjectContextDto';

// Simple debounce function
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const ProjectDetails = ({ stripeSubscription }) => {
  const { id } = useParams(); // Get the id from the URL
  const [project, setProject] = useState(null);
  const [projectMembers, setProjectMembers] = useState({});
  const [projectEpics, setProjectEpics] = useState([]);
  const Auth0_user = useAuth0().user;
  const [projectStories, setProjectStories] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [sprints, setSprints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userPlan, setUserPlan] = useState('Professional');
  const [sprintBacklogStories, setSprintBacklogStories] = useState(true);
  const [refreshStories, setRefreshStories] = useState(false);
  const [projectContext, setProjectContext] = useState({
    EPICS: [],
    SPRINTS: [],
    STORIES: [],
  });
  const userFunctions = new UserFunctions();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Debounce the state update
  const debouncedHandleTabChange = debounce(handleTabChange, 300);

  // Update user stories location if user plan is "Free"
  const updateStoryLocations = async (stories) => {
    if (userPlan.toLowerCase() != 'free') return;

    const storyIdsToUpdate = stories
      .filter(story => story.story_location !== 'backlog') // Filter everything NOT in backlog
      .map(story => story.story_id);

    if (storyIdsToUpdate.length == 0) return;

    // console.log('Stories to update:', storyIdsToUpdate);
    await DataService.updateUserStoriesLocation(storyIdsToUpdate, 'backlog');
    
    const updatedStories = stories.map(story =>
      storyIdsToUpdate.includes(story.story_id)
        ? { ...story, story_location: 'backlog' }
        : story
    );

    // Only set project stories if there are actual updates
    if (!isEqual(projectStories, updatedStories))
      setProjectStories(updatedStories);
    
    setSprintBacklogStories(false);
  };

  useEffect(() => {
    setLoading(true);
    const fetchProjectContext = async () => {
      try {
        const projectData = await DataService.getProjectDetails(id);
        setProject(projectData);

        const members = await userFunctions.getProjectMembers(projectData);
        setProjectMembers(members);

        const context = ProjectContextDto.fromJSON(await DataService.getProjectContextById(id)) ;
        setProjectContext(context); 

        //const epics = await DataService.getEpicsByProjectId(id);
        setProjectEpics(context.EPICS);

        //const fetchedSprints = await DataService.getSprintsByProjectId(id);
        setSprints(context.SPRINTS);
        setProjectStories(context.STORIES);

        //await fetchStories(); // Fetch stories
      } catch (error) {
        console.error('Failed to fetch project data:', error);
        setLoading(false);
      }
      setLoading(false);
    };

    fetchProjectContext();
  }, [id]);

  useEffect(() => {
    setUserPlan("Professional");
  }, [stripeSubscription]);

  useEffect(() => {
    if (projectStories.length == 0) return;
    
    updateStoryLocations(projectStories); // Update story locations if necessary
    
  }, [projectStories, userPlan]);

  const getAdjustedTabValue = () => {
    if (userPlan.toLowerCase() === 'free') {
      return tabValue + 1;
    }
    return tabValue;
  };

  return loading ? (
    <Loading />
  ) : (
    <div style={{ height: '97%', marginBottom: '20px' }}>
      <div>
        {project && <Typography variant="h5">{project.name}</Typography>}
      </div>
      <div style={{ marginTop: "1rem" }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Sprint Board" />
          <Tab label="Backlog" />
          <Tab label="Documents" />
          <Tab label="All Stories" />
          <Tab label="Settings" />
        </Tabs>
        {tabValue === 0 &&
          <ScrumBoard 
            projectMembers={projectMembers} 
            projectEpics={projectEpics} 
            sprints={sprints} 
            userPlan={userPlan}
            projectContext={projectContext} 
            setProjectContext={setProjectContext}
            refreshStories={refreshStories}
            setRefreshStories={setRefreshStories} />
        }
        {getAdjustedTabValue() === 1 &&
          <div style={{ marginTop: '20px' }}>
            <ProjectBacklog 
              projectMembers={projectMembers} 
              project={project} 
              sprints={sprints} 
              setSprints={setSprints} 
              projectEpics={projectEpics} 
              setProjectEpics={setProjectEpics} 
              stories={projectStories} 
              setStories={setProjectStories} 
              userPlan={userPlan}
              projectContext={projectContext} 
              setProjectContext={setProjectContext}
              refreshStories={refreshStories}
              setRefreshStories={setRefreshStories} />
          </div>
        }
        {getAdjustedTabValue() === 2 &&
          <ProjectDocuments 
            project={project} 
            Auth0_user={Auth0_user} 
            stripeSubscription={stripeSubscription}
            projectContext={projectContext} 
            setProjectContext={setProjectContext} />
        }
        {getAdjustedTabValue() === 3 &&
          <div style={{ marginTop: '20px' }}>
            <AllStories 
              //projectStories={projectStories} 
              //projectEpics={projectEpics} 
              projectMembers={projectMembers}
              projectContext={projectContext} 
              setProjectContext={setProjectContext} />
          </div>
        }
        {getAdjustedTabValue() === 4 &&
          <ProjectSettings 
            project={project} 
            setProject={setProject} 
            projectMembers={projectMembers} 
            setProjectMembers={setProjectMembers} 
            userPlan={userPlan} />
        }
      </div>
    </div>
  );
};

export default ProjectDetails;
