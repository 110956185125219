import React, { useEffect, useState } from 'react';
import { keyframes } from '@mui/system';
import { Box, Avatar, Typography, Menu, MenuItem, ListItemIcon, Divider, Chip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useNavigate } from 'react-router-dom';
import MeteorImage from '../assets/images/meteor-logo-min.webp';
import RocketLaunch from '@mui/icons-material/RocketLaunch';
import Help from '@mui/icons-material/Help';
import { chipClasses } from '@mui/material/Chip';
import DataService from '../classes/DataService';

const scrollColors = keyframes`
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

const Header = ({ userProfileImage, stripeSubscription, fullUser, daysLeftOnTrial }) => {
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (stripeSubscription && daysLeftOnTrial !== null) {
      if (stripeSubscription.productName) {
        setShowUpgrade(false);
      } else {
        setShowUpgrade(true);
      }
    }
  }, [stripeSubscription, daysLeftOnTrial]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInitials = (name) => {
    let names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const handleUpgradeClick = async () => {
    navigate('/plan-selection');
  };

  return (
    <Box sx={{ height: '40px', backgroundColor: '#3b3d4a', display: 'flex', justifyContent: 'space-between' }}>
      <img src={MeteorImage} alt='Meteor' style={{ width: '25px', height: '20px', marginTop: '10px', marginLeft: '20px' }} />
      <Box display="flex" alignItems="center">
        {showUpgrade === true && daysLeftOnTrial > 0 && (
          <>
          <Typography variant="body2" sx={{ color: 'gray', marginRight: '10px', fontStyle: 'italic' }}>{daysLeftOnTrial} trials days left</Typography>
            <Chip
              icon={<RocketLaunch sx={{ width: '15px', height: '15px' }} />}
              label={`Upgrade Now!`}
              size='small'
              onClick={handleUpgradeClick}
              sx={{
                marginRight: '10px',
                color: 'white',
                background: 'none',
                userSelect: 'none',
                [`& .${chipClasses.icon}`]: {
                  color: 'white'
                },
                '&:hover': {
                  animation: `${scrollColors} 5s linear infinite`,
                  background: 'linear-gradient(270deg, #ff0000, #00ff00, #0000ff, #ffff00, #ff0000)',
                  backgroundSize: '2000% 2000%',
                }
              }}
            />
          </>
        )}
        <Box display="flex" alignItems="center" onClick={handleClick} sx={{ cursor: 'pointer', marginRight: '10px' }}>
          <Avatar src={userProfileImage} key={userProfileImage} sx={{ marginRight: '1rem', width: 25, height: 25 }}>
            <Typography variant="body2">{user && getInitials(user.name)}</Typography>
          </Avatar>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Link to="/account" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">Account</Typography>
          </MenuItem>
        </Link>
        <a 
          href="https://www.imrsservices.com/support" 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Help fontSize="small" />
            </ListItemIcon>
            <Typography variant='body1'>Support</Typography>
          </MenuItem>
        </a>
        <Divider />
        <MenuItem onClick={() => { handleClose(); logout(); }}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body1">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Header;